<template>
    <div class="">
        <div class="columns is-multiline">
            <div class=" column is-half-tablet " v-for="pay in pays" :key="pay._id">

                <a  class="card columns is-mobile mb-0 p-1" >
                    <!-- <div class="column is-one-fifth">
                        <figure class="image is-64x64 pt-1">
                            <img src="https://bulma.io/images/placeholders/128x128.png" alt="Image">
                        </figure>
                    </div>
                    <div class="column is-half">
                        <div class="p-2 ">
                            <h4 class=" has-text-dark has-text-weight-medium  mb-1">{{ pay.client }}</h4>
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-1">{{ getUser.country.currency+pay.total }} 
                            </p>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <div class="pt-2">
                            <b-tag :type="`${pay.status == 'paid' ? 'is-primary' : 'is-danger'}  mb-2 mt-1`">{{ pay.status }}</b-tag>
                            <p class="has-text-dark small has-text-weight-medium"> {{ formatAMPM(pay.date) }} </p>
                        </div>
                    </div> -->

                    <div @click="routeToInvoice(pay)" class="column is-two-thirds">
                        <div class="p-1 ">
                            <h4 class=" has-text-primary has-text-weight-semibold  mb-1"> <span class="has-text-dark ">Invoice &#8212;</span> {{ pay.invoice }} </h4>
                            
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-1">{{ getUser.country.currency+pay.amount.toFixed(2) }}
                            <!-- 
                            <span class="has-text-success small"> 750.00 </span><br> -->
                            <!-- <span class="small">&#8212;</span> 
                            <span class="ml-1 has-text-primary is-size-7 has-text-weight-bold mb-2"> {{ pay.receipt }} </span> -->
                            <!-- <span v-if="pay.status == 'part payment'" class="has-text-primary small"> ({{ (pay.total - pay.paid).toFixed(2) }}) </span>
                            <span v-else-if="pay.status == 'pending' && pay.type == 'Invoice'" class="has-text-danger small"> ({{ pay.total.toFixed(2) }}) </span>
                            <span v-else class="ml-1 has-text-primary is-size-7 has-text-weight-bold mb-2"> {{ pay.ref }} </span> -->
                            
                            </p>
                            <p  class="has-text-dark has-text-weight-medium mb-1"> {{ pay.client }} </p>
                            <p  class="has-text-weight-semibold has-text-grey small"> {{ pay.details }} </p>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <b-dropdown position="is-bottom-left" :triggers="['click']" :append-to-body="true" :mobile-modal="false" aria-role="list">
                    <template #trigger>
                        <div class="pt-1">
                            <b-tag :type="`${pay.type == 'Cash' ? 'is-primary' : pay.type == 'Mobile Money' ? 'is-warning' : 'is-danger'}  mb-2 mt-1`">{{ pay.type }}</b-tag>
                            <p class="small has-text-dark mb-1 has-text-weight-semibold has-text-primary">{{ pay.receipt }}</p>
                            <p class="has-text-dark small has-text-weight-medium"> {{ new Date(pay.date).toDateString().substr(4) }} </p>
                        </div>
                    </template>
            <b-dropdown-item @click="view(pay)" aria-role="listitem">View</b-dropdown-item>
            <b-dropdown-item @click="edit(pay)" aria-role="listitem">Edit</b-dropdown-item>
            <b-dropdown-item @click="del(pay)" aria-role="listitem">Delete</b-dropdown-item>
            <!-- <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item> -->
            <!-- <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item> -->
            </b-dropdown>
                    </div>
                </a>
                    

                <!-- <hr class="my-1 mb-4"> -->
            </div>
        </div>
        <div class="p-4"></div>
    </div>
</template>

<script>
    
    import axios from 'axios';
    import { mapGetters } from "vuex"
    import moment  from 'moment';

    export default {
        name: 'PayTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            pays: {
                required: true
            },
            del: {

            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            formatDate(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            routeTo(data) {

                //
                this.$router.push({ name: 'shop', 
                                    query: {  shop: data.shop }
                                })
                

            },
            routeToInvoice(data) {

                //
                
                    //
                    this.$router.push({ name: 'view_pay', 
                                    params: { 
                                        id: data._id,
                                        invoice: data.invoice,
                                        receipt: data.receipt,
                                        client: data.client,
                                        business: data.business,
                                        type: data.type,
                                        amount: data.amount,
                                        date: data.date,
                                        details: data.details,
                                        color: data.color,
                                        gradientColor: data.gradientColor,
                                    } 
                                })
                

            },
            view(data) {
                if (data) {
                    this.$router.push({ name: 'view_pay', 
                                    params: { 
                                        id: data._id,
                                        invoice: data.invoice,
                                        receipt: data.receipt,
                                        client: data.client,
                                        business: data.business,
                                        type: data.type,
                                        amount: data.amount,
                                        date: data.date,
                                        details: data.details,
                                        color: data.color,
                                        gradientColor: data.gradientColor,
                                    } 
                                })
                }
            },
            edit(data) {
                this.$router.push({ name: 'add_pay', 
                                    params: { pay: data }
                                })
            },
            // del(data) {
            //     this.$buefy.dialog.confirm({
            //         // title: 'Delete Payment?',
            //         message: `<b>Delete Payment: ${data.receipt} ?</b><br> Are you sure you want to <b>delete</b> this payment? This action cannot be undone.`,
            //         confirmText: 'Delete',
            //         type: 'is-danger',
            //         hasIcon: true,
            //         onConfirm: () => {
                        
            //             //
            //             axios.get('/payment/'+this.getUser.business+'/'+data.invoice.substring(1)).then((res)=>{
            //                 //
            //                 // this.invoice = res.data[0];
            //                 // this.pay.items[0].total = res.data[0].total;

            //                 // this.isLoading = false
            //                 this.$buefy.toast.open('Payment deleted!')

            //                 // this.checkShop()
            //                 // this.checkVisit()
            //             })
            //         }
            //     })
            // },
        } 
    }
</script>

<style lang="scss" >
// .dropdown-trigger {
//         width: calc(100vw - 3.5rem);
//     }
</style>