<template>
    <div>
        <div class="p-4" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            <div class="columns is-mobile">
                    <div class="column is-half">
                        <b-field hidden class="report">
                            <!-- <b-switch v-model="graphIsSwitched" size="is-small">Area Graph</b-switch> -->
                            <b-select size="is-small" placeholder="Select a report" v-model="graph">
                                <option
                                    value="area"
                                    key="area">
                                    Area Graph
                                </option>
                                <option
                                    value="line"
                                    key="line">
                                    Line Graph
                                </option>
                            </b-select>
                        </b-field>  
                        <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather> 
                        <p class="px-3 py-2 pb-0 has-text-weight-bold ml-5">Clients </p>
                    </div>
                    <div class="column py-4">
                        <b-button @click="goto('add_client')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> Add  </b-button>
                        <!-- <b-field class="report is-pulled-right">
                            <b-select size="is-small" placeholder="Select a range" v-model="due">
                                <option
                                    v-for="option in dueRange"
                                    :value="option"
                                    :key="option">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>   -->
                    </div>
            </div>


            <div hidden class="content p-2 text-center">
                        <div class="columns is-mobile is-multiline">
                            <div class="column card p-4 m-1">
                                <p class="small has-text-weight-medium">Paid this month</p>
                                <h4 class="title mb-1 is-4 has-text-weight-bold"> {{ getUser.country.currency+mons.toFixed(2) }} </h4>
                            </div>
                            <div class="column card p-4 m-1">
                                <p class="small has-text-weight-medium">All Payments</p>
                                <h4 class="title mb-1 is-4 has-text-weight-bold">{{ getUser.country.currency+allpays .toFixed(2)}}</h4>
                            </div>
                        </div>
            </div>

            <!-- <div class="p-1">
                <b-field  >
                    <b-input  autocomplete="0" v-model="searchString" placeholder="Search"></b-input>
                </b-field>
            </div> -->

                <div class="py-2 mb-5" style="max-width:460px;margin:auto">
                        <b-field label="">
                            <b-input autocomplete="0" @input="Search" @keyup.native="load" :loading="loading" v-model="searchString" placeholder="Search invoices "></b-input>
                        </b-field>
                </div>


            <ClientTemplate :del="del" :clients="result" v-if="result.length > 0" class="pb-5"></ClientTemplate>
            <ClientTemplate :del="del" v-else :clients="getClients"  class="pb-5"></ClientTemplate>
            
            <div hidden class="p-1 py-4">
                <a class="columns is-mobile mb-0 p-1" >
                    <!-- <div class="column is-one-fifth">
                        <figure class="image is-64x64 pt-1">
                            <img src="https://bulma.io/images/placeholders/128x128.png" alt="Image">
                        </figure>
                    </div> -->
                    <div class="column is-two-thirds">
                        <div class="p-2 ">
                            <h4 class=" has-text-dark has-text-weight-medium  mb-1">Johnson & Johnson</h4>
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-2">{{ getUser.country.currency }}750.00 
                            <!-- <span class="small">&#8212;</span> 
                            <span class="has-text-success small"> 750.00 </span><br> -->
                            <span class="ml-1 has-text-primary is-size-7 has-text-weight-bold mb-2"> #INV00085 </span>
                            </p>
                            
                            <b-tag class="has-text-weight-semibold"> On Receipt</b-tag>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <div class="pt-2">
                            <b-tag type="is-primary  mb-2 mt-1">Paid</b-tag>
                            <p class="small has-text-dark mb-2 ">Bill</p>
                            <p class="has-text-dark small has-text-weight-medium"> Jun 12, 2023 </p>
                        </div>
                    </div>
                </a>
                <hr class="my-1 mb-4">
                <a class="columns is-mobile mb-0 p-1" >
                    <!-- <div class="column is-one-fifth">
                        <figure class="image is-64x64 pt-1">
                            <img src="https://bulma.io/images/placeholders/128x128.png" alt="Image">
                        </figure>
                    </div> -->
                    <div class="column is-two-thirds">
                        <div class="p-2 ">
                            <h4 class=" has-text-dark has-text-weight-medium  mb-2">Kofikrom Farms </h4>
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-1">{{ getUser.country.currency }}750.00 
                            <span class="small">&#8212;</span> 
                            
                            <span class="has-text-danger small"> (750.00) </span><br>
                            <!-- <span class="ml-1 has-text-primary is-size-7 has-text-weight-bold mb-2"> #INV00085 </span> -->
                            </p>
                            <b-tag class="has-text-weight-semibold"> in 30 days - Jul 11, 2023</b-tag>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <div class="pt-2">
                            <b-tag type="is-primary  mb-2 mt-1">Paid</b-tag>
                            <p class="small has-text-dark mb-2 ">Invoice </p>
                            <p class="has-text-dark small has-text-weight-medium"> Jun 12, 2023 </p>
                        </div>
                    </div>
                </a>
            </div>
            <div>

        </div>
        
            </div>
        <MainFooter />
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import moment  from 'moment';
    
    import MainFooter from '../components/footer'
    import InvoiceTemplate from '../components/invoiceTemplate.vue'
    import ClientTemplate from '../components/clientTemplate.vue'

    export default {
        name: "ClientPage",
        data() {
            return {
                due: null,
                loading: false,
                searchString: '',
                isSwitchedCustom: false,
                selected: [],
                screenWidth: 0,
                editShop: null,
                fromShop: false,
                shop: null,
                graph: null,
                mons: 0,
                allpays: 0,
                result: [],
                dueRange: [null, '7 days', '15 days', '30 days', '60 days', '90 days', '180 days'],
            }
        },
        components: {
            MainFooter,
            // InvoiceTemplate,
            ClientTemplate
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            ...mapGetters('invoices', ['getInvoices']),
            ...mapGetters('clients', ['getClients']),
            // currentShop() {
            //     let from = this.$route.params.shop ? this.$route.params.shop : false
            //     return from ? this.shop : this.$store.getters['shops/getShops'][0]
            //     // return this.$store.getters['shops/getShops'][0]
            // },
            // getLink() {
            //     return this.$store.getters['shops/getShopLink']
            // }
        },
        watch: {
            getClients() {
                if (this.getClients) {
                    //
                    this.mons = this.getClients.filter( v => v.date.includes((new Date()).toISOString().substring(0,7))).reduce((a, b) => a + b.amount, 0);

                    // const total = this.getInvoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && moment(new Date(v.dueDate)).diff(moment(new Date), 'days') < 0).reduce((a, b) => a + b.total, 0);

                    // const paid = this.getInvoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && moment(new Date(v.dueDate)).diff(moment(new Date), 'days') < 0).reduce((a, b) => a + b.paid, 0);

                    this.allpays = this.getClients.filter( v => v.date.includes((new Date()).toISOString().substring(0,4))).reduce((a, b) => a + b.amount, 0);

                        // this.mons = this.getInvoices.filter(v => v.date.includes((new Date()).toISOString().substring(0,7))).reduce((a, b) => a + b.total, 0);

                    // let today = moment(new Date)
                    // let due = moment(new Date("2023-10-13T19:01:25.619Z"))
                    // console.log(this.getInvoices[6].dueDate)
                    // console.log(due.diff(today, 'days'))

                    
                     
                }
            },
            searchString() {
                if (this.searchString.length == 0) {
                    this.result = []
                }
            }
        },
        mounted() {

            this.updateScreenWidth();
            this.onScreenResize();
            //
            this.fetchData();

            if (Object.keys(this.$route.params).length !== 0) {

                // console.log(this.$route.params)
                //
                this.searchString = this.$route.params.search
                this.Search()

            }
            // validate

            

            // console.log(this.$route.params)

            // if (Object.keys(this.$route.params).length !== 0) {

            //     console.log(this.$route.params)
            //     //
            //     this.shop = this.$route.params.shop
            //     this.editShop = this.$route.params.editPref
            //     this.fromShop = true

            //     if (this.editShop && this.$route.params.prefs){
            //         this.selected = this.$route.params.prefs

            //         this.$buefy.toast.open("Kindly update shopping preference.")

            //     } else {
            //         this.$buefy.toast.open("Set shopping profile below.")
            //     }

            // } else {
                
                

            //     console.log(this.getLink)
            //     if (this.getLink == null){
            //         //
            //         this.$router.push('/')
            //         // console.log("here")
            //     } else {
            //         //
                    
            //         this.checkShop()

            //     }
            //     //
                
            // }

        },
        methods:{
            
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            fetchData() {
                // fetch
                // this.$store.dispatch("clients/getClients", this.getUser.business);
                // this.$store.dispatch("invoices/getInvoices", this.getUser.business);
                this.$store.dispatch("clients/getClients", this.getUser.business);

            },
            //
            checkShop() {
                //
                axios.get('/user/pref/'+this.currentShop.name).then(response=>{
                    //

                    // this.$buefy.dialog.confirm({
                    //     title: 'Shopping preference',
                    //     message: 'Preferences are already set, would you like to update?',
                    //     confirmText: 'Yes',
                    //     onConfirm: () => {
                    //         //
                            console.log(response.data)
                    //         //
                    //         this.editShop = response.data.shops[0].id
                            this.selected = response.data.shops[0].preferences

                    //     },
                    //     cancelText: 'No',
                    //     onCancel: () => {
                            //
                            this.$buefy.toast.open("Wait to Review your shopping experience today.")
                            //
                            setTimeout(()=>{
                                this.$router.push('rateshop')
                            }, 1000)
                        // }
                    // })
                    //

                }).catch(error=>{
                    //
                    this.$buefy.toast.open("Set shopping preference below.")
                })
            },
            //
            checkPref(event) {
                //
                if (this.isSwitchedCustom) {
                    const all = this.currentShop.categories
                    //
                    this.selected = all
                } 
                
                if (this.isSwitchedCustom == false) {
                    this.selected = []
                }

            },
            goto(link) {
                this.$router.push(link)
            },
            gotoShop() {
                //
                this.$router.push({ name: 'shop', params: this.currentShop })
            },
            getShops() {
                this.$store.dispatch('shops/getShop', this.getLink.shop ).then(()=>{
                    //
                    setTimeout(()=>{
                        this.checkShop()

                        // localStorage.removeItem('shop-link')
                    }, 500)
                })
            },
            onSubmit(event) {
                // validate
                let method
                if (this.editShop) {
                    method = axios.patch
                } else {
                    method = axios.post
                }
                
                // ajax
                method('/user/pref/', { prefs: this.selected, shop: this.currentShop.name, id: this.editShop } ).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)

                    // rate the service
                    setTimeout(()=>{
                        
                        if (this.shop) {
                            //
                            this.$router.push({ name: 'shop', params: this.shop })

                        } else {
                            //
                            this.$router.push('rateshop')
                        }

                    }, 2000)


                }).catch(error=>{
                    //
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                //

                

                

                // alert(JSON.stringify(this.form))
            },
            async Search() {
                // console.log('here')
                if (this.searchString.length >= 2) {
                    
                   
                    const result = await this.$store.getters['clients/searchClients'](this.searchString)

                    this.result = result
                    
                }
            },
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            },
            goBack() {
                this.$router.back()
            },
            goHome() {
                //
                this.$router.push('/')
            },
            del(data) {
                this.$buefy.dialog.confirm({
                    // title: 'Delete Payment?',
                    message: `<b>Delete Client: ${data.name} ?</b><br> Are you sure you want to <b>delete</b> this client? This action cannot be undone.`,
                    confirmText: 'Delete',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        
                        //
                        axios.delete('/client/'+this.getUser.business+'/'+data.name).then((res)=>{
                            //
                            // this.invoice = res.data[0];
                            // this.pay.items[0].total = res.data[0].total;

                            // this.isLoading = false
                            this.$buefy.toast.open(res.data)

                            this.fetchData()

                            // this.checkShop()
                            // this.checkVisit()
                        })
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "vue-select/dist/vue-select.css";

.vs__selected-options input[class] {
    padding: 0.55rem;
}

.vs__selected {
    padding: 0.55rem;
}

.vs__deselect {
    margin: 6px;
    margin-left: 8px;
}

// .columns .column {
//     padding: 0.5rem !important;
// }
</style>