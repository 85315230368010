<template>
    <div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        <div class="pb-5">
            <!-- <h4 class="subtitle is-2 mb-0" style="text-align:center">Heath <i class="has-text-danger">Line</i></h4> -->
            <!-- <p style="text-align:center;font-weight:300" class="subtitle is-6 has-text-gray">your health in your palm</p> -->
        </div>
        <div class="auth">
            <div class=" px-3 py-4">
                <div class="-content">
                    <div class="content">
                        <div class="pb-1 text-center mb-5">
                            <!-- <img style="width:320px" :src="require('@/assets/img/logo1.png')" class="logo"/> -->
                            <h1 class="title title is-1">Shopnscan<span style="font-weight:300;"></span></h1>
                            <p class="subtitle is-6 has-text-grey">Setup page</p>
                        </div>
                        <div class="pt-5 pb-2">
                            <div class="columns is-mobile">
                                <div class="column is-half">
                                    <b-field label="" :type="fn.type" :message="fn.message">
                                        <b-input autocomplete="0" @input="validateFname()" autofocus v-model="form.firstname" placeholder="Firstname"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="ln.type" :message="ln.message">
                                        <b-input autocomplete="0" @input="validateLname()" autofocus v-model="form.lastname" placeholder="Lastname"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field :type="em.type" :message="em.message">
                                        <b-input autocomplete="0" v-model="form.email" placeholder="test@email.com"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-half-desktop is-is-two-fifths-mobile">
                                    <b-field label="" :type="gn.type" :message="gn.message">
                                        <b-select
                                            class="mb-4"
                                            expanded
                                            v-model="form.gender"
                                            placeholder=" Gender"
                                            @input="validateGender()"
                                        >
                                            <option value=""> Select</option>
                                            <option value="male"> Male</option>
                                            <option value="female"> Female</option>

                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="dob.type" :message="dob.message">
                                        <b-datepicker
                                            @input="validateDOB()"
                                            placeholder="Date of birth"
                                            v-model="form.dob"
                                        >
                                        </b-datepicker>
                                    </b-field>
                                </div>
                            </div>

                            <div class="notification small">
                                <button class="delete"></button>
                                Enter details to Setup profile. <span class="is-success">Email</span>  is optional
                            </div>
                            
                            <!-- <b-field :type="ag.type" :message="ag.message">
                                <b-checkbox v-model="form.checkbox">I agree to the <a>Terms & Conditions</a> and <a>privacy policy</a>.</b-checkbox>
                            </b-field> -->
                            
                            <!-- <span> 
                                <router-link  to="/signin">Signin </router-link>
                            </span> -->
                            <b-field class="pt-1">
                                <b-button @click="onSubmit" rounded class="is-submit-button" expanded type="is-primary">Set Profile</b-button>
                            </b-field>

                            <!-- {{ form }} -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { userService } from '../services';

    export default {
        name:'SetupPage',
         data() {
            return {
                isLoading: false,
                isFullPage: true,
                form: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    code: '',
                    contact: '',
                    gender: null,
                    dob: null,
                    checkbox: false,
                },
                fn: {
                    type:'',
                    message:'First name',
                },
                ln:{
                    type:'',
                    message:'Last name',
                },
                gn:{
                    type:'',
                    message:'Gender',
                },
                dob:{
                    type:'',
                    message:'Date of Birth (Optional)',
                },
                em:{
                    type:'',
                    message:'Email (Optional)',
                },
                ag:{
                    type:'',
                    message:'',
                },
                show: true
            }
        },
        created() {
            // watch and fetch
            this.$watch(
            () => this.$route.params,
            () => {
                //
                console.log(this.$route)
                this.form.code = this.$route.params.code
                this.form.contact = this.$route.params.contact
            },
            { immediate: true }

            )

        },
        methods: {
            validateFname() {
                if (this.form.firstname.length < 3){
                    this.fn.type = 'is-danger';
                    this.fn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.firstname.length >= 3){
                    this.fn.type = 'is-success';
                    this.fn.message ='First name'
                    return true
                }
            },
            validateLname() {
                if (this.form.lastname.length < 3){
                    this.ln.type = 'is-danger';
                    this.ln.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.lastname.length >= 3){
                    this.ln.type = 'is-success';
                    this.ln.message ='Last name'
                    return true
                }
            },
            validateGender() {
                if (this.form.gender == null || this.form.gender == ''){
                    this.gn.type = 'is-danger';
                    this.gn.message ='Kindly select a gender'
                    return false
                }

                if (this.form.gender.length > 0){
                    this.gn.type = 'is-success';
                    this.gn.message ='Gender'
                    return true
                }
            },
            validateEmail() {

                const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

                 if (this.form.email.match(reg)) {
                        this.em.type = 'is-success';
                        this.em.message =''
                        return true
                 } else {
                        this.em.type = 'is-danger';
                        this.em.message ='Kindly enter a valid email'
                        return false
                        
                 }

            },
            validateDOB() {
                if (this.form.dob == ''){
                    this.dob.type = 'is-danger';
                    this.dob.message ='Kindly select a date'
                    return false
                }

                if (this.form.dob){
                    this.dob.type = 'is-success';
                    this.dob.message =''
                    return true
                }
            },
            validateAgree() {
                if (!this.form.checkbox){
                    this.ag.type = 'is-danger';
                    this.ag.message ='Kindly agree to proceed!'
                    return false
                }

                if (this.form.checkbox){
                    this.ag.type = 'is-success';
                    this.ag.message =''
                    return true
                }
            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                // if ( !this.validateFname() || !this.validateLname() || !this.validateEmail() || !this.validateGender || !this.validateDOB){
                if ( !this.validateFname() || !this.validateLname() || !this.validateGender()){
                    return
                }

                this.isLoading = true
                
                // ajax
                axios.post('/user/profile', this.form).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)
                    //
                    userService.getAll().then(() => {
                        // update
                        this.$store.dispatch('auth/updateUser')
                    })

                    setTimeout(() => {
                        this.isLoading = false
                        //
                        this.$buefy.toast.open("Welcome Budy!")
                        //
                        this.$router.push({ name: 'index' })

                    }, 1.5 * 1000)

                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                //

                

                

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.auth {
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 460px;
    margin: auto;
    // margin-top: 0.5rem;
    text-align: left;
}

// input{
//     padding: 1.5rem;
//     margin: 0.75em auto;
// }

// .title {
//     font-weight: 700;
// }

// .subtitle {
//     color: rgba(125, 125, 125, 0.7)
// }

// button {
//     padding: 12px 35px;
//     border-radius: 5px;
// }
</style>