<template>
    <div class="">
        <div class="columns is-multiline">
            <div class=" column is-half-tablet " v-for="category in categories" :key="category._id">

                
                <a class="card columns is-mobile mb-0 p-1" >

                    <div @click="routeToInvoice(category)" class="column is-two-thirds">
                        <div class="p-1 ">
                            
                            <h4 class="medium has-text-primary has-text-weight-semibold ml-2 mb-1"> <span class="has-text-dark ">{{ category.category }}</span>  </h4>
                            
                            <b-tag class="small has-text-dark mb-1 has-text-weight-semibold mr-3">Category</b-tag>
                            
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <b-dropdown position="is-bottom-left" :triggers="['click']" :append-to-body="true" :mobile-modal="false" aria-role="list">
                    <template #trigger>
                        <div class="pt-1 ">
                            <p style="font-weight:700" class="small has-text-dark has-text-weight- mb-1"> {{ new Date(category.createdAt).toDateString().substr(4) }}
                            
                            </p>
                        </div>
                    </template>
            <!-- <b-dropdown-item @click="view(item)" aria-role="listitem">View</b-dropdown-item> -->
            <b-dropdown-item @click="edit(category)" aria-role="listitem">Edit</b-dropdown-item>
            <b-dropdown-item @click="del(category)" aria-role="listitem">Delete</b-dropdown-item>
            <!-- <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item> -->
            <!-- <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item> -->
            </b-dropdown>
                    </div>
                </a>

                    

                <!-- <hr class="my-1 mb-4"> -->
            </div>
        </div>
        <div class="p-4"></div>
    </div>
</template>

<script>
    
    import axios from 'axios';
    import { mapGetters } from "vuex"
    import moment  from 'moment';

    export default {
        name: 'CategoriesTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            categories: {
                required: true
            },
            del: {

            },
            edit: {

            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            formatDate(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            routeTo(data) {

                //
                this.$router.push({ name: 'shop', 
                                    query: {  shop: data.shop }
                                })
                

            },
            routeToInvoice(data) {

                //
                

            },
        } 
    }
</script>

<style lang="scss" >
.dropdown-trigger {
        width: calc(100vw - 3.5rem);
    }
</style>