//
import sha256 from "sha256";
import axios from 'axios'
import moment  from 'moment'

export const invoices = {
    namespaced: true,
    state: {
        invoices: []
     },
    getters: {
        allInvoices: (state) => {
            //
            return state.invoices
        },
        getInvoices: (state) => {
            //
            const data = state.invoices
            data.sort((a,b) => new Date(b.date) -  new Date(a.date))
        
            return data
        },
        searchInvoices: state => (search) => {
            //
            const results = state.invoices.filter(({ ref, client, type, date, dd1,dd2, dueDate, status, total, paid, items }) => 
                ref.toLowerCase().includes(search.toLowerCase()) || 
                client.toLowerCase().includes(search.toLowerCase()) || 
                type.toLowerCase().includes(search.toLowerCase()) || 
                date.toLowerCase().includes(search.toLowerCase()) ||
                dd1.toLowerCase().includes(search.toLowerCase()) ||
                dd2.toLowerCase().includes(search.toLowerCase()) ||
                dueDate.toLowerCase().includes(search.toLowerCase()) ||
                status.toLowerCase().includes(search.toLowerCase()) ||
                total == search ||
                paid == search ||
                items.find(({ category, description })=> category.toLowerCase().includes(search.toLowerCase()) || description.toLowerCase().includes(search.toLowerCase()) )
            )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchDues: state => (search) => {
            //
            const results = state.invoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && moment(new Date(v.dueDate)).diff(moment(new Date), 'days') < 1 ).filter(({ ref, client, type, date, dd1,dd2, dueDate, dueDays, status, total, paid, items }) => 
                ref.toLowerCase().includes(search.toLowerCase()) || 
                client.toLowerCase().includes(search.toLowerCase()) || 
                type.toLowerCase().includes(search.toLowerCase()) || 
                date.toLowerCase().includes(search.toLowerCase()) ||
                dd1.toLowerCase().includes(search.toLowerCase()) ||
                dd2.toLowerCase().includes(search.toLowerCase()) ||
                dueDate.toLowerCase().includes(search.toLowerCase()) ||
                dueDays <= search ||
                status.toLowerCase().includes(search.toLowerCase()) ||
                total == search ||
                paid == search ||
                items.find(({ category, description })=> category.toLowerCase().includes(search.toLowerCase()) || description.toLowerCase().includes(search.toLowerCase()) )
            )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchReceivables: state => (search) => {
            //
            const results = state.invoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && v.date.includes((new Date()).toISOString().substring(0,4)) ).filter(({ ref, client, type, date, dd1,dd2, dueDate, status, total, paid, items }) => 
                ref.toLowerCase().includes(search.toLowerCase()) || 
                client.toLowerCase().includes(search.toLowerCase()) || 
                type.toLowerCase().includes(search.toLowerCase()) || 
                date.toLowerCase().includes(search.toLowerCase()) ||
                dd1.toLowerCase().includes(search.toLowerCase()) ||
                dd2.toLowerCase().includes(search.toLowerCase()) ||
                dueDate.toLowerCase().includes(search.toLowerCase()) ||
                status.toLowerCase().includes(search.toLowerCase()) ||
                total == search ||
                paid == search ||
                items.find(({ category, description })=> category.toLowerCase().includes(search.toLowerCase()) || description.toLowerCase().includes(search.toLowerCase()) )
            )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchMainInvoices: state => (search) => {
            
            const results = state.invoices.filter(({ product, business, category, tags }) => 
                product.toLowerCase().includes(search.toLowerCase()) || 
                business.toLowerCase().includes(search.toLowerCase()) || 
                category.toLowerCase().includes(search.toLowerCase()) || 
                tags.includes(search.toLowerCase()) 
            )

            const allBusiness =  results.map(product => product.business)
            const businesses  = [...new Set(allBusiness)]

            return businesses
        }, 
        searchBusinessInvoice: state => (search) => {
            //
            const results = state.invoices.filter(({ ref }) => ref.toLowerCase().includes(search.toLowerCase()) )

            return results
        },
        getDues: (state) => {
            const dues = state.invoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && moment(new Date(v.dueDate)).diff(moment(new Date), 'days') < 1 )

            return dues
        },
        searchDueRange: state => (search) => {
            //
            const range = state.invoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && v.date.includes((new Date()).toISOString().substring(0,4)) ).filter( v => v.dueDays <= search)

            return range
        },
        getReceivables: (state) => {
            const rec = state.invoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && v.date.includes((new Date()).toISOString().substring(0,4)) )

            return rec
        },
        verifyLink: (state) => (name, hash) =>  {
            
            //
            const secret = 225466879314

            if (sha256(name+secret) == hash) {
                return true
            } else {
                return false
            }
        },
        generateLinkHash: (state) => (name) => {
            const secret = 225466879314
            //
            return sha256(name+secret)
        }
    },
    actions: {
        async getInvoices({ commit },  business) {
            //
            let data
            //
            if (business.length > 0) {
                axios.get('/invoices/'+business).then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                    for (let index = 0; index < data.length; index++) {
            
                        // to 2 decimalplaces
                        // data[index].qty = 0
                        // date
                        data[index].dd1 = (new Date(data[index].date)).toDateString()
                        data[index].dd2 = (new Date(data[index].date)).toISOString()
                        data[index].dueDays =  Math.abs(moment(new Date(data[index].dueDate)).diff(moment(new Date), 'days'))
                    }
                    // })()
                    commit('updateInvoices', data)
                })

            } else {
                data = []
            }


            
        }
    },
    mutations: {
        updateInvoices: (state, data) => {
            state.invoices = data
        }
    }
}