
import moment from 'moment';
import { store } from '../store' 
//
const userlogin = 'islogged';
const userRefresh = 'isRefreshed';
const loginExpiryKey = 'tokenExpiry';
const loginRefreshExpiryKey = 'refreshTokenExpiry';
const Userinfo = 'user';
const Pageinfo = 'pages';
const Cartinfo = 'cart';
const Locinfo = 'location';
//
const maxTokenAge = 60 * 24;
const maxRefreshTokenAge = 60 * 24 * 31 * 6;
const maxRememberRefreshTokenAge = 60 * 24 * 31 * 12;

class Auth {
    //
    authToken = null
    uerProfile = null
    tokenExpiry = null
    refreshTokenExpiry = null 
    //
    login(authResult, Remember) {
        //
        this.tokenExpiry = new moment().add(maxTokenAge, 'minutes');
        this.refreshTokenExpiry = new moment().add((Remember ? maxRememberRefreshTokenAge : maxRefreshTokenAge ), 'minutes');

        localStorage.setItem(loginExpiryKey, this.tokenExpiry);
        localStorage.setItem(loginRefreshExpiryKey, this.refreshTokenExpiry);
        localStorage.setItem(userlogin, 'true');
        localStorage.setItem(Userinfo, JSON.stringify(authResult));
        //
        store.dispatch('auth/signin', authResult);
    }

    updateUser(authResult) {
        // return only user
        const user = JSON.parse(localStorage.getItem(Userinfo))
        // update personal only
        user.firstname = authResult.firstname
        user.lastname = authResult.lastname
        user.email = authResult.email
        user.bphone = authResult.bphone
        user.phone = authResult.phone
        user.momo = authResult.momo
        user.bemail = authResult.bemail
        user.address = authResult.address
        user.smsSender = authResult.smsSender
        user.sms = authResult.sms
        user.stockEnabled = authResult.stockEnabled
        user.invoiceGradientEnabled = authResult.invoiceGradientEnabled
        user.invoiceViewAlertEnabled = authResult.invoiceViewAlertEnabled
        user.invoiceViewDueEnabled = authResult.invoiceViewDueEnabled
        user.color = authResult.color
        user.gradientColor = authResult.gradientColor

        // set
        localStorage.setItem(Userinfo, JSON.stringify(user));
    }

    refreshToken(authResult) {
        this.tokenExpiry = new moment().add(maxTokenAge, 'minutes');
        localStorage.setItem(loginExpiryKey, this.tokenExpiry);
        localStorage.setItem(userRefresh, 'true');
        //
        if (authResult.status == "Refreshed") {
            // update
            let data = JSON.parse(localStorage.getItem(Userinfo))
            data.token = authResult.token 
            data.status = authResult.status
            data.refresh_time = authResult.refresh_time
            //
            localStorage.setItem(Userinfo, JSON.stringify(data));

        } else if (authResult.status == "Logged In") {
            // 
            this.refreshTokenExpiry = new moment().add(maxRememberRefreshTokenAge, 'minutes');
            localStorage.setItem(Userinfo, JSON.stringify(authResult));
        }

        localStorage.getItem(Userinfo)
    }

    logout() {
        localStorage.removeItem(loginExpiryKey);
        localStorage.removeItem(userlogin);
        localStorage.removeItem(loginRefreshExpiryKey);
        localStorage.removeItem(Userinfo);
        localStorage.removeItem(Pageinfo);
        localStorage.removeItem(Cartinfo);
        localStorage.removeItem(Locinfo);
        localStorage.removeItem(userRefresh);
    }

    isAuthenticated() {
        return (
            new Date(Date.now()) <=  new Date(localStorage.getItem(loginExpiryKey)) &&
            localStorage.getItem(userlogin) === 'true'
        );
    }

    getUser() {
        // return only user data
        const user = JSON.parse(localStorage.getItem(Userinfo))
        delete(user.token);
        delete(user.refresh_token);
        delete(user.status);
        delete(user.login_time);
        delete(user.refresh_time);
        delete(user.type);
        //
        return (
            localStorage.getItem(Userinfo) ? user : null
        );
    }
}

export default new Auth();