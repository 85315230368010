<template>
    <div>
        <div class="p-5">
            <!-- <icon
                type="chevron-back"
                class="m-4"
                tag="router-link"
                to="/"
                style="top:0;position:absolute"
            ></icon> -->
            <!-- <feather @click="$router.back()" size="30" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <h4 class="title is-4 ml-5 mb-5 pl-5">Alerts <b-tag v-if="allAlerts.length > 0 " type="is-primary">{{ allAlerts.length }}</b-tag></h4> -->
            <div class="columns is-mobile">
                    <div class="column is-half">
                        <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather>
                        <p class="px-2 py-2 pb-0 has-text-weight-bold ml-5"> Alerts  </p>
                    </div>
                    <div class="column py-4">
                        <b-button type="is-primary" class="is-pulled-right" icon-left="" @click="clearAll" rounded size="is-small"> Clear All  </b-button>
                        <!-- <b-button type="is-primary" class="is-pulled-right mr-3" icon-left="" @click="isModalActive = true" rounded size="is-small"> add  </b-button> -->
                        <b-button type="is-primary" class="is-pulled-right mr-3" icon-left="" @click="readAll" rounded size="is-small"> Read All  </b-button>
                    </div>
            </div>
            <div>
                    <div class="mb-5" style="max-width:460px;">
                        <b-field label="">
                            <b-input @input.native="onSearch" @keyup.native="load" :loading="loading" v-model="search" placeholder="Search anything"></b-input>
                        </b-field>
                    </div>
            </div>
            
            <AlertTemplate class="mb-5" :del="del" :alerts="data.length > 0 ? data : allAlerts" :showOnlyUnread="false"></AlertTemplate>
            
            <b-modal v-model="isModalActive"  scroll="keep">
            <div class="card">
                <div class="card-content">

                    <div class="p-3">
                        <h4 class="title is-5">{{ ref ? 'Edit' : 'Add' }} Alert</h4>
                        <p class="subtitle">Fill form below</p>
                    </div>
                    <div class="p-3">
                        
                        <div class="columns is-mobile ">
                            <div class="column">
                                <b-field>
                                    <!-- <b-input v-model="form.type" placeholder="Type"></b-input> -->
                                    <b-select expanded class="is--right" @input="onSelect" placeholder="Type" v-model="form.type">
                                            <option
                                                v-for="option in AlertType"
                                                :value="option"
                                                :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field>
                                    <b-input v-model="form.link" placeholder="Link"></b-input>
                                </b-field>
                            </div>
                         </div>
                         <div class="columns is-mobile ">
                            <div class="column">
                                <b-field>
                                    <!-- <b-input v-model="form.icon" placeholder="Icon"></b-input> -->
                                    <b-select expanded class="is--right" @input="onSelect2" placeholder="Icon" v-model="form.icon">
                                            <option
                                                v-for="option in IconType"
                                                :value="option"
                                                :key="option">
                                                {{ option }}
                                            </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field>
                                    <b-input v-model="form.params" placeholder="Params"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns is-mobile ">
                            <div class="column">
                                <b-field>
                                    <b-input type="textarea" v-model="form.message" placeholder="Message"></b-input>
                                </b-field>
                            </div>
                        </div>

                            <b-field class="pb-5 mb-3">
                                <b-button @click="onSubmit"  class="is-submit-button" expanded type="is-primary">Submit</b-button>
                            </b-field>
                    </div>
                </div>
            </div>
        </b-modal>
            <!-- <b-button v-if="addressChanged" @click="redirect"> Done </b-button> -->
           
        </div>

        <MainFooter />
        
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import AlertTemplate from '../components/AlertTemplate.vue'
    import MainFooter from '../components/footer.vue'
    import axios from 'axios'
import { name } from '@chenfengyuan/vue-countdown'

    export default {
        name: 'AlertsPage',
        data() {
            return {
                ref: null,
                isModalActive: false,
                data: [],
                searchString: '',
                addressChanged: false,
                loading: false,
                prevRoute: null,
                search: '',
                AlertType:  ['viewed', 'due', 'app'],
                IconType:  ['eye', 'calendar', 'bell'],
                matched: [
                    {
                        name: 'viewed',
                        icon: 'eye'
                    },
                    {
                        name: 'due',
                        icon: 'calendar'
                    },
                    {
                        name: 'app',
                        icon: 'bell'
                    }
                ],
                form: {
                    category: '',
                    type: null,
                    link: '',
                    icon: null,
                    params: '',
                    message: ''
                }
            }
        },
        components: {
            AlertTemplate,
            MainFooter
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from
            })
        },
        computed: {
            prevPath() { return this.prevRoute ? this.prevRoute : '/' },
        

            allAlerts() {
                return this.$store.getters['alert/getAlerts']
            },
            ...mapGetters('auth', ['getUser']),

        },
        watch: {
            search() {
                if (this.searchString.length == 0) {
                    this.data = []
                }
            },
        },
        mounted() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.$store.dispatch('alert/getAlert')
            },
            checkAlerts() {
                //
                axios.get('/alert/').then(response=>{
                    //
                    this.data = response.data
                    // this.visitRates = response.data.reduce((a, b) => a + b.rate, 0) !== 0 ? response.data.reduce((a, b) => a + b.rate, 0) / this.visits : 0

                    
                    // const shop = response.data.shops.find( (shop) => shop.shop.includes(this.currentShop.name))
                    //
                    // this.preferences = shop.preferences
                    // this.preferencesId = shop._id

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            goBack() {
                this.$router.back()
                // this.$router.push('home')
            },
            onSubmit(event) {
                // validate
                // if ( !this.validateName() ){
                // if ( (!this.form.client || (!this.validateName() && !this.validateContact())) || !this.form.items.reduce((a, b) => a + b.total, 0) > 0 ){
                    // return
                // }

                this.isLoading = true

                //
                this.form.business = this.getUser.business
                
                let method,url
                //
                if (this.ref) {
                    url = '/alert/'+this.ref
                    method = axios.patch
                } else {
                    url = '/alert/'
                    method = axios.post
                }
                
                // ajax
                method(url, this.form).then(response=>{
                    //
                    console.log(response.data)
                    this.fetchData()
                    this.isModalActive = false
                    this.ref = null
                    // 
                    this.$buefy.toast.open(response.data)

                    this.isLoading = false

                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // alert(JSON.stringify(this.form))
            },
            // onSubmit() {
            //     if (this.searchString.length >= 3) {
            //         console.log(this.searchString)
            //     }
            // },
            async onSearch() {
                // console.log('here')
                if (this.search.length >= 0) {
                    
                   
                    const result = await this.$store.getters['alert/searchMainAlert'](this.search)

                    this.data = result
                    
                }
            }, 
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            },
            onSelect(value) {
                this.form.icon = this.matched.find(t=>t.name == value).icon
            },
            onSelect2(value) {
                this.form.type = this.matched.find(t=>t.icon == value).name
            },
            del(data) {
                this.$buefy.dialog.confirm({
                    // title: 'Delete Payment?',
                    message: `<b>Delete Alert?</b><br> Are you sure you want to <b>delete</b> this alert? This action cannot be undone.`,
                    confirmText: 'Delete',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        
                        //
                        axios.delete('/alert/'+this.getUser.business+'/'+data._id).then((res)=>{
                            //
                            // this.invoice = res.data[0];
                            // this.pay.brands[0].total = res.data[0].total;

                            // this.isLoading = false
                            this.$buefy.toast.open(res.data)

                            this.fetchData()

                            // this.checkShop()
                            // this.checkVisit()
                        }).catch(error=>{
                            this.$buefy.toast.open(error.response.data);
                        })
                    }
                })
            },
            clearAll () {
                this.$buefy.dialog.confirm({
                    // title: 'Delete Payment?',
                    message: `<b>Clear All Alerts?</b><br> Are you sure you want to <b>delete</b> all alerts? This action cannot be undone.`,
                    confirmText: 'Delete All',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        
                        //
                        axios.delete('/alert/'+this.getUser.business+'/').then((res)=>{
                            //
                            // this.invoice = res.data[0];
                            // this.pay.brands[0].total = res.data[0].total;

                            // this.isLoading = false
                            this.$buefy.toast.open(res.data)

                            this.fetchData()

                            // this.checkShop()
                            // this.checkVisit()
                        }).catch(error=>{
                            this.$buefy.toast.open(error.response.data);
                        })
                    }
                })
            },
            readAll() {
                axios.patch('/alert/read/all', { business: this.getUser.business }).then((res)=>{
                            //
                            // this.invoice = res.data[0];
                            // this.pay.brands[0].total = res.data[0].total;

                            // this.isLoading = false
                            this.$buefy.toast.open(res.data)

                            this.fetchData()

                            // this.checkShop()
                            // this.checkVisit()
                }).catch(error=>{
                    this.$buefy.toast.open(error.response.data);
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .dark {
        color: $dark;
    }
</style>