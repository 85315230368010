<template>
    <div>
        <div class="sidebar-wrapper">
            <SideNav hidden />
        </div>
        <!-- {{ vendors }} -->
        
        <div class="p-4" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            <!-- <VendorSkeleton/> -->



            <div hidden class="pb-2 m-3">
                <h1 class="title is-2">Account<span style="font-weight:300;">Onthego.</span> </h1>
                <p class="subtitle is-5 has-text-grey">Your pocket accountant</p>
            </div>

            <!-- <p class="px-3 py-3 pb-0 has-text-weight-bold"> Dashboard </p> -->

            <div class="columns is-mobile">
                <div class="column is-half">
                    <b-field class="report">
                        <!-- <b-switch v-model="graphIsSwitched" size="is-small">Area Graph</b-switch> -->
                        <b-select size="is-small" placeholder="Select a graph" v-model="graph">
                            <option
                                value="area"
                                key="area">
                                Area Graph
                            </option>
                            <option
                                value="line"
                                key="line">
                                Line Graph
                            </option>
                        </b-select>
                    </b-field>   
                </div>
                <div class="column">
                    <b-field class="report">
                        <b-select size="is-small" class="is-pulled-right"  placeholder="Select a report" v-model="option">
                            <option
                                v-for="option in options"
                                :value="option.id"
                                :key="option.id">
                                {{ option.name }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            

            
            <!-- type="bar" -->
            <div class="monthly-visit">
                            <div id="column-chart">
                              <ApexChart
                                v-if="graph == 'area'"
                                ref="areaChart"
                                height="250"
                                type="area"
                                :options="apexTotalsale.options"
                                :series="apexTotalsale.series"
                              ></ApexChart>
                            </div>
                            <div  id="column-chart">
                              <ApexChart
                                v-if="graph == 'line'"
                                ref="barChart"
                                height="250"
                                type="bar"
                                :options="apexTotalvisit.options"
                                :series="apexTotalvisit.series"
                              ></ApexChart>
                            </div>
                          </div>

            <!-- <figure class="image is-16by9">
                <img src="https://bulma.io/images/placeholders/640x360.png">
            </figure> -->

            <p class="px-3 pt-3 pb-0 has-text-weight-bold">Statistics</p>
            <!-- <div class="card"> -->
                <!-- <div class="card-content"> -->
                    <div class="content p-2 text-center mt-4">
                        <div class="columns is-mobile is-multiline">
                            
                            <div @click="goto('invoice')" class="column card p-4 m-1">
                                <b-tooltip :label="ins.toLocaleString()">
                                    <h4 class="title mb-1 is-4 has-text-weight-bold"> {{ kmForm(ins) }}</h4>
                                    <p class="small has-text-weight-medium">Invoices</p>
                                    
                                </b-tooltip>
                            </div>
                            <div @click="goto('payment')" class="column card p-4 m-1">
                                <b-tooltip :label="pays.toLocaleString()">
                                    <h4 class="title mb-1 is-4 has-text-weight-bold">{{ kmForm(pays) }}</h4>
                                    <p class="small has-text-weight-medium">Paid</p>
                                </b-tooltip>
                            </div>
                            <div @click="goto('expense')" class="column card p-4 m-1">
                                <b-tooltip :label="exps.toLocaleString()">
                                    <h4 class="title mb-1 is-4 has-text-weight-bold">{{ kmForm(exps) }}</h4>
                                    <p class="small has-text-weight-medium">Expenses</p>
                                </b-tooltip>
                            </div>
                            
                            
                            <!-- <div class="column card p-4 m-1">
                                <h4 class="title is-3">14</h4>
                                <p class="subtitle has-text-grey">Orders</p>
                            </div> -->
                        </div>
                    </div>
                    <div class="content p-2 text-center mb-4">
                        <div class="columns is-mobile is-multiline">
                            
                            <div @click="$router.push({ name:'invoice', params: { search: new Date().toDateString().substr(4,3) } })" class="column card p-4 m-1">
                                <b-tooltip :label="mons.toLocaleString()">
                                    <h4 class="title mb-1 is-4 has-text-weight-bold">{{ kmForm(mons) }}</h4>
                                    <p class="small has-text-weight-medium">Monthly</p>
                                </b-tooltip>
                            </div>
                            <div @click="goto('receivables')" class="column card p-4 m-1">
                                <b-tooltip :label="rec.toLocaleString()">
                                    <h4 class="title mb-1 is-4 has-text-weight-bold">{{ kmForm(rec) }}</h4>
                                    <p class="small has-text-weight-medium">Unpaid</p>
                                </b-tooltip>
                            </div>
                            <div @click="goto('due')" class="column card p-4 m-1">
                                <b-tooltip :label="due.toLocaleString()">
                                    <h4 class="title mb-1 is-4 has-text-weight-bold">{{ kmForm(due) }}</h4>
                                    <p class="small has-text-weight-medium">Due</p>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            <!-- </div> -->

            <p v-if="alerts.length > 0" @click="$router.push('alert')" class="px-3 mb-3 p-3 has-text-weight-bold"> Alerts
                <!-- <b-tag type="is-dark"> 5</b-tag> -->
            </p> 
            <AlertTemplate v-if="alerts.length > 0" :alerts="alerts" />

            <p @click="$router.push('invoice')" class="px-3 mb-3 p-3 has-text-weight-bold"> Recent Invoices
                <!-- <b-tag type="is-dark"> 5</b-tag> -->
            </p> 
            <!-- <AlertTemplate :alerts="alerts" /> -->

            
            <InvoiceTemplate :invoices="getInvoices.slice(0,5)" class="pb-5 px-1"></InvoiceTemplate>

            <div v-if="false" class="contain p-3 mb-3">
                <a class="columns p-1 card is-mobile mb-5">
                    <!-- <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center"> -->
                        <!-- <div class=" bg-grey is-flex is-justify-content-center is-align-items-center" style="width: 10vw;height:  10vw; border-radius: 100%">
                            <feather size="18" class="has-text-primary" type="bell"></feather>
                        </div> -->
                        <!-- <b-button  type="is-primary" icon-left="bell"></b-button> -->
                        <!--  -->
                        <!-- <p> Apr 12</p> -->
                        <!-- <span class="small has-text-grey">12:00</span> -->
                            <!-- <feather size="26" class="has-text-primary" type="bell"></feather> -->
                    <!-- </div> -->
                    <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                        <feather size="22" class="has-text-primary" type="bell"></feather>
                        <b-button  type="is-primary" icon-left="bell"></b-button>
                    </div>
                    <div class="column">
                        <p class="list-title">Helloo kindly check out</p>
                        <span class="has-text-grey small ">Apr 12 </span>
                    </div>
                    <div class="column is-one-fifth is-flex is-justify-content-center is-align-items-center">
                        <feather size="20" class="has-text-primary" type="check-square"></feather>
                    </div>
                </a>
            </div>

            
            <div class="space my-4 "></div>
            
        </div>
        <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
       
        <MainFooter />

    </div>
</template>

<script>

    import { mapGetters, mapActions} from 'vuex'
    import { store } from '@/store'
    import router from '@/router'
    import moment  from 'moment';
    
    import axios from 'axios'
    import SideNav from '../components/sidenav'
    // import VendorSkeleton from '../components/VendorSkeleton'
    import AlertTemplate from '../components/AlertTemplate.vue'
    import InvoiceTemplate from '../components/invoiceTemplate.vue'
    
    import MainFooter from '../components/footer'

    var primary = localStorage.getItem("primary_color") || "#092f9f";
    var secondary = localStorage.getItem("secondary_color") || "#e10d0d";
    var tertiary = localStorage.getItem("tertiary_color") || "#979797";

    export default {
        name: 'IndexPage',
        data(){
            return {
                options: [
                    {
                        id: 0,
                        name: '1st Half',
                        cat:  [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                        ]
                    },
                    {
                        id: 1,
                        name: '2nd Half',
                        cat: [
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                        ]
                    }
                ],
                option: 0,
                graphIsSwitched: true,
                graph: 'area',
                areaVisible: true,
                barVisible: true,
                invoicePaid: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                invoiceUnpaid: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                expenseData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                apexTotalvisit: {
                    options: {
                        chart: {
                            height: 105,
                            type: "bar",
                            // stacked: true,
                            toolbar: {
                            show: false,
                            },
                        },
                        plotOptions: {
                            bar: {
                            dataLabels: {
                                position: "top", // top, center, bottom
                            },
                            columnWidth: "40%",
                            startingShape: "rounded",
                            endingShape: "rounded",
                            },
                        },
                        colors: [primary, secondary, tertiary],
                        dataLabels: {
                            enabled: false,

                            formatter: function (val) {
                            return val + "%";
                            },
                            offsetY: -10,
                            style: {
                            fontSize: "12px",
                            colors: [primary, secondary, tertiary],
                            },
                        },
                        xaxis: {
                            categories: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            // "Jul",
                            // "Aug",
                            // "Sep",
                            // "Oct",
                            // "Nov",
                            // "Dec",
                            ],
                            position: "bottom",

                            axisBorder: {
                                show: false,
                            },
                            axisTicks: {
                                show: false,
                            },
                            crosshairs: {
                                fill: {
                                    type: "gradient",
                                    gradient: {
                                        colorFrom: primary,
                                        colorTo: secondary,
                                        stops: [0, 100],
                                        opacityFrom: 0.4,
                                        opacityTo: 0.5,
                                    },
                                },
                            },
                            // tooltip: {
                            //     enabled: true,
                            // },
                            // labels: {
                            //     show: true,
                            // },
                        },
                        yaxis: {
                            axisBorder: {
                            show: false,
                            },
                            axisTicks: {
                            show: false,
                            },
                            labels: {
                            show: false,
                            formatter: function (val) {
                                return val;
                            },
                            },
                        },
                        grid: {
                            show: false,
                            padding: {
                                top: -35,
                                right: 20,
                                bottom: 10,
                                left: -10,
                            },
                        },
                        legend: {
                            show: true,
                        },
                    },
                    series: [
                        {
                            name: "Paid ",
                            data: [0, 0, 0, 0, 0, 0],
                            // data: [10, 5, 30, 150, 50, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            name: "Expenses ",
                            data: [0, 0, 0, 0, 0, 0],
                            // data: [50, 2, 100, 35, 60, 0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            name: "Unpaid",
                            data: [0, 0, 0, 0, 0, 0],
                            // data: [50, 2, 100, 35, 60, 0, 0, 0, 0, 0, 0, 0],
                        },
                        // {
                        //     name: "Payables ",
                        //     data: [150, 30, 100, 35, 20, 5],
                        //     // data: [50, 2, 100, 35, 60, 0, 0, 0, 0, 0, 0, 0],
                        // },
                    ],
                },
                apexTotalsale: {
                    options: {
                        chart: {
                            height: 150,
                            type: "area",
                            toolbar: {
                            show: false,
                            },
                        },
                        colors: [primary, secondary, tertiary],
                        dataLabels: {
                            enabled: false,
                        },
                        stroke: {
                            curve: "smooth",
                            width: 0,
                        },
                        xaxis: {
                            // type: "datetime",
                            categories: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec",
                            ],
                        },
                        yaxis: {
                            low: 0,
                            offsetX: 0,
                            offsetY: 0,
                            show: false,
                            labels: {
                            low: 0,
                            offsetX: 0,
                            show: true,
                            },
                            axisBorder: {
                            low: 0,
                            offsetX: 0,
                            show: false,
                            },
                        },
                        fill: {
                            type: "gradient",
                            opacity: [1, 0.6, 0.45],
                            gradient: {
                            shade: "light",
                            type: "horizontal",
                            shadeIntensity: 1,
                            gradientToColors: ["#a26cf8", "#a927f9", primary],
                            opacityFrom: [1, 0.7, 0.45],
                            opacityTo: [1, 0.7, 0.45],
                            stops: [30, 100],
                            colorStops: [],
                            },
                            // colors: [primary, primary, primary],
                            colors: [primary, secondary, tertiary],
                        },
                        tooltip: {
                            x: {
                            format: "dd/MM/yy HH:mm",
                            },
                        },
                        legend: {
                            show: true,
                            // position: 'top',
                        },
                        grid: {
                            show: false,
                            padding: {
                            left: 10,
                            right: -2,
                            top: -25,
                            bottom: 10,
                            },
                        },
                    },
                    series: [
                    {
                        name: "Paid",
                        // data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        data: [0, 0, 0, 0, 0, 0 ],
                    },
                    {
                        name: "Expenses",
                        // data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        data: [0, 0, 0, 0, 0, 0],
                    },
                    {
                      name: "Unpaid",
                      data: [0, 0, 0, 0, 0, 0],
                    },
                    ],
                },
                ins: 0,
                pays: 0,
                exps: 0,
                rec: 0,
                due: 0,
                mons: 0,
                visitRates: 0,
                sliders: [],
                carousels: [
                    // { text: 'Slide 1', img: 'https://bulma.io/images/placeholders/640x320.png', link: 'visits' },
                    // { text: 'Slide 2', img: 'https://bulma.io/images/placeholders/640x320.png', link: 'profile' },
                    // { text: 'Slide 3', img: 'https://bulma.io/images/placeholders/640x320.png', link: 'myshops' },
                    // { text: 'Slide 4', img: 'https://bulma.io/images/placeholders/640x320.png', link: 'mapview' },
                    { text: 'Slide 1', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1690889676/shopnsave/sliders/shoprite1_htb1eb.jpg', link: 'shop', name: 'Shoprite - Accra Mall' },
                    { text: 'Slide 2', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1690889676/shopnsave/sliders/mrp_slide_lkcyhb.jpg', link: 'shop', name: 'MRP Accra Mall' },
                    { text: 'Slide 3', img: 'https://res.cloudinary.com/df8hf97qf/image/upload/v1690889674/shopnsave/sliders/kimo_slide_l9d1z2.jpg', link: 'shop', name: 'Kimo Home Spintex' },
                    // { text: 'Slide 2', img: require('@/assets/img/slider/5.jpg') }
                    
                ],
                screenWidth: 0,
                lastPath: null,
                vendors: { },
                isLoading: false,
                alerts_: [
                    {
                        id: 1,
                        message: "Welcome to shopnscan",
                        date: "Apr 24",
                        icon: "bell",
                        link: "",
                        read: true,
                    },
                    {
                        id: 2,
                        message: "Hurrey you've reviewed Woodin - Accra Mall",
                        date: "Apr 24",
                        icon: "cart",
                        link: "",
                        read: false,
                    },
                    {
                        id: 3,
                        message: "Hello check out your points",
                        date: "Apr 24",
                        icon: "certificate",
                        link: "",
                        read: false,
                    },
                    {
                        id: 4,
                        message: "Visit any partner shops to redeem 10 points.",
                        date: "Apr 24",
                        icon: "cart",
                        link: "",
                        read: false,
                    },
                ]
            }
        },
        components: {
            SideNav,
            // VendorSkeleton,
            AlertTemplate,
            MainFooter,
            InvoiceTemplate
        },
        created() {
            // watch and fetch
            this.$watch(
            () => this.$route.$params,
            async () => {
                // this.$store.dispatch('vendors/getBusiness')
                
                // mapActions('vendors',['getBusiness'])
                this.fetchData()

            },
            { immediate: true }

            )

        },
        watch: {
            getInvoices() {
                if (this.getInvoices) {
                    // dash
                    this.ins = this.getInvoices.filter(v=> v.type == 'Invoice' && v.date.includes((new Date()).toISOString().substring(0,4))).reduce((a, b) => a + b.total, 0);
                    this.pays = this.getInvoices.filter(v=> v.type == 'Invoice' && ['paid', 'part payment'].includes(v.status) && v.date.includes((new Date()).toISOString().substring(0,4))).reduce((a, b) => a + b.paid, 0);
                    this.rec = this.ins - this.pays;
                    this.mons = this.getInvoices.filter(v => v.type == 'Invoice' && v.date.includes((new Date()).toISOString().substring(0,7))).reduce((a, b) => a + b.total, 0);

                    // due
                    const total = this.getInvoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && moment(new Date(v.dueDate)).diff(moment(new Date), 'days') < 0).reduce((a, b) => a + b.total, 0);

                    const paid = this.getInvoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && moment(new Date(v.dueDate)).diff(moment(new Date), 'days') < 0).reduce((a, b) => a + b.paid, 0);

                    this.due = total - paid

                    //
                    let record0 = []
                    let record2 = []
                    //
                    this.getYear().forEach(month => {
                        //
                        const tot = this.getInvoices.filter(v=> v.type == 'Invoice' && v.date.includes(month.substring(0,7))).reduce((a, b) => a + b.total, 0)

                        const pay = this.getInvoices.filter(v=> v.type == 'Invoice' && ['paid', 'part payment'].includes(v.status) && v.date.includes(month.substring(0,7))).reduce((a, b) => a + b.paid, 0)

                        // this.getInvoices.filter(v=> v.type == 'Invoice' && v.status == "pending" && v.date.includes(month.substring(0,7))).reduce((a, b) => a + b.total, 0)
                        
                        record0.push(pay)
                        record2.push(tot - pay)

                    });

                    let data0
                    let data2

                    this.invoicePaid = record0
                    this.invoiceUnpaid = record2
                    
                    if (this.option == 0) {
                        data0 = record0.slice(0,6)
                        data2 = record2.slice(0,6)
                    } else {
                        data0 = record0.slice(6)
                        data2 = record2.slice(6)
                    }

                    this.apexTotalvisit.series[0].data = data0
                    this.apexTotalvisit.series[2].data = data2

                    this.apexTotalsale.series[0].data = data0
                    this.apexTotalsale.series[2].data = data2

                    if (this.$refs.areaChart) {
                        this.$refs.areaChart.refresh()

                    } else {
                        this.$refs.barChart.refresh()
                    }

                    // console.log(record0)
                    // console.log(record2)
                }
            },
            getExpenses() {
                if (this.getExpenses) {
                    // dash
                    this.exps = this.getExpenses.filter(v=> v.date.includes((new Date()).toISOString().substring(0,4))).reduce((a, b) => a + b.amount, 0);

                    //
                    let record1 = []
                    //
                    this.getYear().forEach(month => {
                        //
                        record1.push(this.getExpenses.filter(v=> v.date.includes(month.substring(0,7))).reduce((a, b) => a + b.amount, 0))

                    });

                    let data1
                    
                    if (this.option == 0) {
                        data1 = record1.slice(0,6)
                    } else {
                        data1 = record1.slice(6)
                    }
                    
                    this.apexTotalvisit.series[1].data = data1

                    this.apexTotalsale.series[1].data = data1
                    //

                    if (this.$refs.areaChart) {
                        this.$refs.areaChart.refresh()

                    } else {
                        this.$refs.barChart.refresh()
                    }

                    this.expenseData = record1
                }
            },
            invoice() {
                if (this.invoice) {

                    let record2 = []
                    let record1 = []
                    
                    this.getYear().forEach(month => {
                        //
                        record1.push(this.visits.filter(v=> v.date.includes(month.substring(0,7)) && v.status == "paid").length)


                        record2.push(this.visits.filter(v=> v.date.includes(month.substring(0,7)) && v.status == "pending").length)

                    });
                    
                    this.apexTotalsale.series[1].data = record1
                    this.apexTotalvisit.series[0].data = record2
                }
            },
            costs() {
               if (this.costs){
                //
               } 
            },
            option() {
                //
                if (this.option == 0) {
                    
                    let data0 = this.invoicePaid.slice(0,6)
                    let data1 = this.expenseData.slice(0,6)
                    let data2 = this.invoiceUnpaid.slice(0,6)

                    this.apexTotalvisit.options.xaxis.categories = this.options[0].cat
                    this.apexTotalsale.options.xaxis.categories = this.options[0].cat

                    this.apexTotalvisit.series[0].data = data0
                    this.apexTotalvisit.series[1].data = data1
                    this.apexTotalvisit.series[2].data = data2


                    this.apexTotalsale.series[0].data = data0
                    this.apexTotalsale.series[1].data = data1
                    this.apexTotalsale.series[2].data = data2

                    if (this.$refs.areaChart) {
                        this.$refs.areaChart.refresh()

                    } else {
                        this.$refs.barChart.refresh()
                    }
                }
                else if (this.option == 1) {

                    let data0 = this.invoicePaid.slice(6)
                    let data1 = this.expenseData.slice(6)
                    let data2 = this.invoiceUnpaid.slice(6)

                    this.apexTotalvisit.options.xaxis.categories = this.options[1].cat
                    this.apexTotalsale.options.xaxis.categories = this.options[1].cat

                    this.apexTotalvisit.series[0].data = data0
                    this.apexTotalvisit.series[1].data = data1
                    this.apexTotalvisit.series[2].data = data2


                    this.apexTotalsale.series[0].data = data0
                    this.apexTotalsale.series[1].data = data1
                    this.apexTotalsale.series[2].data = data2
                    
                    console.log(this.$refs.barChart)

                    if (this.$refs.areaChart) {
                        this.$refs.areaChart.refresh()

                    } else {
                        this.$refs.barChart.refresh()
                    }
                }

                // console.log(this.apexTotalvisit.series[0].data)
                
            },
        },
        mounted(){
            
            this.updateScreenWidth();
            this.onScreenResize();

        },
        computed: {
            alerts() {
                return this.$store.getters['alert/getAlerts']
            },
            ...mapGetters('auth', ['getUser']),
            ...mapGetters('invoices', ['getInvoices']),
            ...mapGetters('payments', ['getPayments']),
            ...mapGetters('expenses', ['getExpenses']),
        },
        methods: {
            fetchData() {
                // fetch
                this.$store.dispatch('alert/getAlert')
                this.$store.dispatch("clients/getClients", this.getUser.business);
                this.$store.dispatch("invoices/getInvoices", this.getUser.business);
                this.$store.dispatch("payments/getPayments", this.getUser.business);
                this.$store.dispatch("expenses/getExpenses", this.getUser.business);
            },
            render(v) {

                if (v.shop) {
                    //
                    this.postClick(v)

                    this.$router.push({ name: v.link, query: { shop: v.shop } })
                }
                // console.log(v)
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            async getVendors() {

                this.isLoading = true

                await this.$store.dispatch('vendors/getBusiness').then(()=>{
                    this.isLoading = false
                })

            },
            checkShops() {
                //
                axios.get('/user/pref').then(response=>{
                    //
                    this.shops = response.data.shops.length

                    this.points = response.data.points ? response.data.points.reduce((a, b) => a + b.point, 0) : 0                

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            postClick(data) {
                //
                axios.post('/slider/click', { slider: data.shop, user: {
                    countryCode: this.$store.getters['auth/getUser'].phone.substring(0,4),
                    tel: this.$store.getters['auth/getUser'].phone.substring(4)
                } } ).then(response=>{
                    //
                    console.log(response.data)              

                }).catch(error=>{
                    //
                    console.log(error.response.data)
                })
            },
            getShops() {
                this.$store.dispatch('alert/getAlert')
            },
            checkAlerts() {
                //
                axios.get('/alert').then(response=>{
                    //
                    this.alerts = response.data            

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            checkVisits() {
                //
                axios.get('/shop/visit').then(response=>{
                    //
                    this.visits = response.data.length
                    // this.visitRates = response.data.reduce((a, b) => a + b.rate, 0) !== 0 ? response.data.reduce((a, b) => a + b.rate, 0) / this.visits : 0

                    
                    // const shop = response.data.shops.find( (shop) => shop.shop.includes(this.currentShop.name))
                    //
                    // this.preferences = shop.preferences
                    // this.preferencesId = shop._id

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            getSliders() {
                //
                axios.get('/sliders').then(response=>{
                    //
                    this.sliders = response.data.filter(s=>s.visible).filter(s=> (s.expiry ? new Date(s.end) : new Date()) >= new Date())
                    // check 
                    

                }).catch(error=>{
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            goto(link) {
                this.$router.push(link)
            },
            setAddress(loc) {
                //
                this.$store.dispatch('vendors/addPlace', { address: loc.address, location: loc.location })
                //
                this.getVendors()
            },
            kmForm(num) {
                return Math.abs(num) > 999999 ? Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'm' : Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : (Math.sign(num)*Math.abs(num))
            },
            getYear(year) {

                var firstmonth = year ? year+"-01-01" :  new Date().getFullYear()+"-01-01"
                // var lastday = new Date(curr.setDate(last)).toDateString().substring(4);

                let months =[]
                //
                for (let index = 0; index < 12; index++) {

                const d = moment(firstmonth).add(index, 'months').toDate().toISOString().substring(0,10)

                months.push(d)
                }

                // console.log(days)
                return months

            },
            get1stHalf(year) {

                var firstmonth = year ? year+"-01-01" :  new Date().getFullYear()+"-01-01"
                // var lastday = new Date(curr.setDate(last)).toDateString().substring(4);

                let months =[]
                //
                for (let index = 0; index < 6; index++) {

                const d = moment(firstmonth).add(index, 'months').toDate().toISOString().substring(0,10)
                
                months.push(d)
                }

                // console.log(days)
                return months

            },
            get2ndHalf(year) {

                var firstmonth = year ? year+"-07-01" :  new Date().getFullYear()+"-07-01"
                // var lastday = new Date(curr.setDate(last)).toDateString().substring(4);

                let months =[]
                //
                for (let index = 0; index < 6; index++) {

                const d = moment(firstmonth).add(index, 'months').toDate().toISOString().substring(0,10)

                months.push(d)
                }

                // console.log(days)
                return months

            }
        }
    }
</script>

<style lang="scss" scoped>
    // custom
    @import '@/assets/scss/custom_variables.scss';

    .small {
        font-size: 0.8rem;
    }

    .white {
        color: $white !important;
    }

    .carousel-items {
        border-radius: 1rem;
    }
    .slider-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    .space {
        padding: 5px;
    }

    .cov {
        overflow-x: scroll;
        -ms-overflow-style: none;
        -moz-overflow-style: none;
        scrollbar-width: none;
    }

    .cov::-webkit-scrollbar {
    display: none;
    }

    .cov .media-content {
        overflow: hidden;
    }

    .card-content  {
        padding: 1rem 1.25rem  !important;
    }

    .media-content .b-skeleton {
        margin:  0 !important;
    }

    
</style>