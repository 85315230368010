<template>
    <div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        <div class="pb-5">
            <!-- <h4 class="subtitle is-2 mb-0" style="text-align:center">Heath <i class="has-text-danger">Line</i></h4> -->
            <!-- <p style="text-align:center;font-weight:300" class="subtitle is-6 has-text-gray">your health in your palm</p> -->
        </div>
        <div class="auth">
            <div class=" px-3 py-4">
                <div class="-content">
                    <div class="content">
                        <div class="pb-1 text-center">
                            <img style="width:170px;" :src="require('@/../public/fav-small.jpg')" class="logo "/>
                            <!-- <h1 class="title title is-1 mt-0">Account <br> <span style="font-weight:300;">Onthego</span></h1> -->
                            <p class="subtitle is-5 has-text-grey">Your business companion </p>
                        </div>
                        <div class="pt-5 pb-2">
                            <div class="notification small text-center"> OTP has been sent to your phone <br>
                                Enter OTP here.
                                <!-- <router-link  to="/setup">Sign up </router-link> -->
                                <!-- <vue-feather type="star"></vue-feather> -->
                            </div>
                            <div class="columns is-mobile">
                                <div class="column is-full">
                                    <div style="display: flex; flex-direction: row;">
                                        <v-otp-input
                                        ref="otpInput"
                                        input-classes="otp-input input"
                                        separator=" "
                                        :num-inputs="6"
                                        :should-auto-focus="true"
                                        :is-input-num="true"
                                        @on-change="onChange"
                                        @on-complete="onSubmit"
                                        />

                                        <!-- <button @click="handleClearInput()">Clear Input</button> -->
                                    </div>
                                    
                                </div>
                            </div>
                            
                            <b-field class=" text-center">
                                <b-button @click="onSubmit" expanded class="is-submit-button" type="is-primary">Signin</b-button>
                            </b-field>
                            <div class="text-center py-3">
                                <p class="is-dark">
                                    <a  @click="didntRecieveOTP" class="small has-text-black" type="is-dark" to="/signin"> Oops, invalid contact ? </a>

                                </p>
                                <a class="small has-text-black" v-if="expired == false">
                                    Wait 00:{{  now.toFixed().length == 2 ? now : '0'+now }}
                                </a>
                                <a v-else @click="resendOTP" class="small has-text-black" type="is-dark" to="/signin"> Resend OTP </a>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { userService } from '../services';

    export default {
        name:'VerifyotpPage',
         data() {
            return {
                isLoading: false,
                isFullPage: true,
                form: {
                country: '',
                contact: '',
                code: '+233',
                remember: true,
                },
                type: '',
                message: '',
                show: true,
                now: 45,
                modifiedDate: 0,
                expired: false
            }
        },
        mounted() {
            this.Time()

        },
        created() {
            // watch and fetch
            this.$watch(
            () => this.$route.params,
            () => {
                //
                if (Object.keys(this.$route.params).length !== 0) {
                    
                    const data = {
                        link: 'verifyotp',
                        data: this.$route.params
                    }

                    this.form.code = this.$route.params.code
                    this.form.contact = this.$route.params.contact
                    this.type = this.$route.params.type
                    
                    this.$store.dispatch('pages/setPage', data)

                } else {
                    
                    const data = this.$store.getters['pages/getPage']('verifyotp')

                    if (data !== null) {
                        // this.form = data.search
                        this.form.code = data.code
                        this.form.contact = data.contact
                        this.type = data.type
                    }
                }

                
            },
            { immediate: true }

            )

        },
        methods: {
            Time(){
                const timer = setInterval(() => {
                    this.now--;
                    this.expired = this.now <= this.modifiedDate;
                    if (this.expired == true) {
                        clearInterval(timer)
                    }
                }, 1000);
            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onChange(value){
                // console.log(value)
            },
            didntRecieveOTP(){
                // ajax
                this.$router.push('/signin')
                // axios.post('/user/login', this.form).then(response=>{
                //     //
                //     console.log(response.data)
                //     // 
                //     this.form.type = response.data.type
                //     this.$buefy.toast.open(response.data.message)

                //     //

                // }).catch(error=>{
                //     //
                //     console.log(error)
                //     //
                //     this.$buefy.toast.open(error.response.data)
                // })
            },
            resendOTP(){
                //
                this.now  = 60
                this.Time()

                // ajax
                axios.post('/user/resend', { code: this.form.code, contact: this.form.contact, type: this.type } ).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.form.type = response.data.type
                    this.$buefy.toast.open(response.data.message)

                    //

                }).catch(error=>{
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            onSubmit(value) {

                this.isLoading = true

                setTimeout(() => {
                    
                    this.isLoading = false
                    //
                    this.form.OTP = value

                    userService.verify(this.form.code, this.form.contact, this.form.remember, this.form.OTP ).then(response => {

                        //
                        console.log(response)
                        //
                        this.$buefy.toast.open("Successfully Signed in!")
                        //
                        this.$router.push({ name: 'index' })

                        // this.$store.dispatch('auth/signin')

                    }).catch(error=>{
                        //
                        console.log(error)
                        //
                        this.$buefy.toast.open(error.response.data)
                    })

                    // ajax
                    // axios.post('/user/verify', this.form).then(response=>{
                    //     //
                    //     console.log(response)
                    //     if (response.data.type == "Signin") {
                    //         //
                    //         this.$buefy.toast.open("Successfully Singned in!")
                    //         //
                    //         this.$router.push({ name: 'home' })

                    //     }
                    //     else if (response.data.type == "Signup") {
                    //         //
                    //         this.$buefy.toast.open("Almost there, just some few steps!")
                    //         //
                    //         this.$router.push({ name: 'setup', params: { code: this.form.code, contact: this.form.contact } })
                    //     }

                    //     // set login
                    //     localStorage.setItem('user', JSON.stringify(response.data));
                        
                    //     // set token in all request
                    //     axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`

                    // }).catch(error=>{
                    //     //
                    //     console.log(error.response.data)
                    //     //
                    //     this.$buefy.toast.open(error.response.data)
                    // })

                }, 1.2 * 1000)
                

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            }
        }
    }
</script>

<style lang="scss" >
.auth {
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 460px;
    margin: auto;
    // margin-top: 3.5rem;
    text-align: left;
}


// .otp > input[type] {
//     // padding: 1.5rem;
//     text-align: center;
//     // background: red;
// }


.otp-input {
    width: 40px;
    height: 40px;
    padding: 5px !important;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

// .title {
//     font-weight: 700;
// }

// .subtitle {
//     color: rgba(125, 125, 125, 0.7)
// }

// button {
//     padding: 12px 35px;
//     border-radius: 5px;
// }
</style>