<template>
    <div class="">
        
        <section class="hero is-primary" >
            <div class="hero-body " :style="(getUser.invoiceGradientEnabled) ? `background-image: linear-gradient(160deg,   ${invoice.color ? invoice.color : '#092f9f'} 25%, ${invoice.gradientColor ? invoice.gradientColor : '#06067d'} 100%);` : `background: ${invoice.color ? invoice.color : '#092f9f'};background-color:${invoice.color ? invoice.color : '#092f9f'} !important`">
                <div class="columns is-mobile">
                    <div class="column is-7">
                        <p class="title">
                            {{ invoice.type }} 
                            
                            <b-dropdown v-if="themeType" aria-role="list" :mobile-modal="false">
                                <template #trigger>

                                    <b-button class="mt-1" type="is-white" outlined rounded size="is-small"> {{ theme }}</b-button>

                                    <!-- <b-button
                                        label="Click me!"
                                        type="is-primary"
                                        :icon-right="active ? 'menu-up' : 'menu-down'" /> -->
                                </template>


                                <b-dropdown-item v-for="th in themeType" :key="th" @click="setTheme(th)" aria-role="listitem">{{ th[0].toUpperCase()+th.substring(1) }} Theme</b-dropdown-item>
                                <!-- <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item> -->
                            </b-dropdown>
                        </p>
                        <p class="subtitle has-text-white has-text-weight-semibold">
                            {{ getUser.business }} 
                        </p>
                        <p class="small"><a :href="`tel:${getUser.bphone.countryCode+getUser.bphone.tel}`"> {{ getUser.bphone.countryCode+getUser.bphone.tel }} </a></p>
                        <p class="small"> {{ getUser.address }} </p>
                        <div class="columns is-mobile pt-4">
                            <div class="column is-3">
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Date: </span></p>
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Due: </span></p>
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Bal: </span></p>
                            </div>
                            <div class="column">
                                <p class="small has-text-white has-text-weight-semibold">
                                    {{ new Date(invoice.date).toDateString().substr(4) }}
                                </p>
                                <p class="small has-text-white has-text-weight-semibold">
                                    {{ (invoice.due == 'On Receipt' ? invoice.due : invoice.due ? new Date(invoice.dueDate).toDateString().substring(4)+' ('+invoice.due+')' : 'Draft' ) }}
                                </p>
                                <p class="has-text-white small has-text-weight-semibold"> {{ getUser.country.currency+(invoice.total - invoice.paid).toFixed(2) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <p class="has-text-weight-medium title is-5 pt-1">{{ invoice.client }}</p>
                        <p class="has-text-weight-semibold has-text-white subtitle pt-1">{{ invoice.ref }}</p>
                        <p class="small"><a :href="`tel:${client.phone.countryCode+client.phone.tel}`"> {{ client.phone.countryCode+client.phone.tel }} </a></p>
                        <p class="small"> {{ client.address }} </p>
                        <!-- <p class="">John Doe</p> -->
                        <div class="columns is-mobile pt-4">
                            <!-- <div class="column is-3">
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Date </span></p>
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Due </span></p>
                            </div> -->
                            <div class="column">
                                <!-- <p class="small has-text-white has-text-weight-bold">
                                    <a :href="`tel:${client.phone.countryCode+client.phone.tel}`"> {{ client.phone.countryCode+client.phone.tel }} </a>
                                </p> -->
                                <p v-if="client.email" :href="`mailto:${client.email}`" class="has-text-weight-semibold small"><a class="has-text-white">{{ client.email }}</a></p>
                                <!-- <p class="has-text-weight-semibold  small">{{ client.address }}</p> -->
                                <p v-if="invoice.discount" class="has-text-weight-semibold  small">Discount: ({{ invoice.discount }}%)</p>
                                <p class="medium">  
                                    <feather @click="$router.push({ name: 'create_invoice', params: { invoice } })" type="edit" size="20" class="mt-2 ml-3"></feather>
                                    <feather @click="print()" type="printer" size="20" class="mt-2 ml-3"></feather>
                                    
                                    <a
                                        :href="'https://wa.me/'+(client.phone.countryCode+client.phone.tel)+'?text=Hello '+encodeURIComponent(`${client.name}, from ${getUser.business}, \n_AccountOtg App_ \n\n*${invoice.type+' '+invoice.ref}*:\n_Invoice Summary_\n\n*Date*: ${new Date(invoice.date).toDateString().substr(4)}\n*Due*: ${ (invoice.due == 'On Receipt' ? invoice.due : invoice.due ? new Date(invoice.dueDate).toDateString().substring(4)+' ('+invoice.due+')' : 'Draft' )}\n*Total*: ${getUser.country.currency+(invoice.total).toFixed(2)}\n*Bal*: (${getUser.country.currency+(invoice.total - invoice.paid).toFixed(2)})\n\n Kindly find the invoice link below viewing & payment.\nhttps://app.accountotg.com/pay_page?ref=${invoice.ref.substring(1)}&name=${encodeURIComponent(invoice.business)}&key=${generateLinkHash(invoice.ref)}\n\n_from ${getUser.firstname}_ \n Thank you!\n Accountotg.com`)"
                                        type="is-primary" 
                                        class="has-text-weight-medium is-5 title" 
                                        size="is-medium" 
                                        rounded 
                                        expanded> <feather type="message-circle" size="20" class="mt-2 ml-3"></feather> </a>

                                        <a v-if="renderMail && client.email" @click="renderMail"><feather type="mail" size="20" class="mt-2 ml-3"></feather></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="p-5">
            <div class="">
                    <b-table
                        :data="invoice.items ? invoice.items : []"
                        :bordered="isBordered"
                        :striped="isStriped"
                        :narrowed="isNarrowed"
                        :hoverable="isHoverable"
                        :focusable="isFocusable"
                        :mobile-cards="hasMobileCards">

                        <b-table-column field="description" label="ITEM - DESCRIPTION" v-slot="props">
                            
                            <span class="small has-text-weight-semibold">
                                {{ (props.row.item ? ' ('+props.row.item+')' : '' ) }}
                            </span>
                            <span class="small has-text-dark has-text-weight-medium"> {{ props.row.description }} </span>
                        </b-table-column>

                        <b-table-column field="rate" label="RATE" numeric v-slot="props">
                            <span class="small has-text-dark has-text-weight-medium"> {{ getUser.country.currency+props.row.rate }} </span>
                        </b-table-column>

                        <b-table-column field="qty" label="QTY" numeric v-slot="props">
                            <span class="small has-text-dark has-text-weight-medium"> {{ props.row.qty }} </span>
                        </b-table-column>

                        <b-table-column field="total" label="TOTAL" numeric v-slot="props">
                            <span class="small has-text-dark has-text-weight-medium"> {{ getUser.country.currency+props.row.total.toFixed(2) }} </span>
                        </b-table-column>

                        <template #empty>
                            <div class="has-text-centered">No records</div>
                        </template>

                    </b-table>
                </div>
        </section>
        <hr class="my-3">
        <section class="p-5">
            <div class="columns is-mobile">
                <div class="column is-4">
                </div>
                <div class="column is-4">
                     <div class="has-text-weight-bold small has-text-dark">
                        <p class="">Sub Total:</p>
                        <!-- <p class="">Tax ({{ invoice.tax ? invoice.tax : 0 }}%)</p> -->
                        <p class="mb-3">Total: </p>
                        <p class="">Balance:</p>
                    </div>
                </div>
                <div class="column">
                    <div class="has-text-weight-semibold small has-text-right mr-2">
                        <p class="">{{  getUser.country.currency+invoice.total.toFixed(2) }}</p>
                        <!-- <p class="">{{ (0).toFixed(2) }}</p> -->
                        <p class="mb-3">{{ getUser.country.currency+invoice.total.toFixed(2) }}</p>
                        <p class="">({{getUser.country.currency+(invoice.total - invoice.paid).toFixed(2)}})</p>
                    </div>
                 </div>
            </div>
        </section>
        <hr class="my-3">
        <section class="p-5">
            <div class="px-3 columns is-mobile">
                <div class="column is-8">
                    <p class="small has-text-weight-medium">{{ invoice.notes }}</p>
                </div>
            </div>
        </section>
        <div class="p-5">
            <!-- <b-button expanded size="is-medium" class="is- is-justify-content-center is-flex is-align-content-center">
                <p class="medium">  
                    <feather @click="goBack" type="share-2" size="14" class="mt-2"></feather>
                </p>
                
            </b-button> -->
        </div>

        <div class="" :style="(screenWidth <= 560 ? 'width: 560px; margin: auto;position: fixed;bottom:0; height:10px;background-color:'+(invoice.color ?  invoice.color : '#092f9f') : (screenWidth <= 768 ? 'width: 768px; margin: auto;position: fixed;bottom:0; height:10px;background-color:'+(invoice.color ?  invoice.color : '#092f9f')+';' : 'width: 968px; margin: auto;position: fixed;bottom:0; height:10px;background-color:'+(invoice.color ?  invoice.color : '#092f9f')+';' ) )">
        
        <!-- :style="`position: fixed;bottom:0; height:10px;width: 100%;background-color: ${ (invoice.color ?  invoice.color : '#092f9f') }`"> -->

        </div>
        
    </div>
</template>

<script>
    
    import axios from 'axios';
    import { mapGetters } from "vuex"
    import moment  from 'moment';

    export default {
        name: 'mainTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0,
                isGradient: false,
                isEmpty: false,
                isBordered: false,
                isStriped: false,
                isNarrowed: false,
                isHoverable: false,
                isFocusable: false,
                hasMobileCards: false,
            }
        },
        props: {
            invoice: {
                required: true
            },
            client: {
                required: true
            },
            renderMail: {
                
            },
            print: {

            },
            selectTheme: {

            },
            theme: {
                default: 'main'
            },
            themeType: {

            },
            setTheme: {

            },
            getUser: {

            }
            
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            // ...mapGetters('auth', ['getUser']),
            ...mapGetters('invoices', ['generateLinkHash']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            formatDate(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            routeTo(data) {

                //
                this.$router.push({ name: 'shop', 
                                    query: {  shop: data.shop }
                                })
                

            },
            routeToInvoice(data) {

                //
                

            },
        } 
    }
</script>

<style lang="scss" >
.dropdown-trigger {
        width: calc(100vw - 3.5rem);
    }
</style>