<template>
    <div >
        <div class="page-header">
            <!-- <MainHeader /> -->
        </div>
        <div class="page-body-wrapper">
            <div class="page-body">
                <transition enter-active-class="">
                <!-- <transition name="fadeIn" enter-active-class="animated fadeIn"> -->
                <router-view class="view"></router-view>
                </transition>
            </div>
            <!-- <MainFooter /> -->
        </div>
    </div>
</template>

<script>
// import MainHeader from './header'

export default {
    name: "MainBody",
    components: { 
        // MainHeader,
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    // .page-body {
        // padding: 0 17.5px 0 17.5px;
        // padding-top:50px;
        // padding-bottom: 50px;
        // margin-bottom: 80px;
    // }
</style>