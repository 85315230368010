import Vue from 'vue'
import Router from "vue-router";
import { authHeader } from '../helpers/auth-header'
import Userauth from '../auth/index'
import { userService } from '../services';

import MainBody from '../components/body'
import MainAuth from '../components/auth'

import DashboardPage from '../pages/dashboard'
import AlertsPage from '../pages/alerts'
import IndexPage from '../pages/index'
// import WalletPage from '../pages/wallet'
import NotificationPage from '../pages/notification'
import AddItemPage from '../pages/add_item'
import ItemPage from '../pages/items'
import BrandPage from '../pages/brands'
import CategoryPage from '../pages/categories'
import PaymentPage from '../pages/payments'
import ProfilePage from '../pages/profile'
import invoicePage from '../pages/invoice'
import CreateInvoicePage from '../pages/create_invoice'
import ViewInvoicePage from '../pages/view_invoice'
import DuePage from '../pages/due'
import ReceivablesPage from '../pages/receivables'
import AddPay from '../pages/add_pay'
import ViewPay from '../pages/view_pay'
import PayPay from '../pages/pay_page'
import PayReceipt from '../pages/pay_receipt'
import AddExpense from '../pages/add_expense'
import ExpenseCategory from '../pages/expense_categories'
import AddClient from '../pages/add_client'
import ClientsPage from '../pages/client'
import ExpensePage from '../pages/expense'
import ReferPage from '../pages/refer'
import UserPage from '../pages/user'
import AccountPage from '../pages/account'


import SigninPage from '../pages/signin'
import SignupPage from '../pages/signup'
import SetupPage from '../pages/setup'
import VerifyotpPage from '../pages/verifyotp'
// import TrackerPage from '../pages/tracker'

// import { store } from '../store'

//
Vue.use(Router)

const routes = [
    { path: '', redirect: { name: 'index' }},
    { path: '/', redirect: { name: 'index' }},
    {
        path: '/',
        component: MainBody,
        children:[
            {
                path: 'home',
                name: 'home',
                component: DashboardPage,
                meta: {
                    title: 'Home | Dashboard',
                }
              },
              {
                path: 'index',
                name: 'index',
                component: IndexPage,
                meta: {
                    title: 'Home | Welcome',
                }
              },
              {
                  path: 'payment',
                  name: 'payment',
                  component: PaymentPage,
                  meta: {
                      title: 'Payments',
                  }
              },
              {
                path: 'item',
                name: 'item',
                component: ItemPage,
                meta: {
                    title: 'Items',
                }
              },
              {
                path: 'brand',
                name: 'brand',
                component: BrandPage,
                meta: {
                    title: 'Brands',
                }
              },
              {
                path: 'category',
                name: 'category',
                component: CategoryPage,
                meta: {
                    title: 'Categories',
                }
              },
              {
                path: 'add_item',
                name: 'add_item',
                component: AddItemPage,
                meta: {
                    title: 'Add Items',
                }
              },
              {
                path: 'invoice',
                name: 'invoice',
                component: invoicePage,
                meta: {
                    title: 'Invoices',
                }
              },
              {
                path: 'create_invoice',
                name: 'create_invoice',
                component: CreateInvoicePage,
                meta: {
                    title: 'Create Invoice',
                }
              },
              {
                path: 'view_invoice',
                name: 'view_invoice',
                component: ViewInvoicePage,
                meta: {
                    title: 'View Invoice',
                }
              },
              {
                path: 'due',
                name: 'due',
                component: DuePage,
                meta: {
                    title: 'Due Invoice',
                }
              },
              {
                path: 'receivables',
                name: 'receivables',
                component: ReceivablesPage,
                meta: {
                    title: 'Receivables',
                }
              },
              {
                path: 'add_pay',
                name: 'add_pay',
                component: AddPay,
                meta: {
                    title: 'Add Payments',
                }
              },
              {
                path: 'pay_page',
                name: 'pay_page',
                component: PayPay,
                meta: {
                    title: 'Pay Page',
                }
              },
              {
                path: 'pay_receipt',
                name: 'pay_receipt',
                component: PayReceipt,
                meta: {
                    title: 'Pay Receipt',
                }
              },
              {
                path: 'view_pay',
                name: 'view_pay',
                component: ViewPay,
                meta: {
                    title: 'View Payment',
                }
              },
              {
                path: 'client',
                name: 'client',
                component: ClientsPage,
                meta: {
                    title: 'Clients',
                }
              },
              {
                path: 'add_client',
                name: 'add_client',
                component: AddClient,
                meta: {
                    title: 'Add Clients',
                }
              },
              {
                path: 'expense',
                name: 'expense',
                component: ExpensePage,
                meta: {
                    title: 'Expenses',
                }
              },
              {
                path: 'add_expense',
                name: 'add_expense',
                component: AddExpense,
                meta: {
                    title: 'Add Expense',
                }
              },
              {
                path: 'expense_category',
                name: 'expense_category',
                component: ExpenseCategory,
                meta: {
                    title: 'Expense Category',
                }
              },
             {
                path: 'alert',
                name: 'alert',
                component: AlertsPage,
                meta: {
                    title: 'Alerts',
                }
              },
              {
                path: 'profile',
                name: 'profile',
                component: ProfilePage,
                meta: {
                    title: 'Profile',
                }
              },
              {
                path: 'notification',
                name: 'notification',
                component: NotificationPage,
                meta: {
                    title: 'Notification',
                }
            },
            {
                path: 'user',
                name: 'user',
                component: UserPage,
                meta: {
                    title: 'User ',
                }
            },
            {
                path: 'account',
                name: 'account',
                component: AccountPage,
                meta: {
                    title: 'Account ',
                }
            },
            {
                path: 'refer',
                name: 'refer',
                component: ReferPage,
                meta: {
                    title: 'Refer ',
                }
            }
            
        ]
    },
    {
        path: '/',
        component: MainAuth,
        children: [
            {
                path: 'signin',
                name: 'signin',
                component: SigninPage,
                meta: {
                    title: 'Signin Here',
                }
            },
            {
                path: 'signup',
                name: 'signup',
                component: SignupPage,
                meta: {
                    title: 'Signup Here',
                }
            },
            {
                path: 'setup',
                name: 'setup',
                component: SetupPage,
                meta: {
                    title: 'Setup Here',
                }
            },
            {
                path: 'verifyotp',
                name: 'verifyotp',
                component: VerifyotpPage,
                meta: {
                    title: 'verify OTP Here',
                }
            },
        ]
    }
]



 //
//  axios.get('/getAll').then(res=>{
//     console.log(res.data)
//  })

const router = new Router({
    base: '/',
    mode: 'history',
    routes,
    linkActiveClass: "active",
    scrollBehavior () {
      return { x: 0, y: 0 }
    }
})


// const axios = require('axios')

// token
// let token = authHeader()['Authorization']

// console.log(token)

// authenticate 
router.beforeEach((to, from, next) => {
   
    // default routes without token
    if (['/signin', '/signup',  '/verifyotp', '/setup', '/pay_page', '/pay_receipt'].includes(to.path)){
        return next();
    } else {
        // user
        const User = localStorage.getItem('user')
        // check for token and token expiry
        if (['/signin','/setup', 'verifyotp'].includes(to.path) || (User && Userauth.isAuthenticated()) ){
            return next();
        } else {
            // token has expired

            // user
            const User = JSON.parse(localStorage.getItem('user'))
            // validate
            if (!User) {
                // grab query and pass to next route
                return next({
                    path: '/signin',
                    query:  to.query
                })
            }
            // initiate a refresh request
            userService.refresh(User.refresh_token).then(res=>{
                //
                if (['/signin','/setup', 'verifyotp'].includes(to.path) || (User && Userauth.isAuthenticated())) {
                    return next();
                }

            }).catch(error=>{
                //
                Userauth.logout()
                //
                return next({
                    path: '/signin',
                    query:  to.query
                })
             })

        }

        if(to.meta.title)
            document.title = to.meta.title;

        // return next('/login')
    }

  
      
})

export default router