<template>
    <div>
        <div class="p-4" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            <div class="columns is-mobile">
                    <div class="column is-half">
                        <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather>
                        <p class="px-2 py-2 pb-0 has-text-weight-bold ml-5"> {{ ref ? 'Edit: ' : 'Add  Item' }} <span v-if="ref" class="has-text-primary">{{ ref }}</span> </p>
                    </div>
                    <div class="column py-4">
                        <b-button type="is-primary" class="is-pulled-right mr-3" icon-left="" @click="$router.push('category')" rounded size="is-small"> category </b-button>
                        <b-button type="is-primary" class="is-pulled-right mr-3" icon-left="" @click="$router.push('brand')" rounded size="is-small"> brand </b-button>
                    </div>
            </div>
            <div class="mb-5">
                <div class="pb-2">
                    <div class="columns is-mobile">
                            <div class="column ">
                                <b-field :type="it.type" :message="it.message">
                                    <b-input @input="validateItem()" v-model="form.item" placeholder="Item name"></b-input>
                                </b-field>
                            </div>
                    </div>
                        <div class="columns is-mobile">
                            <div class="column ">
                                <b-field :type="br.type" :message="br.message">
                                        <v-select v-model="brand" :options="brands" label="label"></v-select>
                                    </b-field>
                            </div>
                            <div class="column ">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                <!-- <b-field class="report"> -->
                                    <!-- <b-switch v-model="graphIsSwitched" size="is-small">Area Graph</b-switch> -->
                                    <!-- <b-select expanded size="is-small" class="is--right" placeholder="Choose client" v-model="client">
                                        <option
                                            v-for="option in options"
                                            :value="option.value"
                                            :key="option.text">
                                            {{ option.text }}
                                        </option>
                                    </b-select> -->
                                    <!-- :reduce="invoice => invoice.code" -->
                                    <b-field :type="ct.type" :message="ct.message">
                                        <v-select v-model="category" :options="categories" label="label"></v-select>
                                    </b-field>
                                <!-- </b-field>  -->
                            </div>
                        </div>

                        <div class="columns is-mobile mb-0">
                            <div class="column is-half">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Item Details </p>
                            </div>
                            <div class="column py-4">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                
                            </div>
                        </div>
                        <div>
                            <div class="columns is-mobile mb-0">
                                <div class="column">
                                    <b-field :type="cs.type" :message="cs.message">
                                        <b-input @input="validateCost()" type="number" step="0.01" v-model="form.cost" placeholder="Cost"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :type="pr.type" :message="pr.message">
                                        <b-input @input="validatePrice()" type="number" step="0.01" v-model="form.price" placeholder="Price"></b-input>
                                    </b-field>
                                </div>
                                <!-- <div class="column">
                                    <b-field >
                                        <b-input autocomplete="0" type="number" v-model="form.discount" placeholder="Amount"></b-input>
                                    </b-field>
                                </div> -->
                            </div>
                            <div class="columns is-mobile mb-0">
                                <div class="column">
                                    <b-field >
                                        <b-select expanded class="is--right" placeholder="Payment Type" v-model="form.type">
                                            <option
                                                v-for="option in ItemType"
                                                :value="option"
                                                :key="option">
                                                {{ option }} item
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :type="ds.type" :message="ds.message">
                                        <b-input type="number" step="0.01" v-model="form.discount"  placeholder="Discount" ></b-input>
                                    </b-field>
                                </div>
                            </div>
                        <div class="columns is-mobile mb-0">
                            <div class="column is-half">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Item Stock Details </p>
                            </div>
                            <div class="column py-4">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                
                            </div>
                        </div>
                        <div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field >
                                        <b-input :disabled="form.type == 'Service'" type="number" step="0.01" v-model="form.reorder_level" placeholder="Reorder level"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field >
                                        <b-input :disabled="form.type == 'Service'" type="number" step="0.01" v-model="form.openning_stock" placeholder="Openning stock"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                            <div class="columns is-mobile mb-2">
                                <div class="column  is-half">
                                    <b-field >
                                        <b-select :disabled="form.type == 'Service'" expanded class="is--right" placeholder="Unit of measure" v-model="form.unit_of_measure">
                                            <option
                                                v-for="option in UnitOfMeasure"
                                                :value="option"
                                                :key="option">
                                                {{ option }} 
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column  is-half">
                                    <b-field label="">
                                        <b-select :disabled="form.type == 'Service'" expanded class="is--right" placeholder="Barcode Type" v-model="form.barcode_type">
                                            <option
                                                v-for="option in BarType"
                                                :value="option"
                                                :key="option">
                                                {{ option }} 
                                            </option>
                                        </b-select>
                                        
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field>
                                        <b-input :disabled="form.type == 'Service'" v-model="form.barcode" placeholder="Barcode digits"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <b-button type="is-primary"  expanded>Add item</b-button> -->
                        <!-- <div>
                            <b-table :mobile-cards="false" :data="form.items" :columns="columns"></b-table>
                        </div> -->
                        
                            <b-field class="pb-5 mb-3">
                                <b-button @click="onSubmit"  class="is-submit-button" expanded type="is-primary">Submit</b-button>
                            </b-field>
                    </div>

                    <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
            </div>
        </div>
        <MainFooter/>
    </div>
</template>

<script>

    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import MainFooter from '../components/footer'
    import moment  from 'moment';

    export default {
        name: "AddPayPage",
        data(){
            return {
                brand: {
                        label: 'Select Brand',
                        code: null,
                    },
                category: {
                        label: 'Select Category',
                        code: null,
                    },
                ref: null,
                isLoading: false,
                screenWidth: 0,
                clientView: false,
                payDisabled: false,
                form: {
                    item: '',
                    business: '',
                    cost: null,
                    price: null,
                    type: 'Service',
                    discount: null,
                    reorder_level: null,
                    unit_of_measure: null,
                    openning_stock: null,
                    barcode_type: null,
                    barcode: '',
                },
                ItemType: ['Service', 'Stock'],
                UnitOfMeasure: ["Each", "Piece", "Pack", "Box", "Row", "Dozen"],
                BarType: ["Ean", "Code 128"],
                br: {
                    type:'',
                    message:'',
                },
                ct: {
                    type:'',
                    message:'',
                },
                cs: {
                    type:'',
                    message:'',
                },
                pr: {
                    type:'',
                    message:'',
                },
                ds: {
                    type:'',
                    message:'',
                },
                it: {
                    type:'',
                    message:'',
                },
                brands: [
                    {
                        label: 'Select Brand',
                        code: null,
                    }
                ],
                categories: [
                    {
                        label: 'Select Category',
                        code: null,
                    }
                ],
            }
        },
        components: {
            MainFooter,
        },
        computed: {
            //
            ...mapGetters('items', ['getBrands']),
            ...mapGetters('items', ['getCategories']),
            ...mapGetters('auth', ['getUser'])
        },
        watch: {
            getBrands() {
                if (this.getBrands) {

                    let options = []
                    //  && i.status !== "paid"
                    this.getBrands.forEach(v=>{
                        options.push({ label: v.brand, code: v.brand })
                    })
                    //
                    this.brands = this.brands.concat(options)
                } 
            },
            brand() {
                if (this.brand) {

                    if (this.brand.code !== null) {
                        this.form.brand = this.brand.code

                    } else {
                        this.form.brand = null
                    }
                    
                } 
            },
            getCategories() {
                if (this.getCategories) {

                    let options = []
                    //  && i.status !== "paid"
                    this.getCategories.forEach(v=>{
                        options.push({ label: v.category, code: v.category })
                    })
                    //
                    this.categories = this.categories.concat(options)
                } 
            },
            category() {
                if (this.category) {

                    if (this.category.code !== null) {
                        this.form.category = this.category.code

                    } else {
                        this.form.category = null
                    }
                    
                } 
            },
        },
        mounted() {
            
            this.updateScreenWidth();
            this.onScreenResize();
            this.fetchData()

            if (Object.keys(this.$route.params).length !== 0) {

                if (this.$route.params.item) {

                const data = {
                        link: 'add_item',
                        data: this.$route.params
                    }

                //
                // this.$route.params.invoice.items.forEach(v=>{
                //     this.form.items.push(v)
                // })

                // this.invoice = this.$route.params.pay.invoice
                this.ref = this.$route.params.item.item
                this.form.item = this.$route.params.item.item
                this.brand = this.$route.params.item.brand ? this.$route.params.item.brand : {
                        label: 'Select Brand',
                        code: null,
                    }
                this.category = this.$route.params.item.category ? this.$route.params.item.category : {
                        label: 'Select Category',
                        code: null,
                    }
                this.form.business = this.$route.params.item.business
                this.form.type = this.$route.params.item.type
                this.form.cost = this.$route.params.item.cost
                this.form.price = this.$route.params.item.price
                this.form.discount = this.$route.params.item.discount
                if (this.$route.params.item.type == "Stock") {
                    this.form.reorder_level = this.$route.params.invoice.reorder_level
                    this.invoice.openning_stock = this.$route.params.openning_stock
                    this.form.unit_of_measure = this.$route.params.item.unit_of_measure
                    this.form.barcode_type = this.$route.params.item.barcode_type
                    this.form.barcode = this.$route.params.item.barcode
                }
                // this.form.color = this.$route.params.invoice.color
                // this.invoice.user = this.$route.params.user
                // this.invoice.status = this.$route.params.status

                // this.$store.dispatch('pages/setPage', data)
                this.clientDisabled = true

                this.isLoading = false

                // this.checkShop()
                // this.checkVisit()
                }
                
            }
            // if (Object.keys(this.$route.params).length !== 0) {

            //     console.log(this.$route.params.ref)
            //     console.log(this.options)

            //     setTimeout(() => {
            //         this.invoice = this.options.find(v=>v.code == this.$route.params.ref) 
            //     }, 500);

            // }
                
        },
        methods: {
            
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            fetchData() {
                this.$store.dispatch('items/getBrands', this.getUser.business)
                this.$store.dispatch('items/getCategories', this.getUser.business)
            },
            validateItem() {
                if (this.form.item.length < 3){
                    this.it.type = 'is-danger';
                    this.it.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.item.length >= 3){
                    this.it.type = 'is-success';
                    this.it.message =''
                    return true
                }
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)

                if (reg.test(this.form.phone)) {

                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.phone.length > 8 && this.form.phone.length < 11){
                        this.cn.type = 'is-success';
                        this.cn.message =''
                        return true
                    } else {
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a contact number'
                        return false
                    }
                }


            },
            validateDiscount() {
                if (this.form.discount == null) {
                    this.ds.type = 'is-danger';
                    this.ds.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.discount <= 0) {
                    this.ds.type = 'is-danger';
                    this.ds.message ='Enter a valid number'
                    return false
                    
                }

                 if (this.form.discount > 0){
                    this.ds.type = 'is-success';
                    this.ds.message =''
                    return true
                }
            },
            validateCost() {
                if (this.form.cost == null) {
                    this.cs.type = 'is-danger';
                    this.cs.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.cost <= 0) {
                    this.cs.type = 'is-danger';
                    this.cs.message ='Enter a valid number'
                    return false
                    
                }

                 if (this.form.cost > 0){
                    this.cs.type = 'is-success';
                    this.cs.message =''
                    return true
                }
            },
            validatePrice() {
                if (this.form.price == null) {
                    this.am.type = 'is-danger';
                    this.pr.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.price <= 0) {
                    this.pr.type = 'is-danger';
                    this.pr.message ='Enter a valid number'
                    return false
                    
                }

                 if (this.form.price > 0){
                    this.pr.type = 'is-success';
                    this.pr.message =''
                    return true
                }
            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                if ( !this.validateItem() || !this.validateCost() || !this.validatePrice() ){
                // if ( (!this.form.client || (!this.validateName() && !this.validateContact())) || !this.form.items.reduce((a, b) => a + b.total, 0) > 0 ){
                    return
                }

                this.isLoading = true

                console.log(this.form)

                //
                this.form.business = this.getUser.business
                // this.form.total = this.genDis( (this.form.discount ? this.form.discount : 0),  this.form.items.reduce((a, b) => a + b.total, 0) )
                
                let method,url
                //
                if (this.ref) {
                    url = '/item/'+this.ref
                    method = axios.patch
                } else {
                    url = '/item'
                    method = axios.post
                }
                
                // ajax
                method(url, this.form).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)
                    const ref = response.data.split(" ")[2]
                    //
                    this.$router.push({ name: 'item', query: { ref: ref } })

                    this.isLoading = false

                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            },
            onSelect({name, iso2, dialCode}) {
                this.form.code = "+"+dialCode
                // console.log(name, iso2, dialCode);
                // this.form.country = name.split(" ")[0]
                // this.form.currency = iso2
            },
            calcTot(item) {
                item.total = Math.round( item.qty * item.rate * 1000)/1000
            },
            addItem() {

                let item = {}
                item.category = '' 
                item.description = '' 
                item.qty = null
                item.rate = null
                item.total = null

                this.form.items.push(item)
            },
            removeItem(index){

                if (this.form.items.length > 1) {
                    this.form.items.splice(index,1)
                }
            },
            genDis(discount, amount) {
                return Math.round( ((100 - discount)/100 * amount) * 1000 )/1000
            },
            Discount(discount, amount) {
                return Math.round( ((discount)/100 * amount) * 1000 )/1000
            },
            
            goBack() {
                this.$router.back()
            },
            goHome() {
                //
                this.$router.push('/')
            },
        }
    }
</script>

<style lang="scss" >
// @import "vue-select/dist/vue-select.css";

textarea:not([rows]) {
    max-height: 40em;
    min-height: 5em !important;
}

.vs__selected-options input[class] {
    padding: 0rem !important;
    font-size: 2rem !important;
}

.vs__selected {
    padding: 0rem !important;
    font-size: 0.9rem !important;
}

.vs__deselect {
    margin: 3px;
    margin-left: 5px;
    font-size: 0.9rem !important;
}
</style>