<template>
    <div class="">
        <div class="columns is-multiline">
            <div class=" column is-half-tablet " v-for="invoice in invoices" :key="invoice._id">
                
                        <!-- <b-button
                            label="Hover me!"
                            type="is-info"
                            icon-right="menu-down" /> -->
                    
                <a class="card columns is-mobile mb-0 p-1" >
                    <!-- <div class="column is-one-fifth">
                        <figure class="image is-64x64 pt-1">
                            <img src="https://bulma.io/images/placeholders/128x128.png" alt="Image">
                        </figure>
                    </div>
                    <div class="column is-half">
                        <div class="p-2 ">
                            <h4 class=" has-text-dark has-text-weight-medium  mb-1">{{ invoice.client }}</h4>
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-1">{{ getUser.country.currency+invoice.total }} 
                            </p>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <div class="pt-2">
                            <b-tag :type="`${invoice.status == 'paid' ? 'is-primary' : 'is-danger'}  mb-2 mt-1`">{{ invoice.status }}</b-tag>
                            <p class="has-text-dark small has-text-weight-medium"> {{ formatAMPM(invoice.date) }} </p>
                        </div>
                    </div> -->

                    <div @click="routeToInvoice(invoice)" class="column is-two-thirds">
                        <div class="p-1 ">
                            <h4 class=" has-text-dark has-text-weight-medium  mb-1">{{ invoice.client }}</h4>
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-1">{{ getUser.country.currency+invoice.total.toFixed(2) }}
                            <!-- 
                            <span class="has-text-success small"> 750.00 </span><br> -->
                            <span class="small">&#8212;</span> 
                            <span v-if="invoice.status == 'paid'" class="ml-1 has-text-primary is-size-7 has-text-weight-bold mb-2"> {{ invoice.ref }} </span>
                            <span v-else-if="invoice.status == 'part payment'" class="has-text-primary small"> {{  (invoice.paid).toFixed(2) }} - <span class="has-text-danger">({{ (invoice.total - invoice.paid).toFixed(2) }})</span> </span>
                            <span v-else-if="invoice.status == 'pending' && invoice.type == 'Invoice'" class="has-text-danger small"> ({{ invoice.total.toFixed(2) }}) </span>
                            <span v-else class="ml-1 has-text-primary is-size-7 has-text-weight-bold mb-2"> {{ invoice.ref }} </span>
                            
                            </p>
                            
                            <b-tag class="has-text-weight-semibold mr-2"> {{ enableDueAge ? '' : invoice.due ? invoice.due+(invoice.due == 'On Receipt' ? '' : ' -') : invoice.type == "Invoice" ? "Due" : 'Draft' }} <span v-if="invoice.due !== 'On Receipt' && invoice.due !== null">  {{ new Date(invoice.dueDate).toDateString().substr(4) }}</span> </b-tag>
                            <span v-if="enableDueAge" class="small has-text-weight-bold">&#8212;</span> 
                            <span v-if="enableDueAge" class="has-text-danger small has-text-weight-bold"> ({{ genDueDate(invoice.dueDate)+' days' }}) </span>
                            <span v-if="invoice.expense" class="has-text-dark small has-text-weight-semibold">Expense - <span class="has-text-danger">{{ getUser.country.currency+(invoice.expense).toFixed(2) }}</span>  </span>
                        </div>
                    </div>
                    
                    <div class="column has-text-right">
                        <b-dropdown position="is-bottom-left" :triggers="['click']" :append-to-body="true" :mobile-modal="false" aria-role="list">
                    <template #trigger>
                        <div class="pt-1 ">
                            <b-tag :type="`${invoice.type == 'Invoice' ? (invoice.status == 'paid' ? 'is-primary' : invoice.status == 'part payment' ? 'is-warning' : 'is-danger') :  'is-info'}  mb-2 mt-1`">{{ invoice.type == 'Invoice' ? invoice.status : 'Untracked' }}</b-tag>
                            <br>
                            <span class="small has-text-dark mb-1 ">{{ invoice.type }}</span>
                            <br>
                            <span class="has-text-dark small has-text-weight-medium"> {{ new Date(invoice.date).toDateString().substr(4) }} </span>
                        </div>
                    </template>
            <b-dropdown-item @click="makepay(invoice)" aria-role="listitem">Pay</b-dropdown-item>
            <b-dropdown-item @click="view(invoice)" aria-role="listitem">View</b-dropdown-item>
            <b-dropdown-item @click="edit(invoice)" aria-role="listitem">Edit</b-dropdown-item>
            <b-dropdown-item @click="client(invoice)" aria-role="listitem">Client</b-dropdown-item>
            <b-dropdown-item @click="pay(invoice)" aria-role="listitem">Payment</b-dropdown-item>
            <b-dropdown-item @click="exp(invoice)" aria-role="listitem">Expense</b-dropdown-item>
            <b-dropdown-item @click="del(invoice)" aria-role="listitem">Delete</b-dropdown-item>
            <!-- <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item> -->
            <!-- <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item> -->
            </b-dropdown>
                    </div>
                    
                </a>
            
                <!-- <hr class="my-1 mb-4"> -->
            </div>
        </div>
        <div class="p-4"></div>
    </div>
</template>

<script>
    
    import { mapGetters } from "vuex"
    import moment  from 'moment';

    export default {
        name: 'InvoiceTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            invoices: {
                required: true
            },
            enableDueAge: {
                type: Boolean,
                default: false
            },
            del: {}
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            formatDate(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            routeTo(data) {

                //
                this.$router.push({ name: 'shop', 
                                    query: {  shop: data.shop }
                                })
                

            },
            genDueDate(dueDate) {
                //
                return Math.abs( moment(new Date(dueDate)).diff(moment(new Date), 'days') )
            },
            makepay(data) {
                if (data) {
                    this.$router.push({ name: 'pay_page', 
                                    params: { 
                                        id: data._id,
                                        ref: data.ref,
                                        client: data.client,
                                        business: data.business,
                                        type: data.type,
                                        items: data.items,
                                        total: data.total,
                                        paid: data.paid,
                                        discount: data.discount,
                                        date: data.date,
                                        due: data.due,
                                        dueDate: data.dueDate,
                                        notes: data.notes,
                                        color: data.color,
                                        gradientColor: data.gradientColor,
                                        theme: data.theme,
                                        user: data.user,
                                        status: data.status,
                                    } 
                                })
                }
            },
            view(data) {
                if (data) {
                    this.$router.push({ name: 'view_invoice', 
                                    params: { 
                                        id: data._id,
                                        ref: data.ref,
                                        client: data.client,
                                        business: data.business,
                                        type: data.type,
                                        items: data.items,
                                        total: data.total,
                                        paid: data.paid,
                                        discount: data.discount,
                                        date: data.date,
                                        due: data.due,
                                        dueDate: data.dueDate,
                                        notes: data.notes,
                                        color: data.color,
                                        gradientColor: data.gradientColor,
                                        theme: data.theme,
                                        user: data.user,
                                        status: data.status,
                                    } 
                                })
                }
            },
            edit(data) {
                this.$router.push({ name: 'create_invoice', 
                                    params: { invoice: data }
                                })
            },
            client(data) {
                this.$router.push({ name: 'client', 
                                    params: { search: data.client }
                                })
            },
            pay(data) {
                if (['paid','part payment'].includes(data.status)) {
                    
                    this.$router.push({ name: 'payment', 
                                    params: { search: data.ref, invoiceStatus: data.status }
                                })
                } else {
                    
                    this.$router.push({ name: 'add_pay', 
                                    params: { ref: data.ref }
                                })
                }
                
            },
            exp(data) {

                if (data.expense && data.expense > 0) {
                    
                    this.$router.push({ name: 'expense', 
                                    params: { search: data.ref }
                                })
                } else {
                    
                    this.$router.push({ name: 'add_expense', 
                                    params: { ref: data.ref }
                                })
                }
            },
            // routeTo(data) {

            //     //
                
            //         //
            //         this.$router.push({ name: 'shop', 
            //                         params: { 
            //                             id: data.id,
            //                             name: data.name,
            //                             cover: data.cover,
            //                             phone: data.phone,
            //                             country: data.country,
            //                             whatsapp: data.whatsapp,
            //                             logo: data.logo,
            //                             locationName: data.locationName,
            //                             location: data.location,
            //                             rate: data.rate,
            //                             type: data.type,
            //                             website: data.website,
            //                             categories: data.categories,
            //                             available: data.available,
            //                             active: data.active,
            //                         } 
            //                     })
                

            // },
            routeToInvoice(data) {

                //
                
                    //
                    this.$router.push({ name: 'view_invoice', 
                                    params: { 
                                        id: data._id,
                                        ref: data.ref,
                                        client: data.client,
                                        business: data.business,
                                        type: data.type,
                                        items: data.items,
                                        total: data.total,
                                        paid: data.paid,
                                        discount: data.discount,
                                        date: data.date,
                                        due: data.due,
                                        dueDate: data.dueDate,
                                        notes: data.notes,
                                        color: data.color,
                                        gradientColor: data.gradientColor,
                                        theme: data.theme,
                                        user: data.user,
                                        status: data.status,
                                    } 
                                })
                

            },
            markAsRead(id) {
                // console.log(this.$store)
                this.$buefy.toast.open("Marked as read!");
                
                return this.$store.getters['cart/alreadyInCart'](id)
            },
            run(data){
                console.log(data)
            }
        } 
    }
</script>

<style lang="scss" >
    // .dropdown-trigger {
    //     width: calc(100vw - 3.5rem);
    // }

    .card {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
</style>