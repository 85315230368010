<template>
    <div >
        <div  class="px-3 py-3 footer text-center">
            <!-- <div v-if="cartCount !== 0" class=" -cover px-1 " @click="goTo()">
                <b-button type="is-primary" class="p-3 has-text-weight-medium is-5 title" size="is-medium" rounded expanded>
                    <h6 class=" pt-0 m-0 medium" style=""> {{ getCartBusiness }} </h6> 
                        Order {{ '('+cartCount+')' }} - GH₵{{ formatPrice(totalPrice)}}
                    
                </b-button>
            </div>
            <div v-if="getNewOrder && cartCount == 0" class=" -cover px-1 pb-2" @click="routeTo('tracker')">
                <b-button type="is-primary" class="p-3 has-text-weight-medium is-5 title" size="is-medium" rounded expanded>
                    <h6 class=" pt-0 m-0 medium" style=""> {{ getNewOrder.business }} </h6>
                        Order {{ getNewOrder.orderNo }}
                </b-button>
            </div>
            <hr v-if="cartCount !== 0" class="my-3"> -->
            <div class="columns is-mobile" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
                <div @click="routeTo('index')"  :class="`column item is-one-fourth ${(checkLink('index') ? 'linkActiveParent' : '')}`" >
                    <div :class="checkLink('index')">
                        <feather class="px- pb-1 pt-1" type="home"></feather>
                        <h6 class="has-text-weight-medium">Home</h6>
                    </div>    
                </div>
                <div @click="routeTo('item')" :class="`column item ${(checkLink('item') ? 'linkActiveParent' : '')}`">
                    <div :class="checkLink('item')">
                        <feather class="px- pb-1 pt-1" type="tag"></feather>
                        <h6 class="has-text-weight-medium">Items</h6>
                    </div>
                </div>
                <div @click="routeTo('invoice')" :class="`column item ${(checkLink('invoice') ? 'linkActiveParent' : '')}`">
                    <div :class="checkLink('invoice')">
                        <feather class="px- pb-1 pt-1" type="edit"></feather>
                        <h6 class="has-text-weight-medium">Invoices </h6>
                    </div>
                </div>
                <div @click="routeTo('payment')" :class="`column item ${(checkLink('payment') ? 'linkActiveParent' : '')}`" class="column item">
                    <div :class="checkLink('payment')">
                        <feather class="px- pb-1 pt-1" type="credit-card"></feather>
                        <h6 class="has-text-weight-medium">Payments</h6>
                    </div>
                </div>
                <div @click="routeTo('expense')" :class="`column item ${(checkLink('expense') ? 'linkActiveParent' : '')}`">
                    <div :class="checkLink('expense')">
                        <feather class="px- pb-1 pt-1" type="file-text"></feather>
                        <h6 class="has-text-weight-medium">Expenses</h6>
                    </div>
                </div>
                <div @click="routeTo('profile')" :class="`column item ${(checkLink('profile') ? 'linkActiveParent' : '')}`">
                    <div :class="checkLink('profile')">
                        <feather class="px- pb-1 pt-1" type="user"></feather>
                        <h6 class="has-text-weight-medium">Profile</h6>
                    </div>
                </div>
            </div>
        </div>
        <!-- <b-button id="fab" class="fab-wrapper" style="right: 5vw; bottom: 4vh; z-index: 999; position: fixed;" icon-left="plus" type="is-dark" rounded size="is-medium"></b-button> -->
        <!-- <fab :bgColor="bgColor"  icon-size="small" :position="position" @cart="cart" @wishlist="wishlist" :actions="fabActions"></fab> -->
    </div>
</template>

<script>
    // import fab from 'vue-fab';

    import { mapGetters, mapActions} from 'vuex'

    export default {
        name: "MainFooter",
        components: {
            // fab
        },
        data() {
            return {
                screenWidth: 0,
                linkActive: '',
                bgColor: '#007bff',
                position: 'bottom-middle',
                // fabActions: [
                //                 {
                //                     name: 'cart',
                //                     icon: 'shopping_cart'
                //                 },
                //                 {
                //                     name: 'wishlist',
                //                     icon: 'list'
                //                 }
                //             ]
            }
        },
        computed: {
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('orders', ['getNewOrder']),
            // ...mapGetters('cart', ['getCartBusiness']),
        },
        mounted(){

            this.updateScreenWidth();
            this.onScreenResize();
        },
        methods: {
            
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatPrice(price) {
                return price.toFixed(2)
            },
            getDiscount(price, disc) {
                return ((100 - disc)/100) * price;
            },
            routeTo(link) {
                if (link == this.$route.path.substr(1)) {
                    return
                }
                this.$router.push('/'+link)
            },
            goTo(){

                const bus = this.$store.getters['vendors/currentBusiness'](this.getCartBusiness)
                bus.currency = bus.country.currency

                this.$router.push({ name:'vendor', params: bus })

            },
            checkLink(menu){
                //get link
                const link = this.$route.path.substring(1)

                if (link == menu){
                    return 'linkActive'
                } else {
                    return ''
                }

            },
            cart() {
                console.log('cart')
            },
            wishlist() {
                console.log('wishlist')
            }
        }
    }
</script>

<style lang="scss" scoped>
// custom
@import '@/assets/scss/custom_variables.scss';

 #fab{
    padding-right: 1.25rem;
    padding-left: 1.25rem;
 }

 .footer {
    // box-shadow: 0px 8px 24px;
    position:fixed;
    width:100%;
    background:white;
    bottom:0
 }

 .text-center {
    text-align: center;
 }

 div.item:hover,  div.item:focus {
    transition: ease-out;
    cursor: pointer;
    background: rgba($color: rgb(230, 230, 230), $alpha: 0.5);
 }

 .linkActive {
    color: $primary !important;
 }

.linkActiveParent {
        
    background: rgba($color: rgb(230, 230, 230), $alpha: 0.5);
}

 h6 {
    font-size: 0.7rem;
    margin-top: -3x;
 }

  .column a { 
    color: hsl(0deg, 0%, 21%);
  }

  .fab-wrapper {
    bottom: 9vh !important;
  }
</style>