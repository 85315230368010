<template>
    <div>
            <!-- <h4 class="title is-4">Profile</h4> -->
            <div class="p-3" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
                <div class="p-4 px-4" >
                    <h3 class="title is-4 mb-1"  v-if="getUser">
                        {{ getUser.business }}
                    </h3>
                    <b-tag class="has-text-dark has-text-weight-semibold small mb-5">{{ getUser.bemail }} </b-tag>
                    <h3 class="title medium has-text-"  v-if="getUser">
                        {{ getUser.firstname+' '+getUser.lastname }}
                    </h3>
                    <h6 v-if="getUser" class="subtitle has-text- has-text-weight-semibold text-primary is-5 mb-2">{{ getUser.phone }}</h6>
                    <b-tag class=" has-text-weight-semibold small mb-2">{{ getUser.email }} </b-tag>
                    <hr>
                    <b-menu>
                    <b-menu-list label="MENU">
                        
                        <b-menu-item label="Alerts" class="has-text-weight-medium" tag="router-link" to="/alert" > 
                        </b-menu-item>
                        <b-menu-item label="Categories" class="has-text-weight-medium" tag="router-link" to="/category" > 
                        </b-menu-item>
                        <b-menu-item label="Brands" class="has-text-weight-medium" tag="router-link" to="/brand" >
                        </b-menu-item> 
                        <b-menu-item label="Items" class="has-text-weight-medium" tag="router-link" to="/item" > 
                        </b-menu-item>
                        <b-menu-item label="Receivables" class="has-text-weight-medium" tag="router-link" to="/receivables" > 
                        </b-menu-item>
                        <b-menu-item label="Due " class="has-text-weight-medium" tag="router-link" to="/due" > 
                        </b-menu-item>
                        <b-menu-item label="Clients " class="has-text-weight-medium" tag="router-link" to="client"></b-menu-item>
                        <!-- <b-menu-item label="Create Invoice" class="has-text-weight-medium" tag="router-link" to="/create_invoice" > 
                        </b-menu-item>
                        <b-menu-item label="Add Payment" class="has-text-weight-medium" tag="router-link" to="/add_pay" > 
                        </b-menu-item> -->
                        <!-- <b-menu-item @click="refer" class="has-text-weight-medium" label="Refer your friend"></b-menu-item> -->
                        <!-- <b-menu-item label="Alerts" class="has-text-weight-medium" tag="router-link" to="alerts" ></b-menu-item> -->
                        <!-- <b-menu-item label="Contact " class="has-text-weight-medium" tag="router-link" to="" ></b-menu-item> -->
                        <!-- <b-menu-item icon="information-outline" label="About " tag="router-link" to="" ></b-menu-item> -->
                        
                    </b-menu-list>
                    <!-- <hr class="m-2"> -->
                    <b-menu-list label="ACCOUNT">
                        <b-menu-item label="Settings" class="has-text-weight-medium" tag="router-link" to="/account" ></b-menu-item>
                        <b-menu-item label="Personal" class="has-text-weight-medium" tag="router-link" to="/user" > 
                        </b-menu-item>
                        <b-menu-item @click.native="logOut" class="has-text-weight-medium" label="Sign out" tag="a" ></b-menu-item>
                    </b-menu-list>

                    <b-menu-list label="">
                        <!-- <span class="px-2 py-4" style="position:absolute;bottom:0">&copy;  myHealthSusu. </span> -->
                    </b-menu-list>
                    </b-menu>
                    <hr>
                </div>
        </div>
        <MainFooter/>
    </div>
</template>

<script>

    import { mapGetters } from "vuex"
    import { userService } from '../services';
    import MainFooter from '../components/footer'

    export default {
        name: "ProfilePage",
        data() {
            return {
                screenWidth: 0,
                user: ''
            }
        },
        components: {
            
            MainFooter
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
        },
        methods: {
            logOut() {
                this.open = false;
                //
                userService.logout()
                //
                this.$router.push('/signin')
            },
            refer() {
                this.$buefy.dialog.confirm({
                    message: '<b>Refer your Friend</b><br><br>Receive <b>20 Points</b> for every referal. Your referred friends will enjoy <b>10 Points</b> on signup. <b>Redeem Gifts, Discounts & Special Offers</b> with points!',
                    confirmText: 'Copy link',
                    cancelText: 'Cancel',
                    onConfirm: () => {
                        this.$buefy.toast.open('User agreed')

                        this.$copyText(`https://app.shopnscan.app/refer?link=${(this.$store.getters['shops/generateShopHash'](this.$store.getters['auth/getUser'].name)).substring(0,10)}`).then( (e)=>{
                            this.$buefy.toast.open(`Refer link copied!`)
                        }, (e)=> {
                            this.$buefy.toast.open(`Failed to copy Refer link!`)
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>