<template>
    <div :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">

        <mainTemp v-if="checkActiveTheme('main')" :theme="theme" :setTheme="setTheme" :print="print" :invoice="invoice" :client="client" :getUser="getUser"></mainTemp>
        <classicTemp v-if="checkActiveTheme('classic')" :theme="theme" :setTheme="setTheme" :print="print" :invoice="invoice" :client="client" :getUser="getUser"></classicTemp>
        <miniTemp v-if="checkActiveTheme('mini')" :theme="theme" :setTheme="setTheme" :print="print" :invoice="invoice" :client="client" :getUser="getUser"></miniTemp>

        <div v-if="false" class="p-5">
            <b-button v-if="(invoice.total - invoice.paid) > 0 ? true : false" @click="isPaymentModalActive = true" type="is-primary" expanded size="is-medium" class="is- is-justify-content-center is-flex is-align-content-center">
                <p class="medium has-text-weight-medium">  
                    Pay Now - {{ (invoice.total).toFixed(2) }}
                </p>
                
            </b-button>
        </div>

        

        <div  class="p-5">
            <!-- <b-button expanded size="is-medium" class="is- is-justify-content-center is-flex is-align-content-center">
                <p class="medium">  
                    Pay Now
                </p>
                
            </b-button> -->
            <PayStackVue 
                            v-if="(invoice.total - invoice.paid) > 0 ? true : false"
                            style="display:"
                            :amount="Math.round( (invoice.total).toFixed(2) * 100)"
                            :email="client.email ? client.email : getUser.bemail"
                            :channels="channel"
                            :currencyCode="code"
                            :metadata="{ orderNo: invoice.ref }"
                            :showAmountInButton="true"
                            :reference="reference"
                            :callback="callback"
                            :close="close"
                            ref="paymentBtn"
                        ></PayStackVue>
        </div>

        <div class="" :style="(screenWidth <= 560 ? 'width: 560px; margin: auto;position: fixed;bottom:0; height:10px;background-color:'+(invoice.color ?  invoice.color : '#092f9f') : (screenWidth <= 768 ? 'width: 768px; margin: auto;position: fixed;bottom:0; height:10px;background-color:'+(invoice.color ?  invoice.color : '#092f9f')+';' : 'width: 968px; margin: auto;position: fixed;bottom:0; height:10px;background-color:'+(invoice.color ?  invoice.color : '#092f9f')+';' ) )">
        
        <!-- :style="`position: fixed;bottom:0; height:10px;width: 100%;background-color: ${ (invoice.color ?  invoice.color : '#092f9f') }`"> -->

        </div>
        
            <div v-if="false" class="p-5" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
                
                <div class="columns is-mobile pb-5">
                        <div class="column is-one-third">
                            <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather>
                            <p class="px-2 py-2 ml-5 pb-0 has-text-weight-bold">{{ invoice.type }} </p>
                        </div>
                        <div  class="column py-4">
                            <b-button class="is-pulled-right" icon-left="" @click="print()" rounded size="is-small"> Whatsapp </b-button>
                            <b-button  class="is-pulled-right" icon-left="" @click="print()" rounded size="is-small"> Email /Sms </b-button>
                            <b-button type="is-primary" class="is-pulled-right" icon-left="" @click="$router.push({ name: 'create_invoice', params: { invoice } })" rounded size="is-small"> Edit </b-button>
                        </div>
                </div>

            <div>
            

        </div>
        </div>

        <b-modal v-model="isPaymentModalActive"  scroll="keep">
            <div class="card">
                <div class="card-content">

                    <div class="p-3">
                        <h4 class="title is-5">Make Payment</h4>
                        <p class="subtitle">Fill form below</p>
                    </div>
                    <div v-if="false" class="p-3">
                        <div class="columns is-mobile ">
                            <div class="column">
                                <b-field :type="bn.type" :message="bn.message">
                                        <b-input disabled :value="invoice.total.toFixed(2)" type="number" step="0.01" placeholder="Total"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field>
                                        <b-input disabled step="0.01" :value="(invoice.total - invoice.paid).toFixed(2)" placeholder="Balance"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-field :type="am.type" :message="am.message">
                                        <b-input autocomplete="0" type="number" @input="validateAmount()" step="0.01" v-model="form.amount" placeholder="Pay Amount"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile mb-5">
                                <div class="column is-one-quarter-widesreen is-one-quarter-desktop is-one-quarter-tablet is-one-third-mobile">
                                    <b-field label="" message="">

                                        <vue-country-code
                                            class=" is-size-5"
                                            @onSelect="onSelect"
                                            :enabledCountryCode="true"
                                            :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                            >
                                        </vue-country-code>
                                        
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="cn.type" :message="cn.message">
                                        <b-input type="number"  @input.native="validateContact()" v-model="form.phone" placeholder="XXXXXXXXX"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <b-field class="pb-5 mb-3">
                                <b-button @click="onSubmit"  class="is-submit-button" expanded type="is-primary">Pay Now</b-button>
                            </b-field>
                    </div>
                    <div v-if="insView" class="content">
                        <div>
                            <!-- <b-loading v-if="payLoader" :is-full-page="false" v-model="payLoader" :can-cancel="true"></b-loading> -->
                        </div>
                        <countdown :auto-start="false" :time=" 60 * 1000">
                            <!-- <template slot-scope="props">Time Remaining：{{ props.days }} days, {{ props.hours }} hours, {{ props.minutes }} minutes, {{ props.seconds }} seconds.</template> -->
                            <template slot-scope="props">
                                <b-field class="pb-5 mb-3">
                                    <b-button :disabled="props.seconds > 10" @click="checkPay"  class="is-submit-button" expanded type="is-primary">Please wait ... <span v-if="props.seconds > 0">{{ props.seconds }} Seconds</span> </b-button>
                                </b-field>
                                </template>
                        </countdown>
                        <!-- <div class="pb-2 mx-3">
                            <h3 class="title is-5"> MTN</h3>
                            <p class="subtitle">Follow steps below.</p>
                        </div>
                        <ol>
                            <li>Dial <span class="has-text-weight-semibold">*170#</span> </li>
                            <li>Select - <span class="has-text-weight-semibold">6). My Wallet </span> </li>
                            <li>Select - <span class="has-text-weight-semibold">3). My Approvals</span> </li>
                            <li>Enter Momo PIN </li>
                            <li> Select the transaction from the list.</li>
                            <li>Confirm the transaction</li>
                        </ol>
                        <div class="pb-2 mx-3">
                            <h3 class="title is-5"> VODAFONE</h3>
                            <p class="subtitle">Follow steps below.</p>
                        </div>
                        <ol>
                            <li>Dial <span class="has-text-weight-semibold">*110#</span> </li>
                            <li>Select - <span class="has-text-weight-semibold">6). My Wallet </span> </li>
                            <li>Select - <span class="has-text-weight-semibold">3). My Approvals</span> </li>
                            <li> Select Payment - <span class="has-text-weight-semibold">1). DEBIT - Amount </span></li>
                            <li>Enter - <span class="has-text-weight-semibold">1). Yes</span> to confirm.</li>
                        </ol> -->
                    </div>
                </div>
            </div>
        </b-modal>

            <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        <!-- <MainFooter/> -->
    </div>
</template>

<script>

    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import MainFooter from '../components/footer'
    import mainTemp from '../components/theme/main.vue'
    import classicTemp from '../components/theme/classic.vue'
    import miniTemp from '../components/theme/mini.vue'
    import moment  from 'moment';
    import PayStackVue from '@/components/PayStack.vue'
    import country from '@/plugins/countryCode.json'
    import Userauth from '../auth/index'
    

    export default {
        name: "CreateInvoicePage",
        data(){
            return {
                payLoader: true,
                insView: true,
                isPaymentModalActive: false,
                User: {
                    business: '',
                    bphone: {
                        countryCode: '',
                        tel:''
                    },
                    bemail: '',
                    address: '',
                    country: {
                        name: '',
                        currency: '',
                        code:''
                    },
                },
                form:{
                    name: '',
                    code: '',
                    phone: '',
                    balance: null,
                    amount: null,
                    total: null,
                    address: ''
                },
                code: null,
                country: country,
                isModalActive: false,
                isPmtModalActive: false,
                isFullPage: true,
                business: '',
                businessData: null,
                isOpen: false,
                note: '',
                deliveryTime: '',
                scheduleDate: '',
                datetime: new Date(),
                channel: null,
                payInt: true,
                metadata: null,
                maxAmount: null,
                isGradient: false,
                isEmpty: false,
                isBordered: false,
                isStriped: false,
                isNarrowed: false,
                isHoverable: false,
                isFocusable: false,
                hasMobileCards: false,
                isLoading: false,
                screenWidth: 0,
                clientView: false,
                theme: null,
                themeType: ['main', 'classic', 'mini' ],
                invoice: {
                    id: '',
                    ref: '',
                    client: '',
                    business: '', 
                    type: '', 
                    items: '',
                    total: 0, 
                    paid: '',
                    discount: '',
                    date: '',
                    due: '',
                    dueDate: '',
                    notes: 0,
                    color: 1,
                    user: 1,
                    status: 1,
                },
                client: {
                    name: '',
                    phone: '',
                    email: '',
                    address: '',
                },
                item: {
                    category: '',
                    description: '',
                    rate: 0,
                    qty: 0,
                    total: 0,
                },
                columns: [
                    // {
                    //     field: 'id',
                    //     label: 'ID',
                    //     width: '40',
                    //     numeric: true
                    // },
                    {
                        field: 'description',
                        label: 'Description',
                    },
                    {
                        field: 'rate',
                        label: 'Rate',
                    },
                    {
                        field: 'qty',
                        label: 'Qty',
                    },
                    {
                        field: 'total',
                        label: 'Total',
                    }
                ],
                InvoiceType: ['Invoice', 'Pro forma'],
                bn: {
                    type:'',
                    message:'',
                },
                am: {
                    type:'',
                    message:'',
                },
                cn: {
                    type:'',
                    message:'',
                },
                options: [
                    {
                        label: 'Select Client',
                        code: null
                    }
                ],
            }
        },
        components: {
            // MainFooter,
            PayStackVue,
            mainTemp,
            classicTemp,
            miniTemp
        },
        computed: {
            //
            // ...mapGetters('clients', ['getClients']),
            ...mapGetters('invoices', ['verifyLink']),

            getUser() {
                if (Userauth.isAuthenticated()) {
                   return this.$store.getters["auth/getUser"]
                } else {
                    return this.User
                }
            },
            reference(){
                let text = "";
                let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        
                for( let i=0; i < 10; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));
        
                return text;
            }
        },
        watch: {
            dueValue() {
                //
                this.form.due = this.dueValue

                if (this.dueValue && parseInt(this.dueValue)) {

                    console.log(moment((this.form.date ? new Date(this.form.date) : new Date())).add(parseInt(this.form.due), 'days').toDate())

                    this.form.dueDate = moment((this.form.date ? new Date(this.form.date) : new Date())).add(parseInt(this.form.due), 'days').toDate()
                } else {
                    this.form.dueDate = this.form.date ? new Date(this.form.date) : new Date()
                }
            },
            getClients() {

                if (this.getClients) {
                    let options = []

                    this.getClients.forEach(v=>{
                        options.push({ label: v.name, code: v.name })
                        // options.push({ label: v.name+' '+v.phone.countryCode+v.phone.tel, code: v.name })
                    })
                    //
                    this.options = this.options.concat(options)
                }
            },
        },
        mounted() {
            
            this.updateScreenWidth();
            this.onScreenResize();
            // this.fetchData()

            // console.log(Userauth.isAuthenticated())
            // console.log(typeof(Userauth.isAuthenticated() ))
            // console.log(this.getUser)
            
            //
            if (Object.keys(this.$route.query).length !== 0) {

                // console.log(this.$route.query.ref)

                if (typeof(this.$route.query.ref) == "undefined" || typeof(this.$route.query.key) == "undefined" && typeof(this.$route.query.name) == "undefined") {
                    //
                    this.$buefy.toast.open("Oops, invalid details")

                    setTimeout(()=>{
                        this.$router.push('/signin')
                    }, 1500)

                    return
                }

                if (!this.verifyLink('#'+this.$route.query.ref, this.$route.query.key) ) {
                    //
                    this.$buefy.toast.open("Oops, security check failed")

                    setTimeout(()=>{
                        this.$router.push('/signin')
                    }, 1500)

                    return
                }

                const ref = this.$route.query.ref
                let url
                if (Userauth.isAuthenticated()) {
                    url = '/invoices/'+this.getUser.business+'/'+ref
                    // this.code = this.country.find(c =>c.name == this.getUser.country.name).code
                } else {
                    url = '/invoices/'+this.$route.query.key+'/'+this.$route.query.name+'/'+ref
                }
                
                //
                axios.get(url).then((res)=>{
                    //
                    this.invoice = res.data[0];

                    if (!Userauth.isAuthenticated()){
                        
                        this.User.business = res.data[1].name
                        this.User.bphone = res.data[1].phone
                        this.User.address = res.data[1].address
                        this.User.country = res.data[1].country
                        this.User.invoiceGradientEnabled = res.data[1].invoiceGradientEnabled

                        // this.code =this.country.find(c =>c.name == res.data[1].country.name).code

                    }
                    
                    this.theme = this.invoice.theme

                    this.isLoading = false

                    if (Userauth.isAuthenticated()) { 

                        

                        axios.get('/clients/'+this.getUser.business+'/'+this.invoice.client).then((res)=>{
                            //
                            this.client = res.data[0];

                            this.isLoading = false

                            // this.checkShop()
                            // this.checkVisit()
                        })

                    } else {
                        axios.get('/clients/'+this.$store.getters['invoices/generateLinkHash'](this.invoice.client)+'/'+this.$route.query.name+'/'+this.invoice.client).then((res)=>{
                            //
                            this.client = res.data[0];

                            this.form.code = this.client.phone.countryCode
                            this.form.phone = this.client.phone.tel

                            this.isLoading = false

                            // this.checkShop()
                            // this.checkVisit()
                        })
                    }

                    // this.checkShop()
                    // this.checkVisit()
                })


                

            // this.$store.dispatch('shops/getShop', { shop: this.$route.query.shop, type:"shop" } ).then(()=>{
            //     //
            //     console.log(this.$store.getters['shops/getShops'][0])
            //     //
            //     this.invoice = this.$store.getters['shops/getShops'][0]
            // })
            }

            else if (Object.keys(this.$route.params).length !== 0) {

                // console.log(this.$route.params)

                const data = {
                        link: 'pay_page',
                        data: this.$route.params
                    }

                this.invoice.id = this.$route.params.id
                this.invoice.ref = this.$route.params.ref
                this.invoice.client = this.$route.params.client
                this.invoice.business = this.$route.params.business
                this.invoice.type = this.$route.params.type
                this.invoice.items = this.$route.params.items
                this.invoice.total = this.$route.params.total
                this.invoice.paid = this.$route.params.paid
                this.invoice.discount = this.$route.params.discount
                this.invoice.date = this.$route.params.date
                this.invoice.due = this.$route.params.due
                this.invoice.dueDate = this.$route.params.dueDate
                this.invoice.notes = this.$route.params.notes
                this.invoice.color = this.$route.params.color
                this.invoice.gradientColor = this.$route.params.gradientColor
                this.theme = this.$route.params.theme
                this.invoice.user = this.$route.params.user
                this.invoice.status = this.$route.params.status

                this.$store.dispatch('pages/setPage', data)

                this.isLoading = false

                axios.get('/clients/'+this.getUser.business+'/'+this.invoice.client).then((res)=>{
                            //
                            this.client = res.data[0];
                            this.form.code = this.client.phone.countryCode
                            this.form.phone = this.client.phone.tel

                            this.isLoading = false

                            // this.checkShop()
                            // this.checkVisit()
                        })


                // this.checkShop()
                // this.checkVisit()
            }
            else {

                const data = this.$store.getters['pages/getPage']('view_invoice')

                if (data !== null) {
                        // this.form = data.search
                        this.invoice.id = data.id
                        this.invoice.ref = data.ref
                        this.invoice.client = data.client
                        this.invoice.business = data.business
                        this.invoice.type = data.type
                        this.invoice.items = data.items
                        this.invoice.total = data.total
                        this.invoice.paid = data.paid
                        this.invoice.discount = data.discount
                        this.invoice.date = data.date
                        this.invoice.due = data.due
                        this.invoice.dueDate = data.dueDate
                        this.invoice.notes = data.notes
                        this.invoice.color = data.color
                        this.invoice.gradientColor = data.gradientColor
                        this.theme = data.theme
                        this.invoice.user = data.user
                        this.invoice.status = data.status
                }

                this.isLoading = false

                axios.get('/clients/'+this.getUser.business+'/'+this.invoice.client).then((res)=>{
                            //
                            this.client = res.data[0];
                            this.form.code = this.client.phone.countryCode
                            this.form.phone = this.client.phone.tel

                            this.isLoading = false

                            // this.checkShop()
                            // this.checkVisit()
                        })

                // this.checkShop()
                // this.checkVisit()
            }

            
        },
        methods: {
             
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            fetchData() {
                this.$store.dispatch('clients/getClients', this.getUser.business)
            },
            validateName() {
                if (this.form.name.length < 3){
                    this.bn.type = 'is-danger';
                    this.bn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.name.length >= 3){
                    this.bn.type = 'is-success';
                    this.bn.message =''
                    return true
                }
            },
            validateAmount() {
                if (this.form.amount == null) {
                    this.am.type = 'is-danger';
                    this.am.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.amount == 0) {
                    this.am.type = 'is-danger';
                    this.am.message ='Enter a valid number'
                    return false
                    
                }
                //  if (this.form.amount >= this.maxAmount){
                if (this.form.amount > (this.invoice.total - this.invoice.paid)){
                    this.am.type = 'is-danger';
                    this.am.message ='Pay amount should not be more than the Balance: '+(this.invoice.total - this.invoice.paid)
                    return false
                }

                 if (this.form.amount <= (this.invoice.total - this.invoice.paid)){
                    this.am.type = 'is-success';
                    this.am.message =''
                    return true
                }
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)

                if (reg.test(this.form.phone)) {

                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.phone.length > 8 && this.form.phone.length < 11){
                        this.cn.type = 'is-success';
                        this.cn.message =''
                        return true
                    } else {
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a contact number'
                        return false
                    }
                }


            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                if ( !this.validateAmount() || !this.validateContact()){
                // if ( (!this.form.client || (!this.validateName() && !this.validateContact())) || !this.form.items.reduce((a, b) => a + b.total, 0) > 0 ){
                    return
                }

                this.isLoading = true

                console.log(this.form)

                //
                // this.form.business = this.getUser.business
                // this.form.total = this.genDis( (this.form.discount ? this.form.discount : 0),  this.form.items.reduce((a, b) => a + b.total, 0) )
                let url
                if (Userauth.isAuthenticated) {
                    //
                    url = '/payments/pay'
                } else { 
                    //
                    url = '/payments/'+this.$store.getters['invoices/generateLinkHash'](this.invoice.ref)+'/pay'
                }
                
                // ajax
                axios.post(url, { receiver_phone: this.getUser.bphone, invoice: this.invoice, business: this.getUser.business, sender_phone: { countryCode: this.form.code, tel: this.form.phone}, client: this.invoice.client, amount: this.form.amount }).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open({ message: response.data.message, duration:3500 })

                    this.order_id = response.data.order_id
                    
                    // run the timer for payment confirmation 
                    

                    //
                    // if (Userauth.isAuthenticated) {
                    //     //
                    //     let data 
                    //     axios.get('/payments/'+this.getUser.business+'/'+response.data.ref).then((res)=>{
                    //         //
                    //         data = res.data[0];
                    //         //
                    //         setTimeout(()=>{
                    //             this.$router.push({ name: 'pay_receipt', params: { 
                    //                 id: data._id,
                    //                 invoice: data.invoice,
                    //                 receipt: data.receipt,
                    //                 client: data.client,
                    //                 business: data.business,
                    //                 type: data.type,
                    //                 amount: data.amount,
                    //                 date: data.date,
                    //                 details: data.details,
                    //                 color: data.color,
                    //              } })
                    //         }, 2000)
                    //     })
                        
                    // } else {
                    //     //
                    //     setTimeout(()=>{
                    //         this.$router.push({ name: 'pay_receipt', query: { ref: response.data.receipt.substring(1), name: this.getUser.business, key: this.$store.getters['invoices/generateLinkHash'](response.data.receipt) } })

                    //     }, 1500)
                    //     // 
                    // }
                    // this.$router.push({ name: 'view_invoice', params: { ref: response.data.ref } })


                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open({ message: error.response.data, duration: 3500})
                })

                // alert(JSON.stringify(this.form))
            },
            checkPay() {
                if (this.order_id) {

                    let url
                    if (Userauth.isAuthenticated) {
                        //
                        url = '/payments/pay'+this.getUser.business+'/'+this.order_id
                    } else { 
                        //
                        url = '/payments/'+this.$store.getters['invoices/generateLinkHash'](this.invoice.ref)+'/pay/'+this.getUser.business+'/'+this.order_id
                    }
                    //
                    axios.get(url).then((response)=> {

                        //
                        this.$buefy.toast.open({ message: response.data.message, duration:3000 })
                        //
                        if (Userauth.isAuthenticated) {
                        //
                        let data 
                        axios.get('/payments/'+this.getUser.business+'/'+response.data.receipt.substring(1)).then((res)=>{
                            //
                            data = res.data[0];
                            //
                            setTimeout(()=>{
                                this.$router.push({ name: 'pay_receipt', params: { 
                                    id: data._id,
                                    invoice: data.invoice,
                                    receipt: data.receipt,
                                    client: data.client,
                                    business: data.business,
                                    type: data.type,
                                    amount: data.amount,
                                    date: data.date,
                                    details: data.details,
                                    color: data.color,
                                 } })
                            }, 2500)
                        })
                            
                        } else {
                            //
                            setTimeout(()=>{
                                this.$router.push({ name: 'pay_receipt', query: { ref: response.data.receipt.substring(1), name: this.getUser.business, key: this.$store.getters['invoices/generateLinkHash'](response.data.receipt) } })

                            }, 2500)
                            // 
                        }
                    })
                }
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            },
            onSelect({name, iso2, dialCode}) {
                this.form.code = "+"+dialCode
                // console.log(name, iso2, dialCode);
                // this.form.country = name.split(" ")[0]
                // this.form.currency = iso2
            },
            calcTot(item) {
                item.total = Math.round( item.qty * item.rate * 1000)/1000
            },
            addItem() {

                let item = {}
                item.category = '' 
                item.description = '' 
                item.qty = null
                item.rate = null
                item.total = null

                this.form.items.push(item)
            },
            removeItem(index){

                if (this.form.items.length > 1) {
                    this.form.items.splice(index,1)
                }
            },
            genDis(discount, amount) {
                return Math.round( ((100 - discount)/100 * amount) * 1000 )/1000
            },
            Discount(discount, amount) {
                return Math.round( ((discount)/100 * amount) * 1000 )/1000
            },
            print() {
                window.print()
            },
            goBack() {
                this.$router.back()
            },
            checkActiveTheme(name) {
                return this.theme == name
            },
            goHome() {
                //
                this.$router.push('/')
            },
            callback: function(response){
                this.$buefy.toast.open("Successfully paid.")
                console.log(response)

                // clear 
                // this.$store.dispatch('cart/emptyCart')
                //
                // this.$router.push('/tracker')
            },
            close: function(){
                console.log("Payment closed")
                //
                // this.isPmtModalActive = false

                //
                // delete pending order
                // axios.post('/order/delete', {  orderNo: this.metadata.orderNo, user: { countryCode: this.getUser.phone.substr(0,4),tel: this.getUser.phone.substr(4) }
                //                         })
                //                         .then( response => {

                //                             //
                //                             this.$buefy.toast.open("Oops failed, try again!")

                //                             // const res = response.data
                //                             // this.isLoading = false
                //                             console.log(response.data)

                //                         })

            },
            setTheme(theme) {
                this.theme = theme

                axios.patch('/invoice/theme/'+this.invoice.ref.substring(1), { business: this.invoice.business, theme: this.theme,  }).then(response=>{
                        //
                        console.log(response.data)
                        // 
                        // this.$buefy.toast.open(response.data)
                        //
                        // this.$router.push({ name: 'view_invoice', params: { ref: response.data.ref } })


                    }).catch(error=>{
                        //
                        this.isLoading = false
                        //
                        console.log(error)
                        //
                        this.$buefy.toast.open(error.response.data)
                    })
            }
        }
    }
</script>

<style lang="scss" >
// @import "vue-select/dist/vue-select.css";
@import '@/assets/scss/custom_variables.scss';


// .table thead {
//     background: $primary !important;
// }

// .table thead th{
//     border-color: $primary !important;
// }

.table thead th span {
    // color: white !important;
    font-size: 0.85rem;
    font-weight: 800;
    color: black !important;
    // color: $primary !important;
}

.bg{
    background: $primary !important;
    background-color: $primary !important;
}

.gradient {
    background-image: linear-gradient(160deg, #06067d 25%, $primary 100%);
}
</style>