//
import axios from 'axios'

export const payments = {
    namespaced: true,
    state: {
        payments: []
     },
    getters: {
        getPayments: (state) => {
            //
            const data = state.payments
            data.sort((a,b) => new Date(b.date) -  new Date(a.date))
        
            return data
        },
        searchPayments: state => (search) => {
            //
            const results = state.payments.filter(({ invoice, client, type, amount, details, date, dd1, dd2 }) => 
                invoice.toLowerCase().includes(search.toLowerCase()) || 
                client.toLowerCase().includes(search.toLowerCase()) || 
                type.toLowerCase().includes(search.toLowerCase()) || 
                amount == search || 
                details.toLowerCase().includes(search.toLowerCase()) || 
                date.toLowerCase().includes(search.toLowerCase()) ||
                dd1.toLowerCase().includes(search.toLowerCase()) ||
                dd2.toLowerCase().includes(search.toLowerCase()) 
            
            )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchBusinessPayments: state => (search) => {
            //
            const results = state.payments.filter(({ invoice }) => invoice.toLowerCase().includes(search.toLowerCase()) )

            return results
        }
        // currentBusiness: state => (name) => {
        //     // if (business) { return }
        
        //     const results = state.vendors.find(({ business }) => business.includes(name))
        
        //     return results
        // }
    },
    actions: {
        async getPayments({ state,commit },  business) {
            //
            let data
            //
            if (business.length > 0) {
                axios.get('/payments/'+business).then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                        for (let index = 0; index < data.length; index++) {
            
                            // to 2 decimalplaces
                            // data[index].qty = 0
                            // date
                            data[index].dd1 = (new Date(data[index].date)).toDateString()
                            data[index].dd2 = (new Date(data[index].date)).toISOString()
                        }
                    // })()
                    commit('updatePayments', data)
                })

            } else {
                data = []
            }


            
        }
    },
    mutations: {
        updatePayments: (state, data) => {
            state.payments = data
        }
    }
}