import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
// import './plugins/bootstrap-vue'
import './plugins/axios'
// import './plugins/maps'
// import './plugins/bootstrap-vue'
import router from './router'
import { store } from './store';
import App from './App.vue'
import VueFeather from 'vue-feather';
// import VueSweetalert2 from 'vue-sweetalert2';
// import Notifications from 'vue-notification';
// import Toasted from 'vue-toasted';
import paystack from 'vue-paystack';
import OtpInput from "@bachdgvn/vue-otp-input";
import VueCountryCode from "vue-country-code";
import VueClipboard from 'vue-clipboard2'
import VueCountdown from '@chenfengyuan/vue-countdown';



import vSelect from 'vue-select';
Vue.component('v-select', vSelect)

Vue.use(VueCountryCode);

// Vue.use(Notifications)
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("ApexChart", VueApexCharts);

// Vue.use(Toasted,{
//   iconPack: 'fontawesome',
// });

Vue.use(paystack);

Vue.component(VueFeather.name, VueFeather);

// Vue.use(VueSweetalert2);

Vue.use(VueClipboard)

Vue.component(VueCountdown.name, VueCountdown);

Vue.component("v-otp-input", OtpInput);

// import 'sweetalert2/dist/sweetalert2.min.css';
import Buefy from 'buefy'

// include
import 'buefy/dist/buefy.css'

import './assets/scss/app.scss'
// custom
import './assets/scss/custom.scss'
import './assets/scss/vendors.scss'
import './registerServiceWorker'

Vue.use(Buefy)
// import 'buefy/dist/buefy.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
