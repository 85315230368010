<template>
    <div>
        <div class="p-4" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            <div class="columns is-mobile">
                    <div class="column is-half">
                        <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather>
                        <p class="px-2 py-2 pb-0 has-text-weight-bold ml-5"> {{ ref ? 'Edit: ' : 'Create  Invoice' }} <span v-if="ref" class="has-text-primary">{{ ref }}</span> </p>
                    </div>
                    <div class="column py-4">
                        <b-button type="is-primary" class="is-pulled-right mr-3" icon-left="" @click="clientView ? clientView = false : clientView = true" rounded size="is-small"> add client </b-button>
                    </div>
            </div>
            <div class="mb-5">
                <div class="pb-2">
                        <div class="columns is-mobile">
                            <div class="column is-one-third">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">To </p>
                            </div>
                            <div class="column py-4">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                <!-- <b-field class="report"> -->
                                    <!-- <b-switch v-model="graphIsSwitched" size="is-small">Area Graph</b-switch> -->
                                    <!-- <b-select expanded size="is-small" class="is--right" placeholder="Choose client" v-model="client">
                                        <option
                                            v-for="option in options"
                                            :value="option.value"
                                            :key="option.text">
                                            {{ option.text }}
                                        </option>
                                    </b-select> -->
                                    <b-field :type="cl.type" :message="cl.message">
                                        <v-select @input="validateClient()" :disabled="clientDisabled" :reduce="client => client.code" v-model="client" :options="options" label="label"></v-select>
                                    </b-field>
                                <!-- </b-field>  -->
                            </div>
                        </div>
                        <div v-if="clientView" class="animate__animated animate__fadeIn">

                            <div class="columns is-mobile  mb-0">
                                <div class="column">
                                    <b-field :type="bn.type" :message="bn.message">
                                        <b-input autocomplete="0" autofocus @input.native="validateName()" v-model="form.name" placeholder="Name"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field>
                                        <b-input autocomplete="0" v-model="form.email" placeholder="Email"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile mb-0">
                                <div class="column is-one-quarter-widesreen is-one-quarter-desktop is-one-quarter-tablet is-one-third-mobile">
                                    <b-field label="" message="">

                                        <vue-country-code
                                            class=" is-size-5"
                                            @onSelect="onSelect"
                                            :enabledCountryCode="true"
                                            :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                            >
                                        </vue-country-code>
                                        
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="cn.type" :message="cn.message">
                                        <b-input type="number"  @input.native="validateContact()" v-model="form.phone" placeholder="XXXXXXXXX"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field >
                                        <b-input  type="textarea" rows="2" autocomplete="0" v-model="form.address" placeholder="Address"></b-input>
                                    </b-field>
                                </div>
                            </div>

                        </div>

                        <div class="columns is-mobile mb-0">
                            <div class="column is-half">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Invoice title </p>
                            </div>
                            <div class="column py-4">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                
                            </div>
                        </div>
                        <div>
                            <div class="columns is-mobile mb-0">
                                <div class="column">
                                    <b-field >
                                        <b-select @input="invoiceType" expanded class="is--right" placeholder="Invoice Type" v-model="form.type">
                                            <option
                                                v-for="option in InvoiceType"
                                                :value="option"
                                                :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field >
                                        <b-input autocomplete="0" type="number" v-model="form.discount" placeholder="Discount"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile mb-0">
                                <div class="column  is-half">
                                    <b-field label="">
                                        <b-select expanded class="pb-3" placeholder="Invoice Due" v-model="dueValue">
                                            <option
                                                v-for="option in due"
                                                :value="option.value"
                                                :key="option.name">
                                                {{ option.name }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column  is-half">
                                    <b-field label="">

                                        <b-datetimepicker placeholder="Invoice Date" v-model="form.date" >
                                        </b-datetimepicker>
                                        
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        <div class="columns is-mobile">
                            <div class="column is-half">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Invoice items </p>
                            </div>
                            <div class="column py-4">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                <b-button type="is-primary" class="is-pulled-right mr-3" icon-left="" @click="$router.push('add_item')" rounded size="is-small"> add item </b-button>
                            </div>
                        </div>
                        
                        <div v-for="(item,index) in form.items" :key="index" class="animate__animated animate__fadeIn">
                            <div class="columns">
                                <div class="column ">
                                    <b-field :type="itemsRq[index].it.type" :message="itemsRq[index].it.message">
                                        <!-- <b-input autocomplete="0" v-model="item.category" placeholder="Item"></b-input> -->

                                        <v-select @input="getValue(index);validateItem(index)" :reduce="item => item.code" v-model="item.item" :options="itemOptions" label="label"></v-select>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field>
                                        <b-input autocomplete="0" v-model="item.description" placeholder="Description"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-4">
                                    <b-field :type="itemsRq[index].rt.type" :message="itemsRq[index].rt.message">
                                        <b-input type="number" @input="calcTot(item);validateRate(index)" autocomplete="0" step="0.01" v-model="item.rate" placeholder="Rate"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-flex is-align-items-center is-2">
                                    <b-button @click="addItem()" type="is-primary" icon-left="plus"  expanded></b-button>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                               
                                <div class="column">
                                    <b-field :type="itemsRq[index].qt.type" :message="itemsRq[index].qt.message">
                                        <b-input type="number" @input="calcTot(item);validateQty(index)" autocomplete="0" v-model="item.qty" placeholder="Qty"></b-input>
                                    </b-field>
                                </div>
                                <div class="column ">
                                    <b-field :type="itemsRq[index].tl.type" :message="itemsRq[index].tl.message">
                                        <b-input type="number" :disabled="true" step="0.01"  autocomplete="0" v-model="item.total" placeholder="Total"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-flex is-align-items-center is-2">
                                    <b-button @click="removeItem(index)" type="is-danger" icon-left="close"  expanded></b-button>
                                </div>
                            </div>
                            <hr>
                        </div>
                        <div class="columns is-mobile">
                            <div class="column is-5">

                            </div>
                            <div class="column is-4">
                                <div class="has-text-weight-medium small">
                                    <p>Subtotal -</p>
                                    <p>Discount ({{ form.discount ? form.discount : 0 }}%) -</p>
                                    <p>Total - </p>
                                    <p>Due - </p>
                                </div>
                            </div>
                            <div class="column">
                                <div class="has-text-weight-medium small has-text-right">
                                    <p>{{  (form.items.reduce((a, b) => a + b.total, 0)).toFixed(2) }}</p>
                                    <p>{{ Discount( (form.discount ? form.discount : 0),  form.items.reduce((a, b) => a + b.total, 0) ).toFixed(2) }}</p>
                                    <p>{{ genDis( (form.discount ? form.discount : 0),  form.items.reduce((a, b) => a + b.total, 0) ).toFixed(2) }}</p>
                                    <p>({{form.items.reduce((a, b) => a + b.total, 0).toFixed(2)}})</p>
                                </div>
                            </div>
                        </div>

                        <div class="columns is-mobile mb-0">
                            <div class="column is-half">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Invoice Notes </p>
                            </div>
                            <div class="column py-4">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                
                            </div>
                        </div>
                        <div >
                            <div class="columns is-mobile m">
                                <div class="column">
                                    <b-field>
                                        <b-input  type="textarea" rows="3" autocomplete="0" v-model="form.notes" placeholder="Notes"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>

                        <div class="columns is-mobile mb-5 is-multiline">
                            <div class="column is-7">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Invoice Color </p>
                            </div>
                            <div class="column py-4 is-5">
                                <b-field label="">
                                    <!-- <b-colorpicker rounded expanded v-model="form.color" /> -->
                                    <!-- <b-colorpicker rounded expanded v-model="form.color" /> -->
                                    <input class="input" style="padding: 0.1rem 0.1rem !important;" type="color" v-model="form.color">
                                </b-field>
                            </div>

                            <div v-if="getUser.invoiceGradientEnabled" class="column is-7">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Gradient </p>
                            </div>
                            <div v-if="getUser.invoiceGradientEnabled" class="column py-4 is-5">
                                <b-field label="">
                                    <!-- <b-colorpicker rounded expanded v-model="form.color" /> -->
                                    <!-- <b-colorpicker rounded expanded v-model="form.color" /> -->
                                    <input class="input" style="padding: 0.1rem 0.1rem !important;" type="color" v-model="form.gradientColor">
                                </b-field>
                            </div>
                        </div>
                        
                        <!-- <b-button type="is-primary"  expanded>Add item</b-button> -->
                        <!-- <div>
                            <b-table :mobile-cards="false" :data="form.items" :columns="columns"></b-table>
                        </div> -->
                        <!-- {{ form.color }} -->
                            <b-field class="pb-5 mb-3">
                                <b-button @click="onSubmit"  class="is-submit-button" expanded type="is-primary">Generate</b-button>
                            </b-field>
                    </div>

                    <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
            </div>
        </div>
        <MainFooter/>
    </div>
</template>

<script>

    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import MainFooter from '../components/footer'
    import moment  from 'moment';

    export default {
        name: "CreateInvoicePage",
        data(){
            return {
                ref: null,
                isLoading: false,
                screenWidth: 0,
                clientView: false,
                client: null,
                clientDisabled: false,
                form: {
                    business: '',
                    client: null,
                    name: '',
                    email: '',
                    code: '',
                    phone: '',
                    address: '',
                    type: 'Invoice',
                    discount: null,
                    total: 0,
                    items: [
                        {
                            category: '',
                            item: {
                                label: 'Select Item',
                                code: null
                            },
                            description: '',
                            rate: null,
                            qty: null,
                            total: null,
                        }
                    ],
                    date: null,
                    due: 'On Receipt',
                    dueDate: null,
                    notes: '',
                    color: '#092f9f',
                    gradientColor: '#092f9f',
                },
                itemsRq: [
                    {
                        it: {
                            type:'',
                            message:'',
                        },
                        rt: {
                            type:'',
                            message:'',
                        },
                        qt: {
                            type:'',
                            message:'',
                        },
                        tl: {
                            type:'',
                            message:'',
                        },
                    }
                ],
                item: {
                    category: '',
                    item: {
                        label: 'Select Item',
                        code: null
                    },
                    description: '',
                    rate: 0,
                    qty: 0,
                    total: 0,
                },
                itemRq: {
                        it: {
                            type:'',
                            message:'',
                        },
                        rt: {
                            type:'',
                            message:'',
                        },
                        qt: {
                            type:'',
                            message:'',
                        },
                        tl: {
                            type:'',
                            message:'',
                        },
                },
                columns: [
                    // {
                    //     field: 'id',
                    //     label: 'ID',
                    //     width: '40',
                    //     numeric: true
                    // },
                    {
                        field: 'description',
                        label: 'Description',
                    },
                    {
                        field: 'rate',
                        label: 'Rate',
                    },
                    {
                        field: 'qty',
                        label: 'Qty',
                    },
                    {
                        field: 'total',
                        label: 'Total',
                    }
                ],
                InvoiceType: ['Invoice', 'Pro forma'],
                bn: {
                    type:'',
                    message:'',
                },
                cn: {
                    type:'',
                    message:'',
                },
                cl: {
                    type:'',
                    message:'',
                },
                tl: {
                    type:'',
                    message:'',
                },
                options: [
                    {
                        label: 'Select Client',
                        code: null
                    }
                ],
                itemOptions: [
                    {
                        label: 'Select Item',
                        code: null
                    }
                ],
                due: [
                { 
                    name: 'Invoice Due',
                    value: null,
                 },
                 { 
                    name: 'On Receipt (POS)',
                    value: 'On Receipt',
                 },
                 { 
                    name: '7 days',
                    value: '7 days',
                 }, 
                 { 
                    name: '15 days',
                    value: '15 days',
                 }, 
                 { 
                    name: '30 days',
                    value: '30 days',
                 }, 
                 { 
                    name: '60 days',
                    value: '60 days',
                 }, 
                 { 
                    name: '90 days',
                    value: '90 days',
                 }, 
                 { 
                    name: '180 days',
                    value: '180 days',
                 },
                ],
                dueValue: '',
                color: '#092f9f'
            }
        },
        components: {
            MainFooter,
        },
        computed: {
            //
            ...mapGetters('clients', ['getClients']),
            ...mapGetters('items', ['getItems']),
            ...mapGetters('auth', ['getUser'])
        },
        watch: {
            dueValue() {
                //
                this.form.due = this.dueValue

                if (this.dueValue && parseInt(this.dueValue)) {

                    console.log(moment((this.form.date ? new Date(this.form.date) : new Date())).add(parseInt(this.form.due), 'days').toDate())

                    this.form.dueDate = moment((this.form.date ? new Date(this.form.date) : new Date())).add(parseInt(this.form.due), 'days').toDate()
                } else {
                    this.form.dueDate = this.form.date ? new Date(this.form.date) : new Date()
                }
            },
            getClients() {

                if (this.getClients) {
                    let options = []

                    this.getClients.forEach(v=>{
                        options.push({ label: v.name+' - ('+(v.total - v.paid).toFixed(2)+')', code: v.name })
                        // options.push({ label: v.name+' '+v.phone.countryCode+v.phone.tel, code: v.name })
                    })
                    //
                    this.options = this.options.concat(options)
                }
            },
            getItems() {
                if (this.getItems) {
                    let options = []

                    this.getItems.forEach(v=>{
                        options.push({ label: v.item+' - '+this.getUser.country.currency+v.price+(v.discount ? ' ('+v.discount+' % Off)' : ''), code: v.item, price: v.price, discount: v.discount })
                        // options.push({ label: v.name+' '+v.phone.countryCode+v.phone.tel, code: v.name })
                    })
                    //
                    this.itemOptions = this.itemOptions.concat(options)
                }
            },
            client() {
                if (this.client) {
                    //
                    this.clientView = false
                    this.form.client = this.client
                } 
                else {
                    this.clientView = false
                }
            },
        },
        mounted() {

            this.updateScreenWidth();
            this.onScreenResize();
            this.fetchData()

            //
            this.form.color = this.getUser.color ? this.getUser.color : '#092f9f'
            this.form.gradientColor = '#092f9f'

            //
            this.dueValue = 'On Receipt'

            if (Object.keys(this.$route.params).length !== 0) {

                console.log(this.$route.params.invoice)

                const data = {
                        link: 'create_invoice',
                        data: this.$route.params
                    }

                //
                // this.$route.params.invoice.items.forEach(v=>{
                //     this.form.items.push(v)
                // })

                for (let index = 1; index < this.$route.params.invoice.items.length; index++) {
                    // const element = array[index];
                    
                    let itemRq = {}
                    itemRq.it = {
                        label: 'Select Item',
                        code: null
                    },
                    itemRq.rt = { type:'',  message:'' },
                    itemRq.qt = { type:'',  message:'' },
                    itemRq.tl = { type:'',  message:'' },

                    this.itemsRq.push(itemRq)
                }

                // this.invoice.id = this.$route.params.id
                this.ref = this.$route.params.invoice.ref
                this.client = this.$route.params.invoice.client
                this.form.business = this.$route.params.invoice.business
                this.type = this.$route.params.invoice.type
                this.form.items = this.$route.params.invoice.items
                // this.invoice.total = this.$route.params.invoice.total
                // this.invoice.paid = this.$route.params.paid
                this.form.discount = this.$route.params.invoice.discount
                this.form.date = new Date(this.$route.params.invoice.date)
                this.form.due = this.$route.params.invoice.due
                // this.invoice.dueDate = this.$route.params.dueDate
                this.form.notes = this.$route.params.invoice.notes
                this.form.color = this.$route.params.invoice.color
                // this.invoice.user = this.$route.params.user
                // this.invoice.status = this.$route.params.status

                // this.$store.dispatch('pages/setPage', data)
                this.clientDisabled = true

                this.isLoading = false

                // this.checkShop()
                // this.checkVisit()
                }
                else {

                const data = this.$store.getters['pages/getPage']('create_invoice')
                

                if (typeof data !== 'undefined') {
                        // this.form = data.search
                        this.invoice.id = data.id
                        this.invoice.ref = data.ref
                        this.invoice.client = data.client
                        this.invoice.business = data.business
                        this.invoice.type = data.type
                        this.invoice.items = data.items
                        this.invoice.total = data.total
                        this.invoice.paid = data.paid
                        this.invoice.discount = data.discount
                        this.invoice.date = data.date
                        this.invoice.due = data.due
                        this.invoice.dueDate = data.dueDate
                        this.invoice.notes = data.notes
                        this.invoice.color = data.color
                        this.invoice.user = data.user
                        this.invoice.status = data.status
                }

                this.isLoading = false

                // this.checkShop()
                // this.checkVisit()
                }
        },
        methods: {
            
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            fetchData() {
                this.$store.dispatch('clients/getClients', this.getUser.business)
                this.$store.dispatch('items/getItems', this.getUser.business)
            },
            validateName() {
                if (this.form.name.length < 3){
                    this.bn.type = 'is-danger';
                    this.bn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.name.length >= 3){
                    this.bn.type = 'is-success';
                    this.bn.message =''
                    return true
                }
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)

                if (reg.test(this.form.phone)) {

                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.phone.length > 8 && this.form.phone.length < 11){
                        this.cn.type = 'is-success';
                        this.cn.message =''
                        return true
                    } else {
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a contact number'
                        return false
                    }
                }


            },
            validateItem(index) {
                if (this.form.items[index].item == null) {
                    this.itemsRq[index].it.type = 'is-danger';
                    this.itemsRq[index].it.message ='Select a Item'
                    return false
                    
                }
                if (this.form.items[index].item !== null) {
                    this.itemsRq[index].it.type = 'is-danger';
                    this.itemsRq[index].it.message =''
                    return false
                    
                }
            },
            validateRate(index) {
                if (this.form.items[index].rate == null) {
                    this.itemsRq[index].rt.type = 'is-danger';
                    this.itemsRq[index].rt.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.items[index].rate == 0) {
                    this.itemsRq[index].rt.type = 'is-danger';
                    this.itemsRq[index].rt.message ='Enter a valid number'
                    return false
                    
                }
                // if (this.form.total > this.maxAmount){
                //     this.tl.type = 'is-danger';
                //     this.tl.message ='Pay amount should not be more than the Balance: '+this.maxAmount
                //     return false
                // }

                if (this.form.items[index].rate > 0){
                    this.itemsRq[index].rt.type = 'is-success';
                    this.itemsRq[index].rt.message =''
                    return true
                }
            },
            validateQty(index) {
                if (this.form.items[index].qty == null) {
                    this.itemsRq[index].qt.type = 'is-danger';
                    this.itemsRq[index].qt.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.items[index].qty == 0) {
                    this.itemsRq[index].qt.type = 'is-danger';
                    this.itemsRq[index].qt.message ='Enter a valid number'
                    return false
                    
                }
                // if (this.form.total > this.maxAmount){
                //     this.tl.type = 'is-danger';
                //     this.tl.message ='Pay amount should not be more than the Balance: '+this.maxAmount
                //     return false
                // }

                if (this.form.items[index].qty > 0){
                    this.itemsRq[index].qt.type = 'is-success';
                    this.itemsRq[index].qt.message =''
                    return true
                }
            },
            validateTot(index) {
                if (this.form.items[index] == null) {
                    this.itemsRq[index].tl.type = 'is-danger';
                    this.itemsRq[index].tl.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.items[index] == 0) {
                    this.itemsRq[index].tl.type = 'is-danger';
                    this.itemsRq[index].tl.message ='Enter a valid number'
                    return false
                    
                }
                // if (this.form.total > this.maxAmount){
                //     this.tl.type = 'is-danger';
                //     this.tl.message ='Pay amount should not be more than the Balance: '+this.maxAmount
                //     return false
                // }

                if (this.form.items[index] > 0){
                    this.itemsRq[index].tl.type = 'is-success';
                    this.itemsRq[index].tl.message =''
                    return true
                }
            },
            validateTotal() {
                if (this.form.total == null) {
                    this.tl.type = 'is-danger';
                    this.tl.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.total == 0) {
                    this.tl.type = 'is-danger';
                    this.tl.message ='Enter a valid number'
                    return false
                    
                }
                // if (this.form.total > this.maxAmount){
                //     this.tl.type = 'is-danger';
                //     this.tl.message ='Pay amount should not be more than the Balance: '+this.maxAmount
                //     return false
                // }

                if (this.form.total > 0){
                    this.tl.type = 'is-success';
                    this.tl.message =''
                    return true
                }
            },
            validateClient() {
                if (this.client == null){
                    this.cl.type = 'is-danger';
                    this.cl.message ='Select a client'
                    return false
                }

                if (this.client !== null){
                    this.cl.type = 'is-success';
                    this.cl.message =''
                    return true
                }
            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                //
                
                // validate
                if ( (this.clientView ? (!this.validateName() || !this.validateContact()) : !this.validateClient() ) ||

                this.form.items.forEach((v,i) => {
                    !this.validateRate(i) ||
                    !this.validateQty(i)
                })

                || !this.form.items.reduce((a, b) => a + b.total, 0) > 0
                
                ){
                // if ( (!this.form.client || (!this.validateName() && !this.validateContact())) || !this.form.items.reduce((a, b) => a + b.total, 0) > 0 ){
                    return
                }

                this.isLoading = true

                console.log(this.form)

                //
                this.form.business = this.getUser.business
                this.form.total = this.genDis( (this.form.discount ? this.form.discount : 0),  this.form.items.reduce((a, b) => a + b.total, 0) )
                
                let method,url
                //
                if (this.ref) {
                    url = '/invoice/'+this.ref.substring(1)
                    method = axios.patch
                } else {
                    url = '/invoice'
                    method = axios.post
                }
                
                // ajax
                method(url, this.form).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)
                    const ref = response.data.split(" ")[2]
                    //
                    this.$router.push({ name: 'view_invoice', query: { ref: ref } })

                    this.isLoading = false

                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            },
            onSelect({name, iso2, dialCode}) {
                this.form.code = "+"+dialCode
                // console.log(name, iso2, dialCode);
                // this.form.country = name.split(" ")[0]
                // this.form.currency = iso2
            },
            calcTot(item) {
                item.total = Math.round( item.qty * item.rate * 1000)/1000
            },
            addItem() {

                let item = {}
                item.item = {
                        label: 'Select Item',
                        code: null
                    }
                item.category = '' 
                item.description = '' 
                item.qty = null
                item.rate = null
                item.total = null

                let itemRq = {}
                itemRq.it = { type:'',  message:'' },
                itemRq.rt = { type:'',  message:'' },
                itemRq.qt = { type:'',  message:'' },
                itemRq.tl = { type:'',  message:'' },

                this.form.items.push(item)
                this.itemsRq.push(itemRq)
            },
            removeItem(index){

                if (this.form.items.length > 1) {
                    this.form.items.splice(index,1)
                }

                if (this.itemsRq.length > 1) {
                    this.itemsRq.splice(index,1)
                }
            },
            getValue(index) {
                // console.log(value)
                const match = this.itemOptions.find(it=> it.code == this.form.items[index].item)
                // this.form.items[index].rate = this.itemOptions.find(ix=>ix.label.code == this.form.items[index].item).price
                this.form.items[index].rate = match.discount ? this.genDis(match.discount, match.price) : match.price
                // console.log(this.form.items[index].item)

            },
            genDis(discount, amount) {
                return Math.round( ((100 - discount)/100 * amount) * 1000 )/1000
            },
            Discount(discount, amount) {
                return Math.round( ((discount)/100 * amount) * 1000 )/1000
            },
            
            goBack() {
                this.$router.back()
            },
            goHome() {
                //
                this.$router.push('/')
            },
            invoiceType() {
                if (this.form.type == 'Pro forma') {
                    //
                    this.dueValue = null

                } else {
                    this.dueValue = 'On Receipt'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
// @import "vue-select/dist/vue-select.css";
@import '@/assets/scss/custom_variables.scss';

textarea:not([rows]) {
    max-height: 40em;
    min-height: 5em !important;
}

.vs__selected-options input[class] {
    padding: 0rem !important;
    font-size: 0.9rem !important;
}

.vs__selected {
    padding: 0rem !important;
    font-size: 0.9rem !important;
}

.vs__deselect {
    margin: 3px;
    margin-left: 5px;
    font-size: 0.9rem !important;
}
</style>