<template>
    <div>
        <!-- <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading> -->
        <div class=" p-5"  :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            
            <!-- <feather size="30" @click="goBack" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather> -->
            <!-- <feather v-if="cartCount > 0" @click="emptyCart" size="30" type="trash" class="mx-5 mx-2 dark" style="right:0;position:absolute" ></feather> -->
            <!-- <h4 class="title is-6 ml-5 pl-5">Business </h4> -->
            <div class="columns is-mobile">
                    <div class="column is-half">
                        <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather>
                        <p class="px-2 py-2 pb-0 has-text-weight-bold ml-5"> Business </p>
                    </div>
                    <div class="column py-4">
                        <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> User  </b-button>
                    </div>
            </div>
            <!--  -->
            <!-- Total -->
            <div class="p-3 ">
                <div class=" pb-2">
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="Business Name" label-position="on-border" :type="fn.type" :message="fn.message">
                                        <b-input disabled @input="validateFname()" autofocus v-model="getUser.business" placeholder="Business Name"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="Email" label-position="on-border" message="">
                                        <b-input  v-model="form.email" placeholder="test@email.com"></b-input>
                                    </b-field>
                                </div>
                                <div class="column is-one-third-mobile">
                                    <b-field label="Color" label-position="on-border" message="">
                                        <input class="input" style="padding: 0.3rem 0.3rem !important;height: 48px;" v-model="form.color" type="color">
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <!-- <div class="column">
                                    <b-field label="Phone" label-position="on-border" message="">
                                        <b-input disabled  v-model="form.phone" placeholder="Phone "></b-input>
                                    </b-field>
                                </div> -->

                                <div class="column is-one-quarter-widesreen is-one-quarter-desktop is-one-quarter-tablet is-one-third-mobile">
                                    <b-field label="Country" label-position="on-border" message="">

                                        <vue-country-code
                                            class=" is-size-5"
                                            @onSelect="onSelect"
                                            :enabledCountryCode="true"
                                            :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                            >
                                        </vue-country-code>
                                        
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="Phone" label-position="on-border" :type="cn.type" :message="cn.message">
                                        <b-input type="number"  @input.native="validateContact()" v-model="form.phone" placeholder="XXXXXXXXX"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <!-- <div class="column">
                                    <b-field label="Phone" label-position="on-border" message="">
                                        <b-input disabled  v-model="form.phone" placeholder="Phone "></b-input>
                                    </b-field>
                                </div> -->

                                <div class="column is-one-quarter-widesreen is-one-quarter-desktop is-one-quarter-tablet is-one-third-mobile">
                                    <b-field label="Country" label-position="on-border" message="">

                                        <vue-country-code
                                            class=" is-size-5"
                                            @onSelect="onSelect2"
                                            :enabledCountryCode="true"
                                            :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                            >
                                        </vue-country-code>
                                        
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="Mobile Money Phone" label-position="on-border" >
                                        <b-input type="number" v-model="form.momo" placeholder="XXXXXXXXX"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="Address" label-position="on-border" message="">
                                        <b-input type="textarea" v-model="form.address" placeholder="Address"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            

                            <b-field v-if="checkChanges" class="pt-1">
                                <b-button @click="onSubmit" class="is-submit-button" expanded type="is-primary">Update</b-button>
                            </b-field>

                            <!-- {{ form }} -->
                        </div>
                <!-- <p class=""> :( No Free Coupons today</p> -->
            </div>
            <hr>
        
            <div class="p-3">

                <div class=" py-2">
                    <div class="columns is-mobile">
                        <div class="column is-two-thirds">
                            <p class=" has-text-dark has-text-weight-semibold"> Stock  Enabled</p>
                            <span class="small has-text-grey">inventory</span>
                        </div>
                        <div class="column">
                            <div  class="is-pulled-right">
                                <b-field>
                                    <b-switch size="is-smal" v-model="other.stockEnabled"></b-switch>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="px-5 py-2">
                    <div class="columns is-mobile">
                        <div class="column is-two-thirds">
                            <p class=" has-text-dark has-text-weight-semibold"> SMS Enabled</p>
                            <span class="small has-text-grey">Sending</span>
                        </div>
                        <div class="column">
                            <div  class="is-pulled-right">
                                <b-field>
                                    <b-switch size="is-smal" v-model="other.smsEnabled"></b-switch>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class=" my-2">
                    <div class="columns is-mobile">
                        <div class="column is-two-thirds">
                            <p class=" has-text-dark has-text-weight-semibold"> Invoice Gradient Design</p>
                            <span class="small has-text-grey">Background</span>
                        </div>
                        <div class="column">
                            <div  class="is-pulled-right">
                                <b-field>
                                    <b-switch size="is-smal" v-model="other.invoiceGradientEnabled"></b-switch>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-2">
                    <div class="columns is-mobile">
                        <div class="column is-two-thirds">
                            <p class=" has-text-dark has-text-weight-semibold"> Invoice View Alerts</p>
                            <span class="small has-text-grey">On viewing</span>
                        </div>
                        <div class="column">
                            <div  class="is-pulled-right">
                                <b-field>
                                    <b-switch size="is-smal" v-model="other.invoiceViewAlertEnabled"></b-switch>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-2 mb-5">
                    <div class="columns is-mobile">
                        <div class="column is-two-thirds">
                            <p class=" has-text-dark has-text-weight-semibold"> Invoice Due Alerts</p>
                            <span class="small has-text-grey">Reminders</span>
                        </div>
                        <div class="column">
                            <div  class="is-pulled-right">
                                <b-field>
                                    <b-switch size="is-smal" v-model="other.invoiceViewDueEnabled"></b-switch>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>

                <b-field v-if="checkSetChanges" class="pt-1">
                    <b-button @click="submitSettings" class="is-submit-button" expanded type="is-primary">Submit</b-button>
                </b-field>
            </div>

            <hr>
            <div class="p-3">
                <div class=" pb-2">
                            <div class="columns is-mobile">
                                <div class="column is-6 ">
                                    <b-field label="SMS Sender Name" label-position="on-border" :type="sn.type" :message="sn.message">
                                        <b-input :disabled="smsSender.approved == true" maxlength="11" @input="validateSender()" autofocus v-model="smsSender.sender" placeholder="Sender Name"></b-input>
                                    </b-field>
                                </div>
                                <div class="column ">
                                    <b-tag class="has-text-weight-semibold" :type="smsSender.approved == true ? 'is-primary' : 'is-warning'">{{ smsSender.approved ? "APPOVED" : "PENDING" }}</b-tag>
                                </div>
                                <div class="column ">
                                    <b-button :disabled="smsSender.approved == true"  type="is-primary" @click="saveSMSsender">Save</b-button>
                                </div>
                                
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="SMS Total" label-position="on-border" message="">
                                        <b-input number v-model="sms.total" placeholder=""></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="SMS Used" label-position="on-border" message="">
                                        <b-input number v-model="sms.used" placeholder=""></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-button type="is-primary" >Purchase</b-button>
                                </div>
                            </div>
                </div>
            </div>

            <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex"
    import { userService } from '../services';

    export default {
        name: "UserPage",
        data() {
            return {
                isLoading: false,
                isFullPage: true,
                screenWidth: 0,
                form: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    momo: '',
                    code: '',
                    code2: '',
                    address: '',
                    color: '#092f9f'
                },
                other: {
                    stockEnabled: true,
                    smsEnabled: false,
                    invoiceGradientEnabled: false,
                    invoiceViewDueEnabled: true,
                    invoiceViewAlertEnabled: true,
                },
                smsSender: {
                    sender: '',
                    approved: null
                },
                sms:{
                    amount: 0,
                    total: 0,
                    used: 0
                },
                fn: {
                    type:'',
                    message:'',
                },
                ln:{
                    type:'',
                    message:'',
                },
                sn:{
                    type:'',
                    message:'',
                },
                cn:{
                    type:'',
                    message:'',
                },
                show: true
            }
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            checkChanges() {

                if (this.form.phone !== this.getUser.bphone.tel || this.form.email !== this.getUser.email || this.form.address !== this.getUser.address || this.form.color !== this.getUser.color ){
                    //
                    return true
                }

                return false
            },
            checkSetChanges() {
                if (this.other.invoiceViewDueEnabled !== this.getUser.invoiceViewDueEnabled || this.other.invoiceGradientEnabled !== this.getUser.invoiceGradientEnabled || this.other.stockEnabled !== this.getUser.stockEnabled || this.other.invoiceViewAlertEnabled !== this.getUser.invoiceViewAlertEnabled ){
                    //
                    return true
                }

                return false
            }
        },
        mounted() {
            // this.business = this.$store.getters['cart/getCartBusiness']
            this.updateScreenWidth();
            this.onScreenResize();

            //
            this.form.email = this.getUser.bemail
            this.form.phone = this.getUser.bphone.tel
            this.form.code = this.getUser.bphone.countryCode
            this.form.address = this.getUser.address
            this.form.color = this.getUser.color ? this.getUser.color : '#092f9f'

            if (!this.getUser.momo){
                this.form.momo = this.getUser.bphone.tel
                this.form.code2 = this.getUser.bphone.countryCode
            } else {
                this.form.momo = this.getUser.momo.tel
                this.form.code2 = this.getUser.momo.countryCode
            }

            //
            this.other.stockEnabled = this.getUser.stockEnabled
            this.other.invoiceGradientEnabled = this.getUser.invoiceGradientEnabled
            this.other.invoiceViewAlertEnabled = this.getUser.invoiceViewAlertEnabled
            this.other.invoiceViewDueEnabled = this.getUser.invoiceViewDueEnabled

            //
            this.smsSender = this.getUser.smsSender
            this.smsSender.sms = this.getUser.sms
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            goBack() {
                this.$router.back()
            },
            validateFname() {
                if (this.form.firstname.length < 3){
                    this.fn.type = 'is-danger';
                    this.fn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.firstname.length >= 3){
                    this.fn.type = 'is-success';
                    this.fn.message =''
                    return true
                }
            },
            validateLname() {
                if (this.form.lastname.length < 3){
                    this.ln.type = 'is-danger';
                    this.ln.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.lastname.length >= 3){
                    this.ln.type = 'is-success';
                    this.ln.message =''
                    return true
                }
            },
            validateSender() {
                if (this.smsSender.sender.length < 3){
                    this.sn.type = 'is-danger';
                    this.sn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.smsSender.sender.length >= 3 && this.smsSender.sender.length <= 11){
                    this.sn.type = 'is-success';
                    this.sn.message =''
                    return true
                }
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)

                if (reg.test(this.form.phone)) {

                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.phone.length > 8 && this.form.phone.length < 11){
                        this.cn.type = 'is-success';
                        this.cn.message =''
                        return true
                    } else {
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a contact number'
                        return false
                    }
                }


            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSelect({name, iso2, dialCode}) {
                this.form.code = "+"+dialCode
                // console.log(name, iso2, dialCode);
                // this.form.country = name.split(" ")[0]
                // this.form.currency = iso2
            },
            onSelect2({name, iso2, dialCode}) {
                this.form.code2 = "+"+dialCode
                // console.log(name, iso2, dialCode);
                // this.form.country = name.split(" ")[0]
                // this.form.currency = iso2
            },
            onSubmit(event) {
                // validate
                if ( !this.validateContact()){
                    return
                }

                this.isLoading = true
                //
                this.form.business = this.getUser.business
                // ajax
                this.axios.post('/user/account', this.form).then(response=>{
                    
                    //
                    this.isLoading = false
                    // 
                    this.$buefy.toast.open(response.data)

                    // 
                    userService.getAll().then(() => {
                        // update
                        this.$store.dispatch('auth/updateUser')
                    })

                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })
                // alert(JSON.stringify(this.form))
            },
            saveSMSsender() {
                if (this.smsSender.approved == false || this.smsSender.approved == null) {
                    //
                    if ( !this.validateSender()){
                        return
                    }

                    this.isLoading = true
                    //
                    const form = {
                            business: this.getUser.business,
                            sender: this.smsSender.sender
                    }
                    
                    // ajax
                    this.axios.post('/user/sender', form).then(response=>{
                        
                        // 
                        this.$buefy.toast.open(response.data)

                        this.isLoading = false
                        // 
                        userService.getAll().then(() => {
                            // update
                            this.$store.dispatch('auth/updateUser')
                        })

                    }).catch(error=>{
                        //
                        this.isLoading = false
                        //
                        console.log(error)
                        //
                        this.$buefy.toast.open(error.response.data)
                    })

                }
            },
            submitSettings() {

                    this.isLoading = true
                    
                    this.other.business = this.getUser.business
                    // ajax
                    this.axios.post('/user/settings', this.other).then(response=>{
                        
                        // 
                        this.$buefy.toast.open(response.data)

                        //
                        this.isLoading = false

                        // 
                        userService.getAll().then(() => {
                            // update
                            this.$store.dispatch('auth/updateUser')
                        })

                    }).catch(error=>{
                        //
                        this.isLoading = false
                        //
                        console.log(error)
                        //
                        this.$buefy.toast.open(error.response.data)
                    })
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .link:hover, .link:focus {
        background: grey;
        background: #f4f4f4;
        border-radius: 1.2rem;
    }
</style>