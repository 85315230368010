<template>
    <div>
        <div class="p-4" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            <div class="columns is-mobile">
                    <div class="column is-half">
                        <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather> 
                        <p class="px-3 py-2 pb-0 has-text-weight-bold ">Brands  </p>
                    </div>
                    <div class="column py-4">
                        <b-button @click="isModalActive = true" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> Add  </b-button>
                       
                    </div>
            </div>

                <div class="mb-5 py-2" style="max-width:460px;margin:auto">
                        <b-field label="">
                            <b-input autocomplete="0" @input="Search" @keyup.native="load" :loading="loading" v-model="searchString" placeholder="Search invoices "></b-input>
                        </b-field>
                </div>


                <BrandTemplate :del="del" :edit="edit" :brands="result" v-if="result.length > 0" class="pb-5"></BrandTemplate>
                <BrandTemplate :del="del" :edit="edit" v-else :brands="getBrands"  class="pb-5"></BrandTemplate>

                <b-modal v-model="isModalActive"  scroll="keep">
            <div class="card">
                <div class="card-content">

                    <div class="p-3">
                        <h4 class="title is-5">{{ ref ? 'Edit' : 'Add' }} Brand</h4>
                        <p class="subtitle">Fill form below</p>
                    </div>
                    <div class="p-3">
                        <div class="columns is-mobile ">
                            <div class="column">
                                <b-field :type="ct.type" :message="ct.message">
                                        <b-input  @input="validateName()" v-model="form.brand" placeholder="Brand"></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <b-field class="pb-5 mb-3">
                                <b-button @click="onSubmit"  class="is-submit-button" expanded type="is-primary">Submit</b-button>
                            </b-field>
                    </div>
                </div>
            </div>
        </b-modal>
        
            <div>

        </div>
        
            </div>
        <MainFooter />
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import moment  from 'moment';
    
    import MainFooter from '../components/footer'
    import InvoiceTemplate from '../components/invoiceTemplate.vue'
    import BrandTemplate from '../components/brandTemplate.vue'

    export default {
        name: "BrandPage",
        data() {
            return {
                isModalActive: false,
                ref: null,
                due: null,
                loading: false,
                searchString: '',
                isSwitchedCustom: false,
                selected: [],
                screenWidth: 0,
                result: [],
                form: {
                    business: '',
                    brand:''
                },
                ct: {
                    type:'',
                    message:'',
                },
            }
        },
        components: {
            MainFooter,
            // InvoiceTemplate,
            BrandTemplate
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            // ...mapGetters('invoices', ['getInvoices']),
            ...mapGetters('items', ['getBrands']),
        },
        watch: {
            searchString() {
                if (this.searchString.length == 0) {
                    this.result = []
                }
            }
        },
        mounted() {

            this.updateScreenWidth();
            this.onScreenResize();
            //
            this.fetchData();

            if (Object.keys(this.$route.params).length !== 0) {

                // console.log(this.$route.params)
                //
                this.searchString = this.$route.params.search
                this.Search()

            }

        },
        methods:{
            
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            fetchData() {
                // fetch
                this.$store.dispatch("items/getBrands", this.getUser.business);

            },
            validateName() {
                if (this.form.brand.length < 3){
                    this.ct.type = 'is-danger';
                    this.ct.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.brand.length >= 3){
                    this.ct.type = 'is-success';
                    this.ct.message =''
                    return true
                }
            },
            onSubmit(event) {
                // validate
                if ( !this.validateName() ){
                // if ( (!this.form.client || (!this.validateName() && !this.validateContact())) || !this.form.items.reduce((a, b) => a + b.total, 0) > 0 ){
                    return
                }

                this.isLoading = true

                //
                this.form.business = this.getUser.business
                
                let method,url
                //
                if (this.ref) {
                    url = '/item/brand/'+this.ref
                    method = axios.patch
                } else {
                    url = '/item/brand'
                    method = axios.post
                }
                
                // ajax
                method(url, this.form).then(response=>{
                    //
                    console.log(response.data)
                    this.fetchData()
                    this.isModalActive = false
                    this.ref = null
                    // 
                    this.$buefy.toast.open(response.data)

                    this.isLoading = false

                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // alert(JSON.stringify(this.form))
            },
            async Search() {
                // console.log('here')
                if (this.searchString.length >= 2) {
                    
                   
                    const result = await this.$store.getters['items/searchBrands'](this.searchString)

                    this.result = result
                    
                }
            },
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            },
            goBack() {
                this.$router.back()
            },
            goHome() {
                //
                this.$router.push('/')
            },
            edit(data) {
                //
                this.ref = data.brand
                this.form.brand = data.brand

                this.isModalActive = true
                // this.$router.push({ name: 'add_item', 
                //                     params: { item: data }
                //                 })
            },
            del(data) {
                this.$buefy.dialog.confirm({
                    // title: 'Delete Payment?',
                    message: `<b>Delete Brand: ${data.brand} ?</b><br> Are you sure you want to <b>delete</b> this brand? This action cannot be undone.`,
                    confirmText: 'Delete',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        
                        //
                        axios.delete('/item/brands/'+this.getUser.business+'/'+data.brand).then((res)=>{
                            //
                            // this.invoice = res.data[0];
                            // this.pay.brands[0].total = res.data[0].total;

                            // this.isLoading = false
                            this.$buefy.toast.open(res.data)

                            this.fetchData()

                            // this.checkShop()
                            // this.checkVisit()
                        })
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "vue-select/dist/vue-select.css";

.vs__selected-options input[class] {
    padding: 0.55rem;
}

.vs__selected {
    padding: 0.55rem;
}

.vs__deselect {
    margin: 6px;
    margin-left: 8px;
}

// .columns .column {
//     padding: 0.5rem !important;
// }
</style>