<template>
    <div :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">

        <mainTemp v-if="checkActiveTheme('main')" :theme="theme" :setTheme="setTheme" :themeType="themeType" :print="print" :invoice="invoice" :client="client" :renderMail="renderMail" :getUser="getUser"></mainTemp>
        <classicTemp v-if="checkActiveTheme('classic')" :theme="theme" :setTheme="setTheme" :themeType="themeType" :print="print" :invoice="invoice" :client="client" :renderMail="renderMail" :getUser="getUser"></classicTemp>
        <miniTemp v-if="checkActiveTheme('mini')" :theme="theme" :setTheme="setTheme" :themeType="themeType" :print="print" :invoice="invoice" :client="client" :renderMail="renderMail" :getUser="getUser"></miniTemp>

        <b-modal v-model="isMailModalActive"  scroll="keep">
            <div class="card">
                <div class="card-content">

                    <div class="p-3">
                        <h4 class="title is-5">Send Mail</h4>
                        <p class="subtitle">Fill form below</p>
                    </div>
                    <div class="p-3">
                        <div class="mb-4">
                            <!-- <p v-if="client.email" class="has-text-weight-">To: <span class="has-text-primary"> {{ client.email }}</span></p> -->
                            <!-- <p class="">Cc: <span class="has-text-primary"> {{ getUser.bemail }} </span></p> -->
                        </div>
                        <div class="columns is-mobile ">
                            <div class="column">
                                <b-field>
                                    <b-input v-model="cc" placeholder="Cc"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns is-mobile ">
                                <div class="column">
                                    <b-field>
                                        <b-input v-model="bcc" placeholder="Bcc"></b-input>
                                    </b-field>
                                </div>    
                        </div>
                        <div class="columns is-mobile ">
                                <div class="column">
                                    <b-field >
                                        <b-input type="textarea" rows="4" v-model="message" placeholder="Kindly find the invoice link below. View invoice and make payment. Thank you! "></b-input>
                                    </b-field>
                            </div>
                        </div>

                            <b-field class="pb-5 mb-3">
                                <b-button @click="sendMail"  class="is-submit-button" expanded type="is-primary">Send</b-button>
                            </b-field>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="isThemeModalActive"  scroll="keep">
            <div class="card">
                <div class="card-content">

                    <div class="p-3">
                        <h4 class="title is-5">Select Invoice Theme</h4>
                        <p class="subtitle">Select below</p>
                    </div>
                    <div class="p-3">
                        <div class="mb-4">
                            <!-- <p v-if="client.email" class="has-text-weight-">To: <span class="has-text-primary"> {{ client.email }}</span></p> -->
                            <!-- <p class="">Cc: <span class="has-text-primary"> {{ getUser.bemail }} </span></p> -->
                        </div>
                        <div class="columns is-mobile ">
                            <div class="column">
                                <b-field >
                                        <b-select expanded class="is--right" placeholder="Theme select" v-model="theme">
                                            <option
                                                v-for="option in themeType"
                                                :value="option"
                                                :key="option">
                                                {{ option[0].toUpperCase()+option.substring(1) }} Theme
                                            </option>
                                        </b-select>
                                    </b-field>
                            </div>
                            
                        </div>

                            <b-field class="pb-5 mb-3">
                                <b-button @click="saveTheme"  class="is-submit-button" expanded type="is-primary">Save</b-button>
                            </b-field>
                    </div>
                </div>
            </div>
        </b-modal>
        

        <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        <!-- <MainFooter/> -->
    </div>
</template>

<script>

    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import MainFooter from '../components/footer'
    import mainTemp from '../components/theme/main.vue'
    import classicTemp from '../components/theme/classic.vue'
    import miniTemp from '../components/theme/mini.vue'
    import moment  from 'moment';
    

    export default {
        name: "CreateInvoicePage",
        data(){
            return {
                isMailModalActive: false,
                isThemeModalActive: false,
                isGradient: false,
                isEmpty: false,
                isBordered: false,
                isStriped: false,
                isNarrowed: false,
                isHoverable: false,
                isFocusable: false,
                hasMobileCards: false,
                isLoading: false,
                screenWidth: 0,
                clientView: false,
                message: '',
                cc: '',
                bcc: '',
                theme: null,
                themeType: ['main', 'classic', 'mini' ],
                invoice: {
                    id: '',
                    ref: '',
                    client: '',
                    business: '', 
                    type: '', 
                    items: '',
                    total: 0, 
                    paid: '',
                    discount: '',
                    date: '',
                    due: '',
                    dueDate: '',
                    notes: 0,
                    color: 1,
                    user: 1,
                    status: 1,
                },
                client: {
                    name: '',
                    phone: '',
                    email: '',
                    address: '',
                },
                item: {
                    category: '',
                    description: '',
                    rate: 0,
                    qty: 0,
                    total: 0,
                },
                columns: [
                    // {
                    //     field: 'id',
                    //     label: 'ID',
                    //     width: '40',
                    //     numeric: true
                    // },
                    {
                        field: 'description',
                        label: 'Description',
                    },
                    {
                        field: 'rate',
                        label: 'Rate',
                    },
                    {
                        field: 'qty',
                        label: 'Qty',
                    },
                    {
                        field: 'total',
                        label: 'Total',
                    }
                ],
                InvoiceType: ['Invoice', 'Pro forma'],
                bn: {
                    type:'',
                    message:'',
                },
                cn: {
                    type:'',
                    message:'',
                },
                options: [
                    {
                        label: 'Select Client',
                        code: null
                    }
                ],
            }
        },
        components: {
            // MainFooter,
            mainTemp,
            classicTemp,
            miniTemp
        },
        computed: {
            //
            ...mapGetters('clients', ['getClients']),
            ...mapGetters('invoices', ['generateLinkHash']),
            ...mapGetters('auth', ['getUser']),
            
        },
        watch: {
            dueValue() {
                //
                this.form.due = this.dueValue

                if (this.dueValue && parseInt(this.dueValue)) {

                    console.log(moment((this.form.date ? new Date(this.form.date) : new Date())).add(parseInt(this.form.due), 'days').toDate())

                    this.form.dueDate = moment((this.form.date ? new Date(this.form.date) : new Date())).add(parseInt(this.form.due), 'days').toDate()
                } else {
                    this.form.dueDate = this.form.date ? new Date(this.form.date) : new Date()
                }
            },
            getClients() {

                if (this.getClients) {
                    let options = []

                    this.getClients.forEach(v=>{
                        options.push({ label: v.name, code: v.name })
                        // options.push({ label: v.name+' '+v.phone.countryCode+v.phone.tel, code: v.name })
                    })
                    //
                    this.options = this.options.concat(options)
                }
            },
            theme() {
                //
                
            }
        },
        mounted() {
            
            this.updateScreenWidth();
            this.onScreenResize();
            this.fetchData()

            //
            if (Object.keys(this.$route.query).length !== 0) {

                // console.log()

                const ref = this.$route.query.ref.substring(1)
                const url = '/invoices/'+this.getUser.business+'/'+ref

                //
                axios.get(url).then((res)=>{
                    //
                    this.invoice = res.data[0];
                    this.theme = this.invoice.theme

                    this.isLoading = false

                    // this.checkShop()
                    // this.checkVisit()
                })

            // this.$store.dispatch('shops/getShop', { shop: this.$route.query.shop, type:"shop" } ).then(()=>{
            //     //
            //     console.log(this.$store.getters['shops/getShops'][0])
            //     //
            //     this.invoice = this.$store.getters['shops/getShops'][0]
            // })
            }

            else if (Object.keys(this.$route.params).length !== 0) {

                // console.log(this.$route.params)

                const data = {
                        link: 'view_invoice',
                        data: this.$route.params
                    }

                this.invoice.id = this.$route.params.id
                this.invoice.ref = this.$route.params.ref
                this.invoice.client = this.$route.params.client
                this.invoice.business = this.$route.params.business
                this.invoice.type = this.$route.params.type
                this.invoice.items = this.$route.params.items
                this.invoice.total = this.$route.params.total
                this.invoice.paid = this.$route.params.paid
                this.invoice.discount = this.$route.params.discount
                this.invoice.date = this.$route.params.date
                this.invoice.due = this.$route.params.due
                this.invoice.dueDate = this.$route.params.dueDate
                this.invoice.notes = this.$route.params.notes
                this.invoice.color = this.$route.params.color
                this.invoice.gradientColor = this.$route.params.gradientColor
                this.theme = this.$route.params.theme
                this.invoice.user = this.$route.params.user
                this.invoice.status = this.$route.params.status

                this.$store.dispatch('pages/setPage', data)

                this.isLoading = false

                // this.checkShop()
                // this.checkVisit()
            }
            else {

                const data = this.$store.getters['pages/getPage']('view_invoice')

                if (data !== null) {
                        // this.form = data.search
                        this.invoice.id = data.id
                        this.invoice.ref = data.ref
                        this.invoice.client = data.client
                        this.invoice.business = data.business
                        this.invoice.type = data.type
                        this.invoice.items = data.items
                        this.invoice.total = data.total
                        this.invoice.paid = data.paid
                        this.invoice.discount = data.discount
                        this.invoice.date = data.date
                        this.invoice.due = data.due
                        this.invoice.dueDate = data.dueDate
                        this.invoice.notes = data.notes
                        this.invoice.color = data.color
                        this.invoice.gradientColor = data.gradientColor
                        this.theme = data.theme
                        this.invoice.user = data.user
                        this.invoice.status = data.status
                }

                this.isLoading = false

                // this.checkShop()
                // this.checkVisit()
            }

            axios.get('/clients/'+this.getUser.business+'/'+this.invoice.client).then((res)=>{
                //
                this.client = res.data[0];

                this.isLoading = false

                // this.checkShop()
                // this.checkVisit()
            })
        },
        methods: {
             
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            fetchData() {
                this.$store.dispatch('clients/getClients', this.getUser.business)
            },
            validateName() {
                if (this.form.name.length < 3){
                    this.bn.type = 'is-danger';
                    this.bn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.name.length >= 3){
                    this.bn.type = 'is-success';
                    this.bn.message =''
                    return true
                }
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)

                if (reg.test(this.form.phone)) {

                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.phone.length > 8 && this.form.phone.length < 11){
                        this.cn.type = 'is-success';
                        this.cn.message =''
                        return true
                    } else {
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a contact number'
                        return false
                    }
                }


            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                // if ( !this.validateFname() || !this.validateLname() || !this.validateEmail() || !this.validateGender || !this.validateDOB){
                // if ( (!this.form.client || (!this.validateName() && !this.validateContact())) || !this.form.items.reduce((a, b) => a + b.total, 0) > 0 ){
                //     return
                // }

                this.isLoading = true

                console.log(this.form)

                //
                this.form.business = this.getUser.business
                this.form.total = this.genDis( (this.form.discount ? this.form.discount : 0),  this.form.items.reduce((a, b) => a + b.total, 0) )
                
                // ajax
                axios.post('/invoice', this.form).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)
                    //
                    // this.$router.push({ name: 'view_invoice', params: { ref: response.data.ref } })


                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // alert(JSON.stringify(this.form))
            },
            renderMail() {
                this.isMailModalActive = true
            },
            sendMail() {
                //
                this.isLoading = true

                const invoice = {
                    type: this.invoice.type,
                    ref: this.invoice.ref,
                    due: this.invoice.due,
                    date: this.invoice.date,
                    dueDate: this.invoice.dueDate,
                    total: this.invoice.total,
                    paid: this.invoice.paid
                }

                const client = {
                    name: this.client.name,
                    email: this.client.email,
                }

                const form = {
                    cc: this.bcc,
                    bcc: this.cc,
                    business: this.getUser.business,
                    currency: this.getUser.country.currency,
                    email: this.getUser.bemail,
                    invoice: invoice,
                    client: client,
                    message: this.message
                }

                // ajax
                axios.post('/invoices/send', form).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)
                    //
                    // this.$router.push({ name: 'view_invoice', params: { ref: response.data.ref } })
                    this.isLoading = false
                    this.isMailModalActive = false


                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })
            },
            saveTheme() {
                //
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            },
            onSelect({name, iso2, dialCode}) {
                this.form.code = "+"+dialCode
                // console.log(name, iso2, dialCode);
                // this.form.country = name.split(" ")[0]
                // this.form.currency = iso2
            },
            calcTot(item) {
                item.total = Math.round( item.qty * item.rate * 1000)/1000
            },
            addItem() {

                let item = {}
                item.category = '' 
                item.description = '' 
                item.qty = null
                item.rate = null
                item.total = null

                this.form.items.push(item)
            },
            removeItem(index){

                if (this.form.items.length > 1) {
                    this.form.items.splice(index,1)
                }
            },
            genDis(discount, amount) {
                return Math.round( ((100 - discount)/100 * amount) * 1000 )/1000
            },
            Discount(discount, amount) {
                return Math.round( ((discount)/100 * amount) * 1000 )/1000
            },
            print() {
                window.print()
            },
            goBack() {
                this.$router.back()
            },
            goHome() {
                //
                this.$router.push('/')
            },
            checkActiveTheme(name) {
                return this.theme == name
            },
            selectTheme() {
                this.isThemeModalActive = true
            },
            setTheme(theme) {
                this.theme = theme

                axios.patch('/invoice/theme/'+this.invoice.ref.substring(1), { business: this.invoice.business, theme: this.theme,  }).then(response=>{
                        //
                        console.log(response.data)
                        // 
                        // this.$buefy.toast.open(response.data)
                        //
                        // this.$router.push({ name: 'view_invoice', params: { ref: response.data.ref } })


                    }).catch(error=>{
                        //
                        this.isLoading = false
                        //
                        console.log(error)
                        //
                        this.$buefy.toast.open(error.response.data)
                    })
            }
        }
    }
</script>

<style lang="scss" >
// @import "vue-select/dist/vue-select.css";
@import '@/assets/scss/custom_variables.scss';


// .table thead {
//     background: $primary !important;
// }

// .table thead th{
//     border-color: $primary !important;
// }

.table thead th span {
    // color: white !important;
    font-size: 0.85rem;
    font-weight: 800;
    color: black !important;
    // color: $primary !important;
}

.bg{
    background: $primary !important;
    background-color: $primary !important;
}

.gradient {
    background-image: linear-gradient(160deg, #06067d 25%, $primary 100%);
}
</style>