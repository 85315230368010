import { render, staticRenderFns } from "./items.vue?vue&type=template&id=4684669a&scoped=true"
import script from "./items.vue?vue&type=script&lang=js"
export * from "./items.vue?vue&type=script&lang=js"
import style0 from "./items.vue?vue&type=style&index=0&id=4684669a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4684669a",
  null
  
)

export default component.exports