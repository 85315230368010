<template>
    <div>
        <!-- <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading> -->
        <div class=" p-4"  :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            
            <div class="columns is-mobile">
                    <div class="column is-half">
                         
                        <!-- <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather>  -->
                        <p class="px-3 py-2 pb-0 has-text-weight-bold ">Expenses </p>
                    </div>
                    <div class="column py-4">
                        <b-button @click="goto('add_expense')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> Add  </b-button>
                        <!-- <b-field class="report is-pulled-right">
                            <b-select size="is-small" placeholder="Select a range" v-model="due">
                                <option
                                    v-for="option in dueRange"
                                    :value="option"
                                    :key="option">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>   -->
                    </div>
            </div>


            <div class="content p-2 text-center">
                        <div class="columns is-mobile is-multiline">
                            <div class="column card p-4 m-1">
                                <p class="small has-text-weight-medium">Expense this month</p>
                                <h4 class="title mb-1 is-4 has-text-weight-bold"> {{ getUser.country.currency+mons.toFixed(2) }} </h4>
                            </div>
                            <div class="column card p-4 m-1">
                                <p class="small has-text-weight-medium">All Expenses</p>
                                <h4 class="title mb-1 is-4 has-text-weight-bold">{{ getUser.country.currency+allexps .toFixed(2)}}</h4>
                            </div>
                        </div>
            </div>

            <!-- <div class="p-1">
                <b-field  >
                    <b-input  autocomplete="0" v-model="searchString" placeholder="Search"></b-input>
                </b-field>
            </div> -->

                <div class="mb-5 py-2" style="max-width:460px;margin:auto">
                        <b-field label="">
                            <b-input autocomplete="0" @input="Search" @keyup.native="load" :loading="loading" v-model="searchString" placeholder="Search invoices "></b-input>
                        </b-field>
                </div>


            <ExpenseTemplate :del="del" :expenses="result" v-if="result.length > 0" class="pb-5"></ExpenseTemplate>
            <ExpenseTemplate :del="del" v-else :expenses="getExpenses"  class="pb-5"></ExpenseTemplate>
            
            <div hidden class="p-1 py-4">
                <a class="columns is-mobile mb-0 p-1" >
                    <!-- <div class="column is-one-fifth">
                        <figure class="image is-64x64 pt-1">
                            <img src="https://bulma.io/images/placeholders/128x128.png" alt="Image">
                        </figure>
                    </div> -->
                    <div class="column is-two-thirds">
                        <div class="p-2 ">
                            <h4 class=" has-text-dark has-text-weight-medium  mb-1">Johnson & Johnson</h4>
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-2">{{ getUser.country.currency }}750.00 
                            <!-- <span class="small">&#8212;</span> 
                            <span class="has-text-success small"> 750.00 </span><br> -->
                            <span class="ml-1 has-text-primary is-size-7 has-text-weight-bold mb-2"> #INV00085 </span>
                            </p>
                            
                            <b-tag class="has-text-weight-semibold"> On Receipt</b-tag>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <div class="pt-2">
                            <b-tag type="is-primary  mb-2 mt-1">Paid</b-tag>
                            <p class="small has-text-dark mb-2 ">Bill</p>
                            <p class="has-text-dark small has-text-weight-medium"> Jun 12, 2023 </p>
                        </div>
                    </div>
                </a>
                <hr class="my-1 mb-4">
                <a class="columns is-mobile mb-0 p-1" >
                    <!-- <div class="column is-one-fifth">
                        <figure class="image is-64x64 pt-1">
                            <img src="https://bulma.io/images/placeholders/128x128.png" alt="Image">
                        </figure>
                    </div> -->
                    <div class="column is-two-thirds">
                        <div class="p-2 ">
                            <h4 class=" has-text-dark has-text-weight-medium  mb-2">Kofikrom Farms </h4>
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-1">{{ getUser.country.currency }}750.00 
                            <span class="small">&#8212;</span> 
                            
                            <span class="has-text-danger small"> (750.00) </span><br>
                            <!-- <span class="ml-1 has-text-primary is-size-7 has-text-weight-bold mb-2"> #INV00085 </span> -->
                            </p>
                            <b-tag class="has-text-weight-semibold"> in 30 days - Jul 11, 2023</b-tag>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <div class="pt-2">
                            <b-tag type="is-primary  mb-2 mt-1">Paid</b-tag>
                            <p class="small has-text-dark mb-2 ">Invoice </p>
                            <p class="has-text-dark small has-text-weight-medium"> Jun 12, 2023 </p>
                        </div>
                    </div>
                </a>
            </div>

            

        </div>
        <MainFooter />

    </div>
</template>

<script>
    import axios from 'axios'
    import { mapGetters, mapState } from "vuex"

    import MainFooter from '../components/footer'
    import ExpenseTemplate from '../components/expenseTemplate.vue'


    export default {
        name: "GiftPage",
        data() {
            return {
                loading: false,
                searchString: '',
                business: '',
                businessData: null,
                isOpen: true,
                isLoading: false,
                screenWidth: 0,
                mons: 0,
                allexps: 0,
                result: [],
            }
        },
        components: {
            MainFooter,
            ExpenseTemplate
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            ...mapGetters('expenses', ['getExpenses']),
            // ...mapGetters('payments', ['getPayments']),
        },
        watch: {
            getExpenses() {
                if (this.getExpenses) {
                    //
                    this.mons = this.getExpenses.filter( v => v.date.includes((new Date()).toISOString().substring(0,7))).reduce((a, b) => a + b.amount, 0);

                    // const total = this.getInvoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && moment(new Date(v.dueDate)).diff(moment(new Date), 'days') < 0).reduce((a, b) => a + b.total, 0);

                    // const paid = this.getInvoices.filter(v=> v.type == 'Invoice' && ["part payment", "pending"].includes(v.status) && moment(new Date(v.dueDate)).diff(moment(new Date), 'days') < 0).reduce((a, b) => a + b.paid, 0);

                    this.allexps = this.getExpenses.filter( v => v.date.includes((new Date()).toISOString().substring(0,4))).reduce((a, b) => a + b.amount, 0);

                    
                     
                }
            },
            searchString() {
                if (this.searchString.length == 0) {
                    this.result = []
                }
            }
        },
        mounted() {
            // this.business = this.$store.getters['cart/getCartBusiness']
            this.updateScreenWidth();
            this.onScreenResize();

            //
            this.fetchData();

            if (Object.keys(this.$route.params).length !== 0) {

                // console.log(this.$route.params)
                //
                this.searchString = this.$route.params.search
                this.Search()

            }
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            goBack() {
                this.$router.back()
            },
            goto(link) {

                if (Object.keys(this.$route.params).length !== 0) {
                    this.$router.push({ name: link, params: { ref: this.$route.params.search } })
                } else {
                    this.$router.push(link)
                }
            },
            fetchData() {
                // fetch
                // this.$store.dispatch("clients/getClients", this.getUser.business);
                // this.$store.dispatch("invoices/getInvoices", this.getUser.business);
                this.$store.dispatch("expenses/getExpenses", this.getUser.business);

            },
            async Search() {
                // console.log('here')
                if (this.searchString.length >= 2) {
                    
                   
                    const result = await this.$store.getters['expenses/searchExpenses'](this.searchString)

                    this.result = result
                    
                }
            },
            load() {
                this.loading = true 
                setTimeout(()=>{
                    this.loading = false
                }, 500)
            },
            goHome() {
                //
                this.$router.push('/')
            },
            del(data) {
                this.$buefy.dialog.confirm({
                    // title: 'Delete Expense?',
                    message: `<b>Delete Expense ?</b><br> Are you sure you want to <b>delete</b> this expense? This action cannot be undone.`,
                    confirmText: 'Delete',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {

                        if (data.invoice) {
                            axios.delete('/expense/'+this.getUser.business+'/'+data.invoice.substring(1)+'/'+data._id).then((res)=>{
                                //
                                // this.invoice = res.data[0];
                                // this.pay.items[0].total = res.data[0].total;

                                // this.isLoading = false
                                this.$buefy.toast.open(res.data)

                                this.fetchData()

                                // this.checkShop()
                                // this.checkVisit()
                            })
                        } else {
                            //
                            axios.delete('/expense/'+this.getUser.business+'/'+data._id).then((res)=>{
                                //
                                // this.invoice = res.data[0];
                                // this.pay.items[0].total = res.data[0].total;

                                // this.isLoading = false
                                this.$buefy.toast.open(res.data)

                                this.fetchData()

                                // this.checkShop()
                                // this.checkVisit()
                            })
                        }
                        
                        
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .link:hover, .link:focus {
        background: grey;
        background: #f4f4f4;
        border-radius: 1.2rem;
    }
</style>