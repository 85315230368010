import { render, staticRenderFns } from "./brands.vue?vue&type=template&id=61cc89cd&scoped=true"
import script from "./brands.vue?vue&type=script&lang=js"
export * from "./brands.vue?vue&type=script&lang=js"
import style0 from "./brands.vue?vue&type=style&index=0&id=61cc89cd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61cc89cd",
  null
  
)

export default component.exports