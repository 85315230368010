<template>
    <div class="">
        <div class="columns is-multiline">
            <div class=" column is-half-tablet " v-for="expense in expenses" :key="expense._id">

                <a class="card columns is-mobile mb-0 p-1" >

                    <div class="column is-two-thirds">
                        <div class="p-1 ">
                            <h4 class=" has-text-primary has-text-weight-semibold  mb-1"> 
                                <span class="has-text-dark "> {{ expense.purpose }} </span>  </h4>

                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-1">{{ getUser.country.currency+expense.amount.toFixed(2) }}
                            
                            </p>
                            <p  class="has-text-dark has-text-weight-medium"> <b-tag v-if="expense.category" type="is-primary" class="small mb-1 mr-2 has-text-weight-semibold">{{ expense.category }} </b-tag> <span v-if="expense.invoice">&#8212; {{ expense.invoice }}</span> </p>
                            <p  class="has-text-dark has-text-weight-medium small"> {{ expense.details }} </p>
                          
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <b-dropdown position="is-bottom-left" :triggers="['click']" :append-to-body="true" :mobile-modal="false" aria-role="list">
                    <template #trigger>
                        <div class="pt-1">
                            <b-tag :type="`${expense.mode == 'Cash' ? 'is-primary' : expense.mode == 'Mobile Money' ? 'is-warning' : 'is-info'}  mb-2 mt-1`">{{ expense.mode }}</b-tag>
                            <!-- <p class="small has-text-dark mb-1 has-text-weight-semibold has-text-primary">{{ expense.category }}</p> -->
                            <p class="has-text-dark small has-text-weight-medium"> {{ new Date(expense.date).toDateString().substr(4) }} </p>
                        </div>
                    </template>
            <b-dropdown-item @click="edit(expense)" aria-role="listitem">Edit</b-dropdown-item>
            <b-dropdown-item @click="del(expense)" aria-role="listitem">Delete</b-dropdown-item>
            <!-- <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item> -->
            <!-- <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item> -->
            </b-dropdown>
                    </div>
                </a>

                <!-- <hr class="my-1 mb-4"> -->
            </div>
        </div>
        <div class="p-4"></div>
    </div>
</template>

<script>
    
    import { mapGetters } from "vuex"
    import moment  from 'moment';

    export default {
        name: 'ExpenseTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            expenses: {
                required: true
            },
            del: {

            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            formatDate(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            // routeTo(data) {

            //     //
                
            //         //
            //         this.$router.push({ name: 'shop', 
            //                         params: { 
            //                             id: data.id,
            //                             name: data.name,
            //                             cover: data.cover,
            //                             phone: data.phone,
            //                             country: data.country,
            //                             whatsapp: data.whatsapp,
            //                             logo: data.logo,
            //                             locationName: data.locationName,
            //                             location: data.location,
            //                             rate: data.rate,
            //                             type: data.type,
            //                             website: data.website,
            //                             categories: data.categories,
            //                             available: data.available,
            //                             active: data.active,
            //                         } 
            //                     })
                

            // },
            routeToInvoice(data) {

                //
                
                    //
                    // this.$router.push({ name: 'view_expense', 
                    //                 params: { 
                    //                     id: data._id,
                    //                     ref: data.ref,
                    //                     client: data.client,
                    //                     business: data.business,
                    //                     type: data.type,
                    //                     items: data.items,
                    //                     total: data.total,
                    //                     paid: data.paid,
                    //                     discount: data.discount,
                    //                     date: data.date,
                    //                     due: data.due,
                    //                     dueDate: data.dueDate,
                    //                     details: data.details,
                    //                     color: data.color,
                    //                     user: data.user,
                    //                     status: data.status,
                    //                 } 
                    //             })
                

            },
            markAsRead(id) {
                // console.log(this.$store)
                this.$buefy.toast.open("Marked as read!");
                
                return this.$store.getters['cart/alreadyInCart'](id)
            },
            edit(data) {
                this.$router.push({ name: 'add_expense', 
                                    params: { expense: data }
                                })
            },
        } 
    }
</script>

<style lang="sass" scoped>

</style>