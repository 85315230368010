<template>
    <div class="">
        <div class="columns is-multiline">
            <div class=" column is-half-tablet " v-for="client in clients" :key="client._id">

                
                <a class="card columns is-mobile mb-0 p-1" >
                    <!-- <div class="column is-one-fifth">
                        <figure class="image is-64x64 pt-1">
                            <img src="https://bulma.io/images/placeholders/128x128.png" alt="Image">
                        </figure>
                    </div>
                    <div class="column is-half">
                        <div class="p-2 ">
                            <h4 class=" has-text-dark has-text-weight-medium  mb-1">{{ client.client }}</h4>
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-1">{{ getUser.country.currency+client.total }} 
                            </p>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <div class="pt-2">
                            <b-tag :type="`${client.status == 'paid' ? 'is-primary' : 'is-danger'}  mb-2 mt-1`">{{ client.status }}</b-tag>
                            <p class="has-text-dark small has-text-weight-medium"> {{ formatAMPM(client.date) }} </p>
                        </div>
                    </div> -->

                    <div  @click="routeTo(client)" class="column is-half">
                        <div class="p-1 ">
                            <h4 class=" has-text-primary has-text-weight-semibold  mb-1"> <span class="has-text-dark ">{{ client.name }} </span> </h4>
                            
                            
                            <p style="font-weight:700" class="medium has-text-dark has-text-weight- mb-1">{{ getUser.country.currency+client.total.toFixed(2) }}
                            
                            <!-- <span class="has-text-success small"> 750.00 </span><br> -->
                            <span class="small">|</span> 
                            <span class="ml-1 has-text-primary small has-text-weight-bold mb-2"> {{ client.paid.toFixed(2) }} </span>
                            <span class="small">|</span>
                            <span class="ml-1 has-text-danger small  has-text-weight-bold mb-2"> ({{ (client.total - client.paid).toFixed(2) }}) </span>
                            <!-- <span v-if="client.status == 'part clientment'" class="has-text-primary small"> ({{ (client.total - client.paid).toFixed(2) }}) </span>
                            <span v-else-if="client.status == 'pending' && client.type == 'Invoice'" class="has-text-danger small"> ({{ client.total.toFixed(2) }}) </span>
                            <span v-else class="ml-1 has-text-primary is-size-7 has-text-weight-bold mb-2"> {{ client.ref }} </span> -->
                            
                            </p>
                            <p  class="has-text-dark has-text-weight-medium small"> {{ client.phone.countryCode+client.phone.tel }} </p>
                            <!-- <p  class="has-text-weight-semibold has-text-dark smallpb-3"> {{ client.email }} </p> -->
                            <p  class="has-text-weight-medium has-text-grey small"> {{ client.address }} </p>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <b-dropdown position="is-bottom-left" :triggers="['click']" :append-to-body="true" :mobile-modal="false" aria-role="list">
                    <template #trigger>
                        <div class="pt-1">
                            <!-- <b-tag :type="`${client.type == 'Cash' ? 'is-primary' : client.type == 'Mobile Money' ? 'is-warning' : 'is-info'}  mb-2 mt-1`">{{ client.type }}</b-tag> -->
                            <p class="small has-text-dark mb-1  mt-1 has-text-weight-bold has-text-primary"> Invoices ({{ client.balances.length }})</p>
                            <p class="has-text-dark small has-text-weight-medium pb-1"> {{ new Date(client.date).toDateString().substr(4) }} </p>
                            <p  class="has-text-weight-semibold has-text-dark small "> {{ client.email }} </p>
                        </div>
                    </template>
            <b-dropdown-item @click="view(client)" aria-role="listitem">View</b-dropdown-item>
            <b-dropdown-item @click="edit(client)" aria-role="listitem">Edit</b-dropdown-item>
            <b-dropdown-item v-if="client.balances.length == 0" @click="del(client)" aria-role="listitem">Delete</b-dropdown-item>
            <!-- <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item> -->
            <!-- <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item> -->
            </b-dropdown>
                    </div>
                </a>

            

                <!-- <hr class="my-1 mb-4"> -->
            </div>
        </div>
        <div class="p-4"></div>
    </div>
</template>

<script>
    
    import { mapGetters } from "vuex"
    import moment  from 'moment';

    export default {
        name: 'PayTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            clients: {
                required: true
            },
            del: {
                
            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatAMPM(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            formatDate(date) {
                date =  date ? new Date(date) : new Date()
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            routeTo(data) {

                //
                this.$router.push({ name: 'invoice', 
                                    params: {  search: data.name }
                                })
                

            },
            // routeTo(data) {

            //     //
                
            //         //
            //         this.$router.push({ name: 'shop', 
            //                         params: { 
            //                             id: data.id,
            //                             name: data.name,
            //                             cover: data.cover,
            //                             phone: data.phone,
            //                             country: data.country,
            //                             whatsapp: data.whatsapp,
            //                             logo: data.logo,
            //                             locationName: data.locationName,
            //                             location: data.location,
            //                             rate: data.rate,
            //                             type: data.type,
            //                             website: data.website,
            //                             categories: data.categories,
            //                             available: data.available,
            //                             active: data.active,
            //                         } 
            //                     })
                

            // },
            routeToInvoice(data) {

                //
                
                    //
                    this.$router.push({ name: 'view_client', 
                                    params: { 
                                        id: data._id,
                                        ref: data.ref,
                                        client: data.client,
                                        business: data.business,
                                        type: data.type,
                                        items: data.items,
                                        total: data.total,
                                        paid: data.paid,
                                        discount: data.discount,
                                        date: data.date,
                                        due: data.due,
                                        dueDate: data.dueDate,
                                        details: data.details,
                                        color: data.color,
                                        user: data.user,
                                        status: data.status,
                                    } 
                                })
                

            },
            markAsRead(id) {
                // console.log(this.$store)
                this.$buefy.toast.open("Marked as read!");
                
                return this.$store.getters['cart/alreadyInCart'](id)
            },
            view(data) {
                //
                this.$router.push({ name: 'invoice', 
                                    params: {  search: data.name }
                                })
            },
            edit(data) {
                this.$router.push({ name: 'add_client', 
                                    params: { client: data }
                                })
            },
        } 
    }
</script>

<style lang="sass" scoped>

</style>