<template>
    <div>
        <div class="p-4" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            <div class="columns is-mobile">
                    <div class="column is-half">
                        <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather>
                        <p class="px-2 py-2 pb-0 has-text-weight-bold ml-5"> {{ ref ? 'Edit: ' : 'Add  Payment' }} <span v-if="ref" class="has-text-primary">{{ ref }}</span> </p>
                    </div>
                    <div class="column py-4">
                        <!-- <b-button type="is-primary" class="is-pulled-right mr-3" icon-left="" @click="clientView ? clientView = false : clientView = true" rounded size="is-small"> add client </b-button> -->
                    </div>
            </div>
            <div class="mb-5">
                <div class="pb-2">
                        <div class="columns is-mobile">
                            <!-- <div class="column is-one-third">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Invoice </p>
                            </div> -->
                            <div class="column py-4">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                <!-- <b-field class="report"> -->
                                    <!-- <b-switch v-model="graphIsSwitched" size="is-small">Area Graph</b-switch> -->
                                    <!-- <b-select expanded size="is-small" class="is--right" placeholder="Choose client" v-model="client">
                                        <option
                                            v-for="option in options"
                                            :value="option.value"
                                            :key="option.text">
                                            {{ option.text }}
                                        </option>
                                    </b-select> -->
                                    <!-- :reduce="invoice => invoice.code" -->
                                    <b-field :type="inv.type" :message="inv.message">
                                        <v-select @input="validateInvoice()" :disabled="payDisabled" v-model="invoice" :options="options" label="label"></v-select>
                                    </b-field>
                                <!-- </b-field>  -->
                            </div>
                        </div>

                        <div class="columns is-mobile mb-0">
                            <div class="column is-half">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Invoice Details </p>
                            </div>
                            <div class="column py-4">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                
                            </div>
                        </div>
                        <div>
                            <div class="columns is-mobile mb-0">
                                <div class="column">
                                    <b-field message="">
                                        <b-input :disabled="true" autocomplete="0" type="number" v-model="form.total" placeholder="Total Amount"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field message="">
                                        <b-input :disabled="true" autocomplete="0" type="number" v-model="form.balance" placeholder="Balance"></b-input>
                                    </b-field>
                                </div>
                                <!-- <div class="column">
                                    <b-field >
                                        <b-input autocomplete="0" type="number" v-model="form.discount" placeholder="Amount"></b-input>
                                    </b-field>
                                </div> -->
                            </div>
                            <div class="columns is-mobile mb-0">
                                <div class="column">
                                    <b-field >
                                        <b-select @input="payType" expanded class="is--right" placeholder="Payment Type" v-model="form.type">
                                            <option
                                                v-for="option in PayType"
                                                :value="option"
                                                :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field :type="am.type" :message="am.message">
                                        <b-input autocomplete="0" type="number" step="0.01" v-model="form.amount" @input="validateAmount()" placeholder="Pay Amount" :maxlength="maxAmount"></b-input>
                                    </b-field>
                                </div>
                            </div>

                        </div>
                        <div class="columns is-mobile mb-0">
                            <div class="column is-half">
                                <p class="px-2 py-2 pb-0 has-text-weight-bold">Pay Details </p>
                            </div>
                            <div class="column py-4">
                                <!-- <b-button @click="goto('create_invoice')" type="is-primary" class="is-pulled-right mr-3" icon-left="" rounded size="is-small"> add  </b-button> -->
                                
                            </div>
                        </div>
                        <div>
                            <div class="columns is-mobile mb-2">
                                <div class="column  is-half">
                                    <b-field label="">
                                        <b-input :disabled="true" autocomplete="0" v-model="form.client" placeholder="Client"></b-input>
                                    </b-field>
                                </div>
                                <div class="column  is-half">
                                    <b-field label="">

                                        <b-datetimepicker placeholder="Invoice Date" v-model="form.date" >
                                        </b-datetimepicker>
                                        
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field>
                                        <b-input  type="textarea" rows="3" autocomplete="0" v-model="form.details" placeholder="Pay Details"></b-input>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <b-button type="is-primary"  expanded>Add item</b-button> -->
                        <!-- <div>
                            <b-table :mobile-cards="false" :data="form.items" :columns="columns"></b-table>
                        </div> -->
                        
                            <b-field class="pb-5 mb-3">
                                <b-button @click="onSubmit"  class="is-submit-button" expanded type="is-primary">Submit</b-button>
                            </b-field>
                    </div>

                    <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
            </div>
        </div>
        <MainFooter/>
    </div>
</template>

<script>

    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import MainFooter from '../components/footer'
    import moment  from 'moment';

    export default {
        name: "AddPayPage",
        data(){
            return {
                maxAmount: null,
                ref: null,
                isLoading: false,
                screenWidth: 0,
                clientView: false,
                invoice: {
                        label: 'Select Invoice',
                        code: null,
                        total: null,
                        paid:  null,
                        client:  null
                    },
                payDisabled: false,
                form: {
                    invoice: '',
                    business: '',
                    client: null,
                    type: 'Cash',
                    total: null,
                    amount: null,
                    date: null,
                    color: null,
                    gradientColor: null,
                    details: '',
                },
                PayType: ['Cash', 'Mobile Money', 'Bank'],
                inv: {
                    type:'',
                    message:'',
                },
                cn: {
                    type:'',
                    message:'',
                },
                am: {
                    type:'',
                    message:'',
                },
                options: [
                    {
                        label: 'Select Invoice',
                        code: null,
                        total: null,
                        paid:  null,
                        client:  null
                    }
                ],
                due: [
                { 
                    name: 'Invoice Due',
                    value: null,
                 },
                 { 
                    name: 'On Receipt (POS)',
                    value: 'On Receipt',
                 },
                 { 
                    name: '7 days',
                    value: '7 days',
                 }, 
                 { 
                    name: '15 days',
                    value: '15 days',
                 }, 
                 { 
                    name: '30 days',
                    value: '30 days',
                 }, 
                 { 
                    name: '60 days',
                    value: '60 days',
                 }, 
                 { 
                    name: '90 days',
                    value: '90 days',
                 }, 
                 { 
                    name: '180 days',
                    value: '180 days',
                 },
                ],
                dueValue: 'On Receipt',
            }
        },
        components: {
            MainFooter,
        },
        computed: {
            //
            ...mapGetters('clients', ['getClients']),
            ...mapGetters('invoices', ['getInvoices']),
            ...mapGetters('auth', ['getUser'])
        },
        watch: {
            getInvoices() {
                if (this.getInvoices) {
                    let options = []
                    //  && i.status !== "paid"
                    this.getInvoices.filter(i=>i.type=="Invoice").forEach(v=>{
                        options.push({ label: v.ref+' - '+v.client+' - '+this.getUser.country.currency+v.total.toFixed(2)+' - '+(new Date(v.date).toDateString().substring(4))+' - '+(v.due ? v.due : '')+' - '+v.status, code: v.ref, total: v.total,  paid: v.paid, client: v.client, color: v.color, gradientColor: v.gradientColor })
                        // options.push({ label: v.name+' '+v.phone.countryCode+v.phone.tel, code: v.name })
                    })
                    //
                    this.options = this.options.concat(options)

                    if (Object.keys(this.$route.params).length !== 0) {
                        if (typeof(this.$route.params.pay) !== "undefined") {
                            this.invoice = this.options.find(v=>v.code == this.$route.params.pay.invoice) 
                        }
                    }

                    if (Object.keys(this.$route.params).length !== 0) {
                        if (typeof(this.$route.params.ref) !== "undefined") {
                            this.invoice = this.options.find(v=>v.code == this.$route.params.ref) 
                        }
                    }
                }
            },
            // getClients() {

            //     if (this.getClients) {
            //         let options = []

            //         this.getClients.forEach(v=>{
            //             options.push({ label: v.name, code: v.name })
            //             // options.push({ label: v.name+' '+v.phone.countryCode+v.phone.tel, code: v.name })
            //         })
            //         //
            //         this.options = this.options.concat(options)
            //     }
            // },
            invoice() {
                if (this.invoice) {
                    //
                    this.clientView = false

                    if (this.invoice.code !== null) {
                        this.form.invoice = this.invoice.code
                        //
                        this.form.client = this.invoice.client
                        this.form.color = this.invoice.color
                        this.form.gradientColor = this.invoice.gradientColor
                        this.form.total = this.invoice.total.toFixed(2)
                        this.form.balance = (this.invoice.total - this.invoice.paid).toFixed(2)
                        // this.maxAmount = (this.invoice.total - this.invoice.paid)
                        this.maxAmount = this.ref ? this.invoice.paid : (this.invoice.total - this.invoice.paid)

                    } else {
                        this.form.client = null
                        this.form.total = null
                        this.form.balance = null
                        this.maxAmount = null
                    }
                    
                } 
                else {
                    this.clientView = false
                }
            },
        },
        mounted() {
            
            this.updateScreenWidth();
            this.onScreenResize();
            this.fetchData()

            if (Object.keys(this.$route.params).length !== 0) {

                if (this.$route.params.pay) {

                const data = {
                        link: 'add_pay',
                        data: this.$route.params
                    }

                //
                // this.$route.params.invoice.items.forEach(v=>{
                //     this.form.items.push(v)
                // })

                // this.invoice = this.$route.params.pay.invoice
                this.ref = this.$route.params.pay.receipt
                this.client = this.$route.params.pay.client
                this.form.business = this.$route.params.pay.business
                this.type = this.$route.params.pay.type
                // this.invoice.total = this.$route.params.invoice.total
                this.form.amount = this.$route.params.pay.amount
                this.form.type = this.$route.params.pay.type
                this.form.date = new Date(this.$route.params.pay.date)
                // this.form.due = this.$route.params.invoice.due
                // this.invoice.dueDate = this.$route.params.dueDate
                this.form.details = this.$route.params.pay.details
                this.form.color = this.$route.params.pay.color
                this.form.gradientColor = this.$route.params.pay.gradientColor
                // this.form.color = this.$route.params.invoice.color
                // this.invoice.user = this.$route.params.user
                // this.invoice.status = this.$route.params.status

                // this.$store.dispatch('pages/setPage', data)
                this.clientDisabled = true

                this.isLoading = false

                // this.checkShop()
                // this.checkVisit()
                }
                
            }
            // if (Object.keys(this.$route.params).length !== 0) {

            //     console.log(this.$route.params.ref)
            //     console.log(this.options)

            //     setTimeout(() => {
            //         this.invoice = this.options.find(v=>v.code == this.$route.params.ref) 
            //     }, 500);

            // }
                
        },
        methods: {
            
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            fetchData() {
                this.$store.dispatch('clients/getClients', this.getUser.business)
                this.$store.dispatch('invoices/getInvoices', this.getUser.business)
            },
            validateName() {
                if (this.form.name.length < 3){
                    this.bn.type = 'is-danger';
                    this.bn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.name.length >= 3){
                    this.bn.type = 'is-success';
                    this.bn.message =''
                    return true
                }
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)

                if (reg.test(this.form.phone)) {

                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.phone.length > 8 && this.form.phone.length < 11){
                        this.cn.type = 'is-success';
                        this.cn.message =''
                        return true
                    } else {
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a contact number'
                        return false
                    }
                }


            },
            validateAmount() {
                if (this.form.amount == null) {
                    this.am.type = 'is-danger';
                    this.am.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.amount == 0) {
                    this.am.type = 'is-danger';
                    this.am.message ='Enter a valid number'
                    return false
                    
                }
                //  if (this.form.amount >= this.maxAmount){
                if (this.form.amount > this.maxAmount){
                    this.am.type = 'is-danger';
                    this.am.message ='Pay amount should not be more than the Balance: '+this.maxAmount
                    return false
                }

                 if (this.form.amount <= this.maxAmount){
                    this.am.type = 'is-success';
                    this.am.message =''
                    return true
                }
            },
            validateInvoice() {
                if (this.invoice.code == null){
                    this.inv.type = 'is-danger';
                    this.inv.message ='Select a valid invoice'
                    return false
                }

                if (this.invoice.code !== null){
                    this.inv.type = 'is-success';
                    this.inv.message =''
                    return true
                }
            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                if ( !this.validateInvoice() || !this.validateAmount() ){
                // if ( (!this.form.client || (!this.validateName() && !this.validateContact())) || !this.form.items.reduce((a, b) => a + b.total, 0) > 0 ){
                    return
                }

                this.isLoading = true

                console.log(this.form)

                //
                this.form.business = this.getUser.business
                // this.form.total = this.genDis( (this.form.discount ? this.form.discount : 0),  this.form.items.reduce((a, b) => a + b.total, 0) )
                
                let method,url
                //
                if (this.ref) {
                    url = '/payment/'+this.ref.substring(1)
                    method = axios.patch
                } else {
                    url = '/payment'
                    method = axios.post
                }
                
                // ajax
                method(url, this.form).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)
                    const ref = response.data.split(" ")[2]
                    //
                    this.$router.push({ name: 'payment', query: { ref: ref } })

                    this.isLoading = false

                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            },
            onSelect({name, iso2, dialCode}) {
                this.form.code = "+"+dialCode
                // console.log(name, iso2, dialCode);
                // this.form.country = name.split(" ")[0]
                // this.form.currency = iso2
            },
            calcTot(item) {
                item.total = Math.round( item.qty * item.rate * 1000)/1000
            },
            addItem() {

                let item = {}
                item.category = '' 
                item.description = '' 
                item.qty = null
                item.rate = null
                item.total = null

                this.form.items.push(item)
            },
            removeItem(index){

                if (this.form.items.length > 1) {
                    this.form.items.splice(index,1)
                }
            },
            genDis(discount, amount) {
                return Math.round( ((100 - discount)/100 * amount) * 1000 )/1000
            },
            Discount(discount, amount) {
                return Math.round( ((discount)/100 * amount) * 1000 )/1000
            },
            
            goBack() {
                this.$router.back()
            },
            goHome() {
                //
                this.$router.push('/')
            },
            payType() {
                if (this.form.type == 'Pro forma') {
                    //
                    this.dueValue = null

                } else {
                    this.dueValue = 'On Receipt'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
// @import "vue-select/dist/vue-select.css";

textarea:not([rows]) {
    max-height: 40em;
    min-height: 5em !important;
}

.vs__selected-options input[class] {
    padding: 0rem !important;
    font-size: 0.9rem !important;
}

.vs__selected {
    padding: 0rem !important;
    font-size: 0.9rem !important;
}

.vs__deselect {
    margin: 3px;
    margin-left: 5px;
    font-size: 0.9rem !important;
}
</style>