<template>
    <div>
            <div class="pb-4">
                <div class=" columns is-multiline" style="">
                    <div v-for="alert in (showOnlyUnread ? alerts.filter((a)=> a.read == false) : alerts)" :key="alert._id" class=" column is-mobile is-half-tablet is-one-third-desktop">

                        <a class="p-2 card columns  is-mobile mb-0" >
                            <!-- <div @click="routeTo(alert)" class="column is-2 is-flex is-justify-content-center is-align-items-center">
                                <b-button outlined :type="alert.read ? 'is-dark' : 'is-primary'" :icon-left="alert.icon"></b-button>
                                
                            </div> -->
                            <div @click="routeTo(alert)" class="column is-two-thirds">
                                <div class="p-1 ">
                                    <p class="small list-title has-text-weight-medium">{{ alert.message }} <span v-if="alert.params" class="small">&#8212;</span> <span class="has-text-primary is-size-7 has-text-weight-bold" v-if="alert.params">{{ alert.params }}</span> </p>
                                    <!-- <p class="has-text-grey small my-1"> {{ alert.date.substr(3) }} </p> -->
                                    <!-- <span class="small has-text-dark mb-1 mr-2">{{ alert.type[0].toUpperCase()+alert.type.substring(1) }}</span> -->
                                    <div>
                                        <p class="mt-2">
                                            <feather v-if="alert.read" :type="alert.icon" class="mt-1 mr-2" size="14"></feather>
                                            <feather v-else :type="alert.icon" class="mt-1 mr-2 has-text-primary" size="14"></feather>
                                        <b-tag class="has-text-weight-semibold">{{ alert.type[0].toUpperCase()+alert.type.substring(1) }} &#8212; Alert</b-tag>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="column pb-1 has-text-right">
                                <b-dropdown position="is-bottom-left" :triggers="['click']" :append-to-body="true" :mobile-modal="false" aria-role="list">
                    <template #trigger>
                                    <b-tag @dblclick="markAs(alert)" :type="`${alert.read ? 'is-dark' : 'is-primary' }`" :class="`mb-1 mt-1 has-text-weight-medium`">{{ alert.read ? 'Read' : 'Not Read' }}</b-tag>
                                    <br>
                                    <p class="has-text-dark has-text-weight-medium small my-1"> {{ alert.date.substr(3) }} </p>
                                    <br>
                                </template>
            <b-dropdown-item @click="markAs(alert)" aria-role="listitem">Mark as {{ !alert.read ? 'Read' : 'Not Read' }}</b-dropdown-item>
            <!-- <b-dropdown-item @click="edit(brand)" aria-role="listitem">Edit</b-dropdown-item> -->
            <b-dropdown-item @click="del(alert)" aria-role="listitem">Delete</b-dropdown-item>
            <!-- <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item> -->
            <!-- <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item> -->
            </b-dropdown>
                                <!-- <feather v-if="!alert.read" size="20" class="has-text-primary" type="check-square"></feather> -->
                                <!-- <feather v-else size="20" type="check-square"></feather> -->
                            </div>
                        </a>

                        <!-- <hr class="m-0 p-0 mb-0"> -->
                    </div>
                </div>
            </div>
        <div v-if="!showOnlyUnread" class="p-4"></div>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapGetters } from "vuex"

    export default {
        name: 'AlertTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0
            }
        },
        props: {
            alerts: {
                required: true
            },
            showOnlyUnread: {
                type: Boolean,
                default: true
            },
            del: {

            }
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            routeTo(data) {
                //
                if (this.showOnlyUnread) {
                    this.markAs(data)
                }
                
                //
                if (data.link) {
                    //
                    if (data.params) {
                        this.$router.push({ name: data.link, 
                                    params: { ref: data.params, search: data.params } 
                                })
                    } else {
                        this.$router.push(data.link)

                    }
                    // this.$router.push({ name: data.link, 
                    //                 params: {
                    //                     id: data.id,
                    //                     message: data.message,
                    //                     type: data.type, 
                    //                     icon: data.icon,
                    //                     date: data.date,
                    //                     link: data.link,
                    //                 } 
                    //             })
                }

            },
            markAs(alert) {
                // console.log(this.$store)
                //
                // alert
                // alert.read = alert.read == false ? true : false
                axios.patch('/alert/read', { business: alert.business, id: alert._id, read: alert.read == false ? true : false }).then(response=>{
                    //
                    if (response) { 
                        this.$buefy.toast.open(`Marked as ${alert.read == false ? "read!" : "unread!"}`);

                        //
                        this.$store.dispatch("alert/getAlert");
                    }
                }).catch(error=>{
                    this.$buefy.toast.open(error.response.data);
                })
                
                
            },
        } 
    }
</script>

<style lang="scss" scoped>// custom
    @import '@/assets/scss/custom_variables.scss';
    
    .list-title {
        font-size: 0.95rem;
    }

    .columnxs {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .modal-close {
        background: gray !important;
    }

    // .inCart {
        // border-left: 4px solid $primary;
    // }

    //  .modal-content {
    //     height: 90% !important;
    //     border-radius: 20px;
    //     margin-top: 20% !important;
    // }

    .order-cover {
        position: fixed;
        z-index: 50;
        width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        margin-bottom: 5px;
        bottom: 10px;
    }
</style>