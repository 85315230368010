import Vue from "vue";
import Vuex from "vuex";

import { alert } from './modules/alert'
import { auth } from './modules/auth'
import { items } from './modules/items'
import { products } from './modules/products'
import { cart } from './modules/cart'
// import { orders } from './modules/orders'
// import { vendors } from './modules/vendors'
import { visits } from './modules/visits'
import { pages } from './modules/pages'
import { shops } from './modules/shops'
import { invoices } from './modules/invoices'
import { payments } from './modules/payments'
import { expenses } from './modules/expenses'
import { clients } from './modules/clients'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      alert,
      auth,
      // shops,
      // visits,
      // products,
      // cart,
      items,
      invoices,
      payments,
      expenses,
      clients,
      // orders,
      // vendors,
      pages,
    },
});