//
import axios from 'axios'

export const expenses = {
    namespaced: true,
    state: {
        expenses: [],
        categories: []
     },
    getters: {
        getExpenses: (state) => {
            //
            const data = state.expenses
            
            data.sort((a,b) => new Date(b.date) -  new Date(a.date))
        
            return data
        },
        getCategories: (state) => {
            //
            const data = state.categories
            // data.sort((a,b) => new Date(b.date) -  new Date(a.date))
            data.sort((a,b) => {
                var textA = a.category.toUpperCase();
                var textB = b.category.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;  
            })
        
            return data
        },
        searchExpenses: state => (search) => {
            //
            const results = state.expenses.filter(({ invoice, category, mode, amount, purpose, details, date, dd1, dd2 }) => 
                invoice.toLowerCase().includes(search.toLowerCase()) || 
                category.toLowerCase().includes(search.toLowerCase()) || 
                mode.toLowerCase().includes(search.toLowerCase()) || 
                amount == search || 
                purpose.toLowerCase().includes(search.toLowerCase()) || 
                details.toLowerCase().includes(search.toLowerCase()) || 
                date.toLowerCase().includes(search.toLowerCase()) ||
                dd1.toLowerCase().includes(search.toLowerCase()) ||
                dd2.toLowerCase().includes(search.toLowerCase())
                
            )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchCategories: state => (search) => {
            //
            const results = state.categories.filter(({ category }) => category.toLowerCase().includes(search.toLowerCase()) )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchBusinessExpenses: state => (search) => {
            //
            const results = state.expenses.filter(({ purpose }) => purpose.toLowerCase().includes(search.toLowerCase()) )

            return results
        }
    },
    actions: {
        async getExpenses({ commit },  business) {
            //
            let data
            //
            if (business.length > 0) {
                axios.get('/expenses/'+business).then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                    for (let index = 0; index < data.length; index++) {

                        // date
                        data[index].dd1 = (new Date(data[index].date)).toDateString()
                        data[index].dd2 = (new Date(data[index].date)).toISOString()
                    }
                    // })()
                    commit('updateExpenses', data)
                })

            } else {
                data = []
            }


            
        },
        async getCategories({ state,commit },  business) {
            //
            let data
            //
            if (business.length > 0) {
                axios.get('/expense/categories/'+business).then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                        for (let index = 0; index < data.length; index++) {
            
                            // to 2 decimalplaces
                            // data[index].qty = 0
                            // date
                        }
                    // })()
                    commit('updateCategories', data)
                })

            } else {
                data = []
            }


            
        }
    },
    mutations: {
        updateExpenses: (state, data) => {
            state.expenses = data
        },
        updateCategories: (state, data) => {
            state.categories = data
        },
    }
}