<template>
    <div>
        <div class="p-4" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            <div class="columns is-mobile">
                    <div class="column is-half">
                        <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather>
                        <p class="px-2 py-2 pb-0 has-text-weight-bold ml-5"> {{ ref ? 'Edit: ' : 'Add  Client' }} <span v-if="ref" class="has-text-primary">{{ ref }}</span> </p>
                    </div>
                    <div class="column py-4">
                        <!-- <b-button type="is-primary" class="is-pulled-right mr-3" icon-left="" @click="clientView ? clientView = false : clientView = true" rounded size="is-small"> add client </b-button> -->
                    </div>
            </div>
            <div class="mb-5">
                <div class="pb-2 p-1">
                        <div class="columns is-mobile  mb-0">
                                <div class="column">
                                    <b-field :type="bn.type" :message="bn.message">
                                        <b-input autocomplete="0" autofocus @input.native="validateName()" v-model="form.name" placeholder="Name"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile  mb-0">
                                <div class="column">
                                    <b-field>
                                        <b-input autocomplete="0" v-model="form.email" placeholder="Email Address"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile mb-0">
                                <div class="column is-one-quarter-widesreen is-one-quarter-desktop is-one-quarter-tablet is-one-third-mobile">
                                    <b-field label="" message="">

                                        <vue-country-code
                                            class=" is-size-5"
                                            @onSelect="onSelect"
                                            :enabledCountryCode="true"
                                            :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                            >
                                        </vue-country-code>
                                        
                                    </b-field>
                                </div>
                                <div class="column">
                                    <b-field label="" :type="cn.type" :message="cn.message">
                                        <b-input type="number"  @input.native="validateContact()" v-model="form.phone" placeholder="XXXXXXXXX"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field >
                                        <b-input  type="textarea" autocomplete="0" v-model="form.address" placeholder="Address"></b-input>
                                    </b-field>
                                </div>
                            </div>

                        <div>
                        </div>
                        
                        <!-- <b-button type="is-primary"  expanded>Add item</b-button> -->
                        <!-- <div>
                            <b-table :mobile-cards="false" :data="form.items" :columns="columns"></b-table>
                        </div> -->
                        
                            <b-field class="pb-5 mb-3">
                                <b-button @click="onSubmit"  class="is-submit-button" expanded type="is-primary">Submit</b-button>
                            </b-field>
                    </div>

                    <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
            </div>
        </div>
        <MainFooter/>
    </div>
</template>

<script>

    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import MainFooter from '../components/footer'
    import moment  from 'moment';

    export default {
        name: "AddClientPage",
        data(){
            return {
                maxAmount: null,
                ref: null,
                id: null,
                isLoading: false,
                screenWidth: 0,
                invoice: {
                        label: 'Select Invoice',
                        code: null,
                        total: null,
                        paid:  null,
                        client:  null
                    },
                payDisabled: false,
                form: {
                    business: '',
                    name: '',
                    email: '',
                    code: '',
                    phone: '',
                    address: '',
                },
                bn: {
                    type:'',
                    message:'',
                },
                cl: {
                    type:'',
                    message:'',
                },
                tl: {
                    type:'',
                    message:'',
                },
                inv: {
                    type:'',
                    message:'',
                },
                pr: {
                    type:'',
                    message:'',
                },
                cn: {
                    type:'',
                    message:'',
                },
                am: {
                    type:'',
                    message:'',
                },
                options: [
                    {
                        label: 'Select Invoice',
                        code: null,
                        total: null,
                        paid:  null,
                        client:  null
                    }
                ],
            }
        },
        components: {
            MainFooter,
        },
        computed: {
            //
            ...mapGetters('clients', ['getClients']),
            ...mapGetters('invoices', ['getInvoices']),
            ...mapGetters('auth', ['getUser'])
        },
        watch: {
            getInvoices() {
                if (this.getInvoices) {
                    let options = []

                    this.getInvoices.filter(i=>i.type=="Invoice").forEach(v=>{
                        options.push({ label: v.ref+' - '+v.client+' - '+this.getUser.country.currency+v.total.toFixed(2)+' - '+(new Date(v.date).toDateString().substring(4))+' - '+(v.due ? v.due : '')+' - '+v.status, code: v.ref, total: v.total,  paid: v.paid, client: v.client })
                        // options.push({ label: v.name+' '+v.phone.countryCode+v.phone.tel, code: v.name })
                    })
                    //
                    this.options = this.options.concat(options)
                }
            },
            // getClients() {

            //     if (this.getClients) {
            //         let options = []

            //         this.getClients.forEach(v=>{
            //             options.push({ label: v.name, code: v.name })
            //             // options.push({ label: v.name+' '+v.phone.countryCode+v.phone.tel, code: v.name })
            //         })
            //         //
            //         this.options = this.options.concat(options)
            //     }
            // },
            invoice() {
                if (this.invoice) {
                    //
                    this.clientView = false

                    if (this.invoice.code) {
                        this.form.invoice = this.invoice.code
                        //
                        // this.form.client = this.invoice.client
                        // this.form.total = this.invoice.total.toFixed(2)
                        // this.form.balance = (this.invoice.total - this.invoice.paid).toFixed(2)
                        // this.maxAmount = (this.invoice.total - this.invoice.paid)

                    }
                    
                } 
                else {
                    this.clientView = false
                }
            },
        },
        mounted() {
            
            this.updateScreenWidth();
            this.onScreenResize();
            this.fetchData()

            if (Object.keys(this.$route.params).length !== 0) {

                console.log(this.$route.params.client)

                const data = {
                        link: 'create_invoice',
                        data: this.$route.params
                    }

                // this.invoice.id = this.$route.params.id
                this.ref = this.$route.params.client.name
                // this.client = this.$route.params.client.client
                this.form.business = this.$route.params.client.business
                this.form.name = this.$route.params.client.name
                this.form.email = this.$route.params.client.email
                this.form.code = this.$route.params.client.phone.countryCode
                this.form.phone = this.$route.params.client.phone.tel
                this.form.address = this.$route.params.client.email

                // this.checkShop()
                // this.checkVisit()
                }
                else {

                const data = this.$store.getters['pages/getPage']('create_invoice')
                

                if (typeof data !== 'undefined') {
                        // this.form = data.search
                        this.invoice.id = data.id
                        this.invoice.ref = data.ref
                        this.invoice.client = data.client
                        this.invoice.business = data.business
                        this.invoice.type = data.type
                        this.invoice.items = data.items
                        this.invoice.total = data.total
                        this.invoice.paid = data.paid
                        this.invoice.discount = data.discount
                        this.invoice.date = data.date
                        this.invoice.due = data.due
                        this.invoice.dueDate = data.dueDate
                        this.invoice.notes = data.notes
                        this.invoice.color = data.color
                        this.invoice.user = data.user
                        this.invoice.status = data.status
                }

                this.isLoading = false

                // this.checkShop()
                // this.checkVisit()
                }
        },
        methods: {

            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            fetchData() {
                this.$store.dispatch('clients/getClients', this.getUser.business)
                this.$store.dispatch('invoices/getInvoices', this.getUser.business)
            },
            validateName() {
                if (this.form.name.length < 3){
                    this.bn.type = 'is-danger';
                    this.bn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.name.length >= 3){
                    this.bn.type = 'is-success';
                    this.bn.message =''
                    return true
                }
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)

                if (reg.test(this.form.phone)) {

                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.phone.length > 8 && this.form.phone.length < 11){
                        this.cn.type = 'is-success';
                        this.cn.message =''
                        return true
                    } else {
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a contact number'
                        return false
                    }
                }


            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                if ( !this.validateName() || !this.validateContact() ){
                // if ( (!this.form.client || (!this.validateName() && !this.validateContact())) || !this.form.items.reduce((a, b) => a + b.total, 0) > 0 ){
                    return
                }

                this.isLoading = true

                //
                this.form.business = this.getUser.business
                // this.form.total = this.genDis( (this.form.discount ? this.form.discount : 0),  this.form.items.reduce((a, b) => a + b.total, 0) )
                
                let method,url
                //
                if (this.ref) {
                    url = '/client/'+this.ref
                    method = axios.patch
                } else {
                    url = '/client'
                    method = axios.post
                }
                
                // ajax
                method(url, this.form).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)
                    // const ref = response.data.split(" ")[2]
                    //
                    this.$router.push({ name: 'client' })

                    this.isLoading = false

                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            },
            onSelect({name, iso2, dialCode}) {
                this.form.code = "+"+dialCode
                // console.log(name, iso2, dialCode);
                // this.form.country = name.split(" ")[0]
                // this.form.currency = iso2
            },
            calcTot(item) {
                item.total = Math.round( item.qty * item.rate * 1000)/1000
            },
            addItem() {

                let item = {}
                item.category = '' 
                item.description = '' 
                item.qty = null
                item.rate = null
                item.total = null

                this.form.items.push(item)
            },
            removeItem(index){

                if (this.form.items.length > 1) {
                    this.form.items.splice(index,1)
                }
            },
            genDis(discount, amount) {
                return Math.round( ((100 - discount)/100 * amount) * 1000 )/1000
            },
            Discount(discount, amount) {
                return Math.round( ((discount)/100 * amount) * 1000 )/1000
            },
            
            goBack() {
                this.$router.back()
            },
            goHome() {
                //
                this.$router.push('/')
            },
            payType() {
                if (this.form.type == 'Pro forma') {
                    //
                    this.dueValue = null

                } else {
                    this.dueValue = 'On Receipt'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
// @import "vue-select/dist/vue-select.css";

textarea:not([rows]) {
    max-height: 40em;
    min-height: 5em !important;
}

.vs__selected-options input[class] {
    padding: 0rem !important;
    font-size: 0.9rem !important;
}

.vs__selected {
    padding: 0rem !important;
    font-size: 0.9rem !important;
}

.vs__deselect {
    margin: 3px;
    margin-left: 5px;
    font-size: 0.9rem !important;
}
</style>