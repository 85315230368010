//
import axios from 'axios'

export const items = {
    namespaced: true,
    state: {
        items: [],
        brands: [],
        categories: []
     },
    getters: {
        getItems: (state) => {
            //
            const data = state.items
            // data.sort((a,b) => new Date(b.date) -  new Date(a.date))

            data.sort((a,b) => {
                var textA = a.item.toUpperCase();
                var textB = b.item.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;  
            })
        
            return data
        },
        getBrands: (state) => {
            //
            const data = state.brands
            // data.sort((a,b) => new Date(b.date) -  new Date(a.date))
            data.sort((a,b) => {
                var textA = a.brand.toUpperCase();
                var textB = b.brand.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;  
            })
        
            return data
        },
        getCategories: (state) => {
            //
            const data = state.categories
            // data.sort((a,b) => new Date(b.date) -  new Date(a.date))
            data.sort((a,b) => {
                var textA = a.category.toUpperCase();
                var textB = b.category.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;  
            })
        
            return data
        },
        searchItems: state => (search) => {
            //
            const results = state.items.filter(({ item, brand, category, type, cost, price }) => 
                item.toLowerCase().includes(search.toLowerCase()) || 
                brand.toLowerCase().includes(search.toLowerCase()) || 
                category.toLowerCase().includes(search.toLowerCase()) || 
                type.toLowerCase().includes(search.toLowerCase()) || 
                cost == search || 
                price == search 
                // details.toLowerCase().includes(search.toLowerCase()) || 
                // date.toLowerCase().includes(search.toLowerCase()) ||
                // dd1.toLowerCase().includes(search.toLowerCase()) ||
                // dd2.toLowerCase().includes(search.toLowerCase()) 
            
            )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchBrands: state => (search) => {
            //
            const results = state.brands.filter(({ brand }) => brand.toLowerCase().includes(search.toLowerCase()) )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchCategories: state => (search) => {
            //
            const results = state.categories.filter(({ category }) => category.toLowerCase().includes(search.toLowerCase()) )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchBusinessItems: state => (search) => {
            //
            const results = state.items.filter(({ item }) => item.toLowerCase().includes(search.toLowerCase()) )

            return results
        }
        // currentBusiness: state => (name) => {
        //     // if (business) { return }
        
        //     const results = state.vendors.find(({ business }) => business.includes(name))
        
        //     return results
        // }
    },
    actions: {
        async getItems({ state,commit },  business) {
            //
            let data
            //
            if (business.length > 0) {
                axios.get('/items/'+business).then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                        for (let index = 0; index < data.length; index++) {
            
                            // to 2 decimalplaces
                            // data[index].qty = 0
                            // date
                            // data[index].dd1 = (new Date(data[index].date)).toDateString()
                            // data[index].dd2 = (new Date(data[index].date)).toISOString()
                        }
                    // })()
                    commit('updateItems', data)
                })

            } else {
                data = []
            }    
        },
        async getBrands({ state,commit },  business) {
            //
            let data
            //
            if (business.length > 0) {
                axios.get('/item/brands/'+business).then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                        for (let index = 0; index < data.length; index++) {
            
                            // to 2 decimalplaces
                            // data[index].qty = 0
                            // date
                        }
                    // })()
                    commit('updateBrands', data)
                })

            } else {
                data = []
            }


            
        },
        async getCategories({ state,commit },  business) {
            //
            let data
            //
            if (business.length > 0) {
                axios.get('/item/categories/'+business).then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                        for (let index = 0; index < data.length; index++) {
            
                            // to 2 decimalplaces
                            // data[index].qty = 0
                            // date
                        }
                    // })()
                    commit('updateCategories', data)
                })

            } else {
                data = []
            }


            
        }

    },
    mutations: {
        updateItems: (state, data) => {
            state.items = data
        },
        updateBrands: (state, data) => {
            state.brands = data
        },
        updateCategories: (state, data) => {
            state.categories = data
        },
    }
}