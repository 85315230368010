<template>
    <div :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
        <section class="hero is-primary" :style="`background: ${invoice.color ? invoice.color : '#092f9f'};background-color:${invoice.color ? invoice.color : '#092f9f'} !important`">
            <div class="hero-body " :style="(isGradient) ? `background-image: linear-gradient(160deg,   ${pay.color ? pay.color : '#092f9f'} 25%, #06067d 100%);` : ''">
                <div class="columns is-mobile">
                    <div class="column is-7">
                        <p class="title">
                            Receipt
                        </p>
                        <p class="subtitle has-text-white has-text-weight-semibold">
                            {{ getUser.business }} 
                        </p>
                        <p class="small"><a :href="`tel:${getUser.bphone.countryCode+getUser.bphone.tel}`"> {{ getUser.bphone.countryCode+getUser.bphone.tel }} </a></p>
                        <p class="small"> {{ getUser.address }} </p>
                        <div class="columns is-mobile pt-4">
                            <div class="column is-3">
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Date: </span></p>
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Total: </span></p>
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Bal: </span></p>
                            </div>
                            <div class="column">
                                <p class="small has-text-white has-text-weight-semibold">
                                    {{ new Date(pay.date).toDateString().substr(4) }}
                                </p>
                                <!-- <p class="small has-text-white has-text-weight-semibold">
                                    {{ (pay.due == 'On Receipt' ? pay.due : pay.due ? new Date(pay.dueDate).toDateString().substring(4)+' ('+pay.due+')' : 'Draft' ) }}
                                </p> -->
                                <p class="has-text-white small has-text-weight-semibold"> {{ getUser.country.currency+(invoice.total).toFixed(2) }}</p>
                                <p class="has-text-white small has-text-weight-semibold"> {{ getUser.country.currency+(invoice.total - invoice.paid).toFixed(2) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="column has-text-right">
                        <p class="has-text-weight-medium title is-5 pt-1"> {{ pay.receipt }} </p>
                        <p class="has-text-weight-semibold has-text-white subtitle pt-1">{{ invoice.ref }}</p>
                        <p class="has-text-weight-semibold has-text-white  pt-1">{{ pay.client }}</p>
                        <p class="small"><a :href="`tel:${client.phone.countryCode+client.phone.tel}`"> {{ client.phone.countryCode+client.phone.tel }} </a></p>
                        <p class="small"> {{ getUser.address }} </p>
                        <!-- <p class="">John Doe</p> -->
                        <div class="columns is-mobile pt-4">
                            <!-- <div class="column is-3">
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Date </span></p>
                                <p class="small has-text-white has-text-weight-semibold"><span class="has-text-weight">Due </span></p>
                            </div> -->
                            <div class="column">
                                <!-- <p class="small has-text-white has-text-weight-bold">
                                    <a :href="`tel:${client.phone.countryCode+client.phone.tel}`"> {{ client.phone.countryCode+client.phone.tel }} </a>
                                </p> -->
                                <p v-if="client.email" class="has-text-weight-semibold small"><a class="has-text-white" :href="`mailto:${client.email}`">{{ client.email }}</a></p>
                                <!-- <p class="has-text-weight-semibold  small">{{ client.address }}</p> -->
                                <p v-if="pay.discount" class="has-text-weight-semibold  small">Discount: ({{ pay.discount }}%)</p>
                                <p class="medium">  
                                    <feather @click="$router.push({ name: 'add_pay', params: { pay } })" type="edit" size="20" class="mt-2 mr-3"></feather>
                                    <feather @click="print()" type="printer" size="20" class="mt-2 mr-3"></feather>
                                    <feather type="share-2" size="20" class="mt-2"></feather>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="p-5">
            <div class="">
                    <b-table
                        :data="pay.items ? pay.items : []"
                        :bordered="isBordered"
                        :striped="isStriped"
                        :narrowed="isNarrowed"
                        :hoverable="isHoverable"
                        :focusable="isFocusable"
                        :mobile-cards="hasMobileCards">

                        <b-table-column field="details" label="DETAILS" v-slot="props">
                            <!-- <span class="small has-text-dark has-text-weight-medium"> Payment </span> -->
                            <span class="small has-text-weight-semibold">
                                {{ props.row.details ? props.row.details : 'Payment' }}
                            </span>
                        </b-table-column>

                        <b-table-column field="amount" label="PAID" numeric v-slot="props">
                            <span class="small has-text-dark has-text-weight-medium"> {{ getUser.country.currency+props.row.amount }} </span>
                        </b-table-column>

                        <b-table-column field="total" label="TOTAL" numeric v-slot="props">
                            <span class="small has-text-dark has-text-weight-medium"> {{ getUser.country.currency+props.row.total }} </span>
                        </b-table-column>

                        <!-- <b-table-column field="qty" label="QTY" numeric v-slot="props">
                            <span class="small has-text-dark has-text-weight-medium"> {{ props.row.qty }} </span>
                        </b-table-column>

                        <b-table-column field="total" label="TOTAL" numeric v-slot="props">
                            <span class="small has-text-dark has-text-weight-medium"> {{ getUser.country.currency+props.row.total.toFixed(2) }} </span>
                        </b-table-column> -->

                        <template #empty>
                            <div class="has-text-centered">No records</div>
                        </template>

                    </b-table>
                </div>
        </section>
        <hr class="my-3">
        <section class="p-5">
            <div class="columns is-mobile">
                <div class="column is-4">
                </div>
                <div class="column is-4">
                     <div class="has-text-weight-bold small has-text-dark">
                        <p class="">Sub Total:</p>
                        <!-- <p class="">Tax ({{ pay.tax ? pay.tax : 0 }}%)</p> -->
                        <p class="mb-3">Paid: </p>
                        <p class="">Balance Due:</p>
                    </div>
                </div>
                <div class="column">
                    <div class="has-text-weight-semibold small has-text-right mr-2">
                        <p class="">{{  getUser.country.currency+invoice.total.toFixed(2) }}</p>
                        <!-- <p class="">{{ (0).toFixed(2) }}</p> -->
                        <p class="mb-3">{{ getUser.country.currency+pay.amount.toFixed(2) }}</p>
                        <p class="">({{getUser.country.currency+(invoice.total - invoice.paid).toFixed(2)}})</p>
                    </div>
                 </div>
            </div>
        </section>

        <div class="" :style="(screenWidth <= 560 ? 'width: 560px; margin: auto;position: fixed;bottom:0; height:10px;background-color:'+(invoice.color ?  invoice.color : '#092f9f') : (screenWidth <= 768 ? 'width: 768px; margin: auto;position: fixed;bottom:0; height:10px;background-color:'+(invoice.color ?  invoice.color : '#092f9f')+';' : 'width: 968px; margin: auto;position: fixed;bottom:0; height:10px;background-color:'+(invoice.color ?  invoice.color : '#092f9f')+';' ) )">
        
        <!-- :style="`position: fixed;bottom:0; height:10px;width: 100%;background-color: ${ (invoice.color ?  invoice.color : '#092f9f') }`"> -->

        </div>
        
        <div v-if="false" class="p-5" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            
            <div class="columns is-mobile pb-5">
                    <div class="column is-one-third">
                        <feather @click="goBack" type="arrow-left" size="22" class="is-pulled-left mt-2"></feather>
                        <p class="px-2 py-2 ml-5 pb-0 has-text-weight-bold">{{ invoice.type }} </p>
                    </div>
                    <div  class="column py-4">
                        <b-button class="is-pulled-right" icon-left="" @click="print()" rounded size="is-small"> Whatsapp </b-button>
                        <b-button  class="is-pulled-right" icon-left="" @click="print()" rounded size="is-small"> Email /Sms </b-button>
                        <b-button type="is-primary" class="is-pulled-right" icon-left="" @click="$router.push({ name: 'create_invoice', params: { invoice } })" rounded size="is-small"> Edit </b-button>
                    </div>
            </div>

        <div>
            

        </div>
        </div>

      

            <b-loading v-if="isLoading" :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        <!-- <MainFooter/> -->
    </div>
</template>

<script>

    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import MainFooter from '../components/footer'
    import moment  from 'moment';
    import PayStackVue from '@/components/PayStack.vue'
    import country from '@/plugins/countryCode.json'
    import Userauth from '../auth/index'
    

    export default {
        name: "CreateInvoicePage",
        data(){
            return {
                isPaymentModalActive: false,
                User: {
                    business: '',
                    bphone: {
                        countryCode: '',
                        tel:''
                    },
                    bemail: '',
                    address: '',
                    country: {
                        name: '',
                        currency: '',
                        code:''
                    },
                },
                form:{
                    name: '',
                    code: '',
                    phone: '',
                    balance: null,
                    amount: null,
                    total: null,
                    address: ''
                },
                code: null,
                country: country,
                isModalActive: false,
                isPmtModalActive: false,
                isFullPage: true,
                business: '',
                businessData: null,
                isOpen: false,
                note: '',
                deliveryTime: '',
                scheduleDate: '',
                datetime: new Date(),
                channel: null,
                payInt: true,
                metadata: null,
                maxAmount: null,
                isGradient: false,
                isEmpty: false,
                isBordered: false,
                isStriped: false,
                isNarrowed: false,
                isHoverable: false,
                isFocusable: false,
                hasMobileCards: false,
                isLoading: false,
                screenWidth: 0,
                clientView: false,
                invoice: {
                    total: 0,
                    paid: 0,
                },
                pay: {
                    id: '',
                    invoice: '',
                    receipt: '',
                    client: '', 
                    business: '', 
                    type: '',
                    amount: 0, 
                    date: '',
                    details: '',
                    color: '',
                    status: '',
                    items: [{
                        details: '',
                        amount: 0,
                        total: 0
                    }]
                },
                client: {
                    name: '',
                    phone: '',
                    email: '',
                    address: '',
                },
                item: {
                    category: '',
                    description: '',
                    rate: 0,
                    qty: 0,
                    total: 0,
                },
                columns: [
                    // {
                    //     field: 'id',
                    //     label: 'ID',
                    //     width: '40',
                    //     numeric: true
                    // },
                    {
                        field: 'description',
                        label: 'Description',
                    },
                    {
                        field: 'rate',
                        label: 'Rate',
                    },
                    {
                        field: 'qty',
                        label: 'Qty',
                    },
                    {
                        field: 'total',
                        label: 'Total',
                    }
                ],
                InvoiceType: ['Invoice', 'Pro forma'],
                bn: {
                    type:'',
                    message:'',
                },
                am: {
                    type:'',
                    message:'',
                },
                cn: {
                    type:'',
                    message:'',
                },
                options: [
                    {
                        label: 'Select Client',
                        code: null
                    }
                ],
            }
        },
        components: {
            // MainFooter,
        },
        computed: {
            //
            // ...mapGetters('clients', ['getClients']),
            ...mapGetters('invoices', ['verifyLink']),

            getUser() {
                if (Userauth.isAuthenticated()) {
                   return this.$store.getters["auth/getUser"]
                } else {
                    return this.User
                }
            },
            reference(){
                let text = "";
                let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        
                for( let i=0; i < 10; i++ )
                text += possible.charAt(Math.floor(Math.random() * possible.length));
        
                return text;
            }
        },
        watch: {
            dueValue() {
                //
                this.form.due = this.dueValue

                if (this.dueValue && parseInt(this.dueValue)) {

                    console.log(moment((this.form.date ? new Date(this.form.date) : new Date())).add(parseInt(this.form.due), 'days').toDate())

                    this.form.dueDate = moment((this.form.date ? new Date(this.form.date) : new Date())).add(parseInt(this.form.due), 'days').toDate()
                } else {
                    this.form.dueDate = this.form.date ? new Date(this.form.date) : new Date()
                }
            },
            getClients() {

                if (this.getClients) {
                    let options = []

                    this.getClients.forEach(v=>{
                        options.push({ label: v.name, code: v.name })
                        // options.push({ label: v.name+' '+v.phone.countryCode+v.phone.tel, code: v.name })
                    })
                    //
                    this.options = this.options.concat(options)
                }
            },
        },
        mounted() {
            
            this.updateScreenWidth();
            this.onScreenResize();
            // this.fetchData()

            // console.log(Userauth.isAuthenticated())
            // console.log(typeof(Userauth.isAuthenticated() ))
            // console.log(this.getUser)
            
            //
            if (Object.keys(this.$route.query).length !== 0) {

                // console.log(this.$route.query.ref)

                if (typeof(this.$route.query.ref) == "undefined" || typeof(this.$route.query.key) == "undefined" && typeof(this.$route.query.name) == "undefined") {
                    //
                    this.$buefy.toast.open("Oops, invalid details")

                    setTimeout(()=>{
                        this.$router.push('/signin')
                    }, 1500)

                    return
                }

                if (!this.verifyLink('#'+this.$route.query.ref, this.$route.query.key) ) {
                    //
                    this.$buefy.toast.open("Oops, security check failed")

                    setTimeout(()=>{
                        this.$router.push('/signin')
                    }, 1500)

                    return
                }

                const ref = this.$route.query.ref
                let url
                if (Userauth.isAuthenticated()) {
                    url = '/payments/'+this.getUser.business+'/'+ref
                    // this.code = this.country.find(c =>c.name == this.getUser.country.name).code
                } else {
                    url = '/payments/'+this.$route.query.key+'/'+this.$route.query.name+'/'+ref
                }
                
                //
                axios.get(url).then((res)=>{
                    //
                    this.pay = res.data[0];
                    this.pay.items = [{
                        details: '',
                        amount: 0,
                        total: 0
                    }];
                    this.pay.items[0].details = res.data[0].details
                    this.pay.items[0].amount = res.data[0].amount

                    if (!Userauth.isAuthenticated()){
                        
                        this.User.business = res.data[1].name
                        this.User.bphone = res.data[1].phone
                        this.User.address = res.data[1].address
                        this.User.country = res.data[1].country

                        // this.code =this.country.find(c =>c.name == res.data[1].country.name).code

                    }

                    this.isLoading = false

                    if (Userauth.isAuthenticated()) { 

                        

                        axios.get('/clients/'+this.getUser.business+'/'+this.pay.client).then((res)=>{
                            //
                            this.client = res.data[0];

                            this.isLoading = false

                            // this.checkShop()
                            // this.checkVisit()
                        })

                        axios.get('/invoices/'+this.getUser.business+'/'+this.pay.invoice.substring(1)).then((res)=>{
                            //
                            this.invoice = res.data[0];
                            this.pay.items[0].total = res.data[0].total;

                            this.isLoading = false

                            // this.checkShop()
                            // this.checkVisit()
                        })

                    } else {
                        axios.get('/clients/'+this.$route.query.key+'/'+this.$route.query.name+'/'+this.pay.client).then((res)=>{
                            //
                            this.client = res.data[0];

                            this.isLoading = false

                            // this.checkShop()
                            // this.checkVisit()
                        })

                        axios.get('/invoices/'+this.$store.getters['invoices/generateLinkHash'](this.pay.invoice)+'/'+this.$route.query.name+'/'+this.pay.invoice.substring(1)).then((res)=>{
                            //
                            this.invoice = res.data[0];
                            this.pay.items[0].total = res.data[0].total;

                            this.isLoading = false

                            // this.checkShop()
                            // this.checkVisit()
                        })
                    }

                    // this.checkShop()
                    // this.checkVisit()
                })


                

            // this.$store.dispatch('shops/getShop', { shop: this.$route.query.shop, type:"shop" } ).then(()=>{
            //     //
            //     console.log(this.$store.getters['shops/getShops'][0])
            //     //
            //     this.invoice = this.$store.getters['shops/getShops'][0]
            // })
            }

            else if (Object.keys(this.$route.params).length !== 0) {

                // console.log(this.$route.params)

                const data = {
                        link: 'pay_rceipt',
                        data: this.$route.params
                    }

                this.pay.id = this.$route.params.id
                this.pay.invoice = this.$route.params.invoice
                this.pay.receipt = this.$route.params.receipt
                this.pay.client = this.$route.params.client
                this.pay.business = this.$route.params.business
                this.pay.type = this.$route.params.type
                this.pay.amount = this.$route.params.amount
                this.pay.date = this.$route.params.date
                this.pay.items[0].details = this.$route.params.details
                this.pay.items[0].amount = this.$route.params.amount
                this.pay.details = this.$route.params.details
                this.pay.color = this.$route.params.color
                this.pay.status = this.$route.params.status

                // this.$store.dispatch('pages/setPage', data)



                this.$store.dispatch('pages/setPage', data)

                this.isLoading = false

                axios.get('/clients/'+this.getUser.business+'/'+this.pay.client).then((res)=>{
                    //
                    this.client = res.data[0];

                    this.isLoading = false

                    // this.checkShop()
                    // this.checkVisit()
                })

                axios.get('/invoices/'+this.getUser.business+'/'+this.pay.invoice.substring(1)).then((res)=>{
                    //
                    this.invoice = res.data[0];
                    this.pay.items[0].total = res.data[0].total;

                    this.isLoading = false

                    // this.checkShop()
                    // this.checkVisit()
                })


                // this.checkShop()
                // this.checkVisit()
            }
            else {

                const data = this.$store.getters['pages/getPage']('pay_rceipt')

                if (data !== null) {
                        // this.form = data.search
                        this.pay.id = data.id
                        this.pay.invoice = data.invoice
                        this.pay.receipt = data.receipt
                        this.pay.client = data.client
                        this.pay.business = data.business
                        this.pay.type = data.type
                        this.pay.amount = data.amount
                        this.pay.date = data.date
                        this.pay.items[0].details = data.details
                        this.pay.items[0].amount = data.amount
                        this.pay.details = data.details
                        this.pay.color = data.color
                        this.pay.status = data.status
                }

                this.isLoading = false


                axios.get('/clients/'+this.getUser.business+'/'+this.pay.client).then((res)=>{
                    //
                    this.client = res.data[0];

                    this.isLoading = false

                    // this.checkShop()
                    // this.checkVisit()
                })

                axios.get('/invoices/'+this.getUser.business+'/'+this.pay.invoice.substring(1)).then((res)=>{
                    //
                    this.invoice = res.data[0];
                    this.pay.items[0].total = res.data[0].total;

                    this.isLoading = false

                    // this.checkShop()
                    // this.checkVisit()
                })

                // this.checkShop()
                // this.checkVisit()
            }

            
        },
        methods: {
             
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            fetchData() {
                this.$store.dispatch('clients/getClients', this.getUser.business)
            },
            validateName() {
                if (this.form.name.length < 3){
                    this.bn.type = 'is-danger';
                    this.bn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.name.length >= 3){
                    this.bn.type = 'is-success';
                    this.bn.message =''
                    return true
                }
            },
            validateAmount() {
                if (this.form.amount == null) {
                    this.am.type = 'is-danger';
                    this.am.message ='Enter a valid number'
                    return false
                    
                }
                if (this.form.amount == 0) {
                    this.am.type = 'is-danger';
                    this.am.message ='Enter a valid number'
                    return false
                    
                }
                //  if (this.form.amount >= this.maxAmount){
                if (this.form.amount > (this.invoice.total - this.invoice.paid)){
                    this.am.type = 'is-danger';
                    this.am.message ='Pay amount should not be more than the Balance: '+(this.invoice.total - this.invoice.paid)
                    return false
                }

                 if (this.form.amount <= (this.invoice.total - this.invoice.paid)){
                    this.am.type = 'is-success';
                    this.am.message =''
                    return true
                }
            },
            validateContact() {

                const reg = new RegExp(/^\d+$/)

                if (reg.test(this.form.phone)) {

                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }

                    if (this.form.phone.length > 8 && this.form.phone.length < 11){
                        this.cn.type = 'is-success';
                        this.cn.message =''
                        return true
                    } else {
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a valid contact'
                        return false
                    }
                } else {
                    if (this.form.phone.length <=8){
                        this.cn.type = 'is-danger';
                        this.cn.message ='Kindly enter a contact number'
                        return false
                    }
                }


            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                if ( !this.validateAmount() || !this.validateContact()){
                // if ( (!this.form.client || (!this.validateName() && !this.validateContact())) || !this.form.items.reduce((a, b) => a + b.total, 0) > 0 ){
                    return
                }

                this.isLoading = true

                console.log(this.form)

                //
                // this.form.business = this.getUser.business
                // this.form.total = this.genDis( (this.form.discount ? this.form.discount : 0),  this.form.items.reduce((a, b) => a + b.total, 0) )
                
                // ajax
                axios.post('/pay', { sender: Userauth.isAuthenticated ? this.getUser.bphone : this.User.bphone, invoice: this.invoice, business: Userauth.isAuthenticated ? this.getUser.business : this.User.bphone, code: this.form.code, phone: this.form.phone, amount: this.form.amount }).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)
                    //
                    // this.$router.push({ name: 'view_invoice', params: { ref: response.data.ref } })


                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            },
            onSelect({name, iso2, dialCode}) {
                this.form.code = "+"+dialCode
                // console.log(name, iso2, dialCode);
                // this.form.country = name.split(" ")[0]
                // this.form.currency = iso2
            },
            calcTot(item) {
                item.total = Math.round( item.qty * item.rate * 1000)/1000
            },
            addItem() {

                let item = {}
                item.category = '' 
                item.description = '' 
                item.qty = null
                item.rate = null
                item.total = null

                this.form.items.push(item)
            },
            removeItem(index){

                if (this.form.items.length > 1) {
                    this.form.items.splice(index,1)
                }
            },
            genDis(discount, amount) {
                return Math.round( ((100 - discount)/100 * amount) * 1000 )/1000
            },
            Discount(discount, amount) {
                return Math.round( ((discount)/100 * amount) * 1000 )/1000
            },
            print() {
                window.print()
            },
            goBack() {
                this.$router.back()
            },
            goHome() {
                //
                this.$router.push('/')
            },
            callback: function(response){
                this.$buefy.toast.open("Successfully paid.")
                console.log(response)

                // clear 
                // this.$store.dispatch('cart/emptyCart')
                //
                // this.$router.push('/tracker')
            },
            close: function(){
                console.log("Payment closed")
                //
                // this.isPmtModalActive = false

                //
                // delete pending order
                // axios.post('/order/delete', {  orderNo: this.metadata.orderNo, user: { countryCode: this.getUser.phone.substr(0,4),tel: this.getUser.phone.substr(4) }
                //                         })
                //                         .then( response => {

                //                             //
                //                             this.$buefy.toast.open("Oops failed, try again!")

                //                             // const res = response.data
                //                             // this.isLoading = false
                //                             console.log(response.data)

                //                         })

            },
        }
    }
</script>

<style lang="scss" >
// @import "vue-select/dist/vue-select.css";
@import '@/assets/scss/custom_variables.scss';


// .table thead {
//     background: $primary !important;
// }

// .table thead th{
//     border-color: $primary !important;
// }

.table thead th span {
    // color: white !important;
    font-size: 0.85rem;
    font-weight: 800;
    color: black !important;
    // color: $primary !important;
}

.bg{
    background: $primary !important;
    background-color: $primary !important;
}

.gradient {
    background-image: linear-gradient(160deg, #06067d 25%, $primary 100%);
}
</style>