//
import axios from 'axios'

export const clients = {
    namespaced: true,
    state: {
        clients: []
     },
    getters: {
        getClients: (state) => {
            //
            const data = state.clients
            
            data.sort((a,b) => b.total -  a.total)
        
            return data
        },
        searchClients: state => (search) => {
            //
            const results = state.clients.filter(({ name, email, address, phone, balances, date }) => name.toLowerCase().includes(search.toLowerCase()) || email.toLowerCase().includes(search.toLowerCase()) || address.toLowerCase().includes(search.toLowerCase()) || phone.tel.includes(search) || balances.find(({ invoice, total })=> invoice.toLowerCase().includes(search.toLowerCase()) || total == search ) || date.toLowerCase().includes(search.toLowerCase()) )

            // const allBusiness =  results.map(product => product.business)
            // const businesses  = [...new Set(allBusiness)]

            return results
            
        },
        searchBusinessClients: state => (search) => {
            //
            const results = state.clients.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()) )

            return results
        }
    },
    actions: {
        async getClients({ state,commit },  business) {
            //
            let data
            //
            if (business.length > 0) {
                axios.get('/clients/'+business).then((res)=>{
                    //
                    data = res.data
        
                    // (function () {
                    for (let index = 0; index < data.length; index++) {
            
                        // date
                        data[index].date = (new Date(data[index].createdAt)).toISOString()
                        data[index].total = data[index].balances.reduce((a, b) => a + b.total, 0)
                        data[index].paid = data[index].balances.reduce((a, b) => a + b.paid, 0)
                    }
                    // })()
                    commit('updateClients', data)
                })

            } else {
                data = []
            }


            
        }
    },
    mutations: {
        updateClients: (state, data) => {
            state.clients = data
        }
    }
}